/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Common
# Custom Cursor
# Navigations One
# Mobile Nav
# Home Showcase
# Navigations Two
# Navigations Three
# Search Popup
# Main Slider
# Main Slider Two
# Main Slider Three
# Update One
# Department One
# About One
# Feature One
# Services One
# Video One
# Documents One
# Counter One
# Team One
# Testimonial One
# Portfolio One
# Brand One
# Event One
# News One
# Site Footer
# Feature Two
# About Two
# Feature Three
# Services Two
# Counter Two
# Portfolio Two
# Department Two
# Event Two
# Testimonial Two
# News Two
# Social One
# Feature Four
# About Three
# Department Three
# Event Three
# Tesimonial Three
# Portfolio Three
# Brand Two
# Great Place
# Documents Two
# History One
# News Three
# Page Header
# News Details
# Comments
# Sidebar
# News Sidebar
# Contact Page
# Google Map
# Contact One
# Event Details
# Departments Details
# Service Details
# Portfolio Details
# Similar Portfolio
# Team Details
# Contact Two
# Error Page
# Search Box
# FAQ Page
# Feature Five
# Offering One
# Brand three
# CTA One
# About Four
# Reawards One
# Counter Three
# Team Two
# Brand Four
# Our History
# Pricing Page
# News Page
# Events Page
# Why Choose One
# Team Page
# Portfolio Page
# Department Page
# Contact One
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Common
--------------------------------------------------------------*/
:root {
  --govity-font: 'DM Sans', sans-serif;
  --govity-gray: #888888;
  --govity-gray-rgb: 136, 136, 136;
  --govity-white: #ffffff;
  --govity-white-rgb: 255, 255, 255;
  --govity-base: #0ca554;
  --govity-base-rgb: 12, 165, 84;
  --govity-black: #151515;
  --govity-black-rgb: 21, 21, 21;
  --govity-primary: #f3f3f3;
  --govity-primary-rgb: 243, 243, 243;
  --govity-bdr-color: #dddddd;
  --govity-bdr-color-rgb: 221, 221, 221;
  --govity-letter-spacing: -0.04em;
  --govity-letter-spacing-two: -0.02em;
}

.row {
  --bs-gutter-x: 30px;
}

.gutter-y-30 {
  --bs-gutter-y: 30px;
}

body {
  font-family: var(--govity-font);
  color: var(--govity-gray);
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
}

body.locked {
  overflow: hidden;
}

a {
  color: var(--govity-gray);
}

a,
a:hover,
a:focus,
a:visited {
  text-decoration: none;
}

::-moz-placeholder {
  color: inherit;
  opacity: 1;
}

::placeholder {
  color: inherit;
  opacity: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--govity-font);
  color: var(--govity-black);
  margin: 0;
  letter-spacing: var(--govity-letter-spacing);
}

p {
  margin: 0;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 0;
}

::-moz-placeholder {
  color: inherit;
  opacity: 1;
}

::placeholder {
  color: inherit;
  opacity: 1;
}

.page-wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-width: 300px;
  overflow: hidden;
}

.container {
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

::-moz-placeholder {
  color: inherit;
  opacity: 1;
}

::placeholder {
  color: inherit;
  opacity: 1;
}

.section-separator {
  border-color: var(--govity-border);
  border-width: 1px;
  margin-top: 0;
  margin-bottom: 0;
}

#particles-js {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-position: 50% 50%;
  opacity: 0.4;
  z-index: -1;
}

/*--------------------------------------------------------------
# Custom Cursor
--------------------------------------------------------------*/
.custom-cursor__cursor {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  border: 1px solid var(--govity-base);
  transition: all 200ms ease-out;
  position: fixed;
  pointer-events: none;
  left: 0;
  top: 0;
  transform: translate(calc(-50% + 5px), -50%);
  z-index: 999991;
}

.custom-cursor__cursor-two {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: var(--govity-base);
  opacity: 0.3;
  position: fixed;
  transform: translate(-50%, -50%);
  pointer-events: none;
  transition:
    width 0.3s,
    height 0.3s,
    opacity 0.3s;
  z-index: 999991;
}

.custom-cursor__hover {
  background-color: var(--govity-base);
  opacity: 0.4;
}

.custom-cursor__innerhover {
  width: 25px;
  height: 25px;
  opacity: 0.4;
}

.thm-btn {
  border: none;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none !important;
  background-color: var(--govity-base);
  color: var(--govity-white);
  font-size: 16px;
  font-weight: 700;
  padding: 15px 50px 13px;
  overflow: hidden;
  transition: all 0.5s linear;
  z-index: 1;
}

.thm-btn::before {
  transition-duration: 800ms;
  position: absolute;
  width: 200%;
  height: 200%;
  content: '';
  top: 110%;
  left: 50%;
  background-color: var(--govity-black);
  transform: translateX(-50%);
  border-radius: 50%;
  z-index: -1;
}

.thm-btn:hover:before {
  top: -40%;
}

.thm-btn:hover {
  color: var(--govity-white);
}

.section-title {
  position: relative;
  display: block;
  margin-top: -13px;
  margin-bottom: 48px;
}

.section-title__icon {
  position: relative;
  display: block;
  top: 4px;
}

.section-title__icon span {
  position: relative;
  display: inline-block;
  font-size: 13px;
  color: var(--govity-base);
}

.section-title__tagline {
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 16px;
  color: var(--govity-gray);
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.section-title__title {
  margin: 0;
  color: var(--govity-black);
  font-size: 45px;
  line-height: 54px;
  font-weight: 700;
  letter-spacing: var(--govity-letter-spacing);
}

.bootstrap-select .btn-light:not(:disabled):not(.disabled).active,
.bootstrap-select .btn-light:not(:disabled):not(.disabled):active,
.bootstrap-select .show > .btn-light.dropdown-toggle {
  box-shadow: none !important;
}

.bootstrap-select > .dropdown-toggle {
  box-shadow: none !important;
  outline: none !important;
  outline-offset: 0 !important;
  border: none;
  background-color: transparent;
  border-color: transparent;
  padding: 0;
}

.bootstrap-select > .dropdown-toggle::after {
  margin: 0;
  border: none;
  content: '\f107';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 14px;
}

.bootstrap-select:not([class*='col-']):not([class*='form-control']):not(.input-group-btn) {
  width: auto;
}

.bootstrap-select .dropdown-toggle:focus,
.bootstrap-select > select.mobile-device:focus + .dropdown-toggle {
  outline: none !important;
}

.bootstrap-select .dropdown-menu {
  border: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  z-index: 991;
  border-radius: 0;
}

.bootstrap-select .dropdown-menu > li + li > a {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.bootstrap-select .dropdown-menu > li.selected > a {
  background: var(--govity-black);
  color: #fff;
}

.bootstrap-select .dropdown-menu > li > a {
  font-size: 14px;
  font-weight: 400;
  padding: 4px 20px;
  color: #ffffff;
  background: var(--govity-base);
  transition: all 0.4s ease;
  display: inline-flex;
  align-items: center;
}

.bootstrap-select .dropdown-menu > li > a img {
  margin-right: 10px;
}

.bootstrap-select .dropdown-menu > li > a:hover {
  background: var(--govity-black);
  color: var(--govity-white);
  cursor: pointer;
}

.preloader {
  position: fixed;
  background-color: var(--govity-black);
  background-position: center center;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.preloader__image {
  animation-fill-mode: both;
  animation-name: flipInY;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  background-image: url(../images/ajax-loader.gif);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 60px auto;
  width: 100%;
  height: 100%;
}

/* scroll to top */
.scroll-to-top {
  display: inline-block;
  width: 50px;
  height: 50px;
  background: var(--govity-base);
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 99;
  text-align: center;
  transition: all 0.4s ease;
  display: none;
  border-radius: 50%;
}

.scroll-to-top i {
  color: var(--govity-white);
  font-size: 18px;
  line-height: 50px;
  transition: all 0.4s ease;
  position: relative;
  display: inline-block;
  transform: rotate(-90deg);
}

.scroll-to-top:hover {
  background-color: var(--govity-black);
}

.scroll-to-top:hover i {
  color: #fff;
}

/*--------------------------------------------------------------
# Navigations One
--------------------------------------------------------------*/
.main-header {
  position: relative;
  display: block;
  background-color: var(--govity-primary);
  width: 100%;
  transition: all 500ms ease;
  z-index: 999;
}

.main-menu__top {
  position: relative;
  display: block;
  z-index: 1;
}

.main-menu__top-inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-left: 315px;
  z-index: 1;
}

.main-menu__logo {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.main-menu__logo a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--govity-black);
  padding: 49px 80px;
}

.main-menu {
  position: relative;
  display: block;
}

.main-menu__wrapper {
  position: relative;
  display: block;
}

.main-menu__wrapper-inner {
  position: relative;
  display: block;
}

.main-menu__top-left {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 60px;
  padding: 13.5px 0;
  z-index: 1;
}

.main-menu__social {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1;
}

.main-menu__social a {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 13px;
  color: var(--govity-gray);
  transition: all 500ms ease;
}

.main-menu__social a:hover {
  color: var(--govity-base);
}

.main-menu__social a + a {
  margin-left: 25px;
}

.main-menu__top-right {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 1;
  background-color: var(--govity-white);
  padding: 6px 30px 6px;
  padding-right: 60px;
}

.main-menu__contact-list {
  position: relative;
  display: flex;
  align-items: center;
}

.main-menu__contact-list li {
  position: relative;
  display: flex;
  align-items: center;
}

.main-menu__contact-list li + li {
  margin-left: 30px;
}

.main-menu__contact-list li .icon {
  position: relative;
  display: flex;
  align-items: center;
  top: 2px;
}

.main-menu__contact-list li .icon i {
  font-size: 12px;
  color: var(--govity-base);
}

.main-menu__contact-list li .text {
  margin-left: 10px;
}

.main-menu__contact-list li .text p {
  font-size: 14px;
  font-weight: 400;
  color: var(--govity-gray);
  letter-spacing: var(--govity-letter-spacing-two);
}

.main-menu__contact-list li .text p a {
  color: var(--govity-gray);
  transition: all 500ms ease;
}

.main-menu__contact-list li .text p a:hover {
  color: var(--govity-base);
}

.main-menu__top-menu {
  position: relative;
  display: flex;
  align-items: center;
}

.main-menu__top-menu li + li {
  margin-left: 35px;
}

.main-menu__top-menu li a {
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: var(--govity-gray);
  transition: all 500ms ease;
}

.main-menu__top-menu li a:hover {
  color: var(--govity-base);
}

.main-menu__top-menu li a:before {
  content: '';
  position: absolute;
  top: 8px;
  bottom: 8px;
  left: -17px;
  width: 1px;
  background-color: #dfdfdf;
  transform: rotate(10deg);
}

.main-menu__top-menu li:first-child a:before {
  display: none;
}

.main-menu__bottom {
  display: block;
}

.main-menu__bottom-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-left: 315px;
  padding-right: 60px;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
}

.main-menu__main-menu-box {
  display: block;
}

.main-menu__right {
  position: relative;
  display: flex;
  align-items: center;
}

.main-menu__call {
  position: relative;
  display: flex;
  align-items: center;
  padding: 5px 0;
  z-index: 5;
}

.main-menu__call-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background-color: var(--govity-white);
  border-radius: 50%;
  font-size: 15px;
  color: var(--govity-base);
  transition: all 500ms ease;
}

.main-menu__call-icon:hover {
  background-color: var(--govity-black);
}

.main-menu__call-content {
  margin-left: 10px;
}

.main-menu__call-sub-title {
  font-size: 12px;
  line-height: 12px;
  letter-spacing: var(--govity-letter-spacing-two);
}

.main-menu__call-number {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin-top: 2px;
  letter-spacing: var(--govity-letter-spacing-two);
}

.main-menu__call-number a {
  color: var(--govity-black);
  transition: all 500ms ease;
}

.main-menu__call-number a:hover {
  color: var(--govity-base);
}

.main-menu__search-box {
  position: relative;
  display: block;
  margin-left: 60px;
  margin-right: 30px;
  padding: 14.5px 0;
}

.main-menu__search-box:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: -30px;
  width: 1px;
  background-color: var(--govity-bdr-color);
}

.main-menu__search {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 21px;
  color: var(--govity-black);
  transition: all 500ms ease;
}

.main-menu__search:hover {
  color: var(--govity-base);
}

.language-switcher {
  position: relative;
  display: flex;
  align-items: center;
  padding: 11px 0;
}

.language-switcher .bootstrap-select:not([class*='col-']):not([class*='form-control']):not(.input-group-btn) {
  width: 100px;
}

.language-switcher .bootstrap-select:not([class*='col-']):not([class*='form-control']):not(.input-group-btn) span img {
  margin-right: 10px;
}

.language-switcher .bootstrap-select .dropdown-menu {
  min-width: 120px;
}

.language-switcher .bootstrap-select .dropdown-menu > li > a {
  padding: 7px 10px;
  font-size: 14px;
}

.language-switcher .bootstrap-select > .dropdown-toggle {
  color: var(--govity-gray);
  font-size: 14px;
  font-weight: 400;
}

.language-switcher .bootstrap-select > .dropdown-toggle::after {
  color: var(--govity-gray);
  font-size: 12px;
}

.main-menu__btn-box {
  position: relative;
  display: block;
  margin-left: 30px;
}

.main-menu__btn {
  padding: 10px 50px 10px;
  font-size: 14px;
}

.stricky-header.main-menu {
  background-color: var(--govity-primary);
}

.main-menu .main-menu__list,
.main-menu .main-menu__list > li > ul,
.main-menu .main-menu__list > li > ul > li > ul,
.stricky-header .main-menu__list,
.stricky-header .main-menu__list > li > ul,
.stricky-header .main-menu__list > li > ul > li > ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  align-items: center;
  display: none;
}

@media (min-width: 1200px) {
  .main-menu .main-menu__list,
  .main-menu .main-menu__list > li > ul,
  .main-menu .main-menu__list > li > ul > li > ul,
  .stricky-header .main-menu__list,
  .stricky-header .main-menu__list > li > ul,
  .stricky-header .main-menu__list > li > ul > li > ul {
    display: flex;
  }
}

.main-menu .main-menu__list > li,
.stricky-header .main-menu__list > li {
  padding-top: 35px;
  padding-bottom: 35px;
  position: relative;
}

.main-menu .main-menu__list > li + li,
.stricky-header .main-menu__list > li + li {
  margin-left: 60px;
}

.main-menu .main-menu__list > li > a,
.stricky-header .main-menu__list > li > a {
  font-size: 14px;
  display: flex;
  align-items: center;
  color: var(--govity-gray);
  position: relative;
  transition: all 500ms ease;
  font-weight: 600;
  letter-spacing: var(--govity-letter-spacing-two);
}

.main-menu .main-menu__list > li.current > a,
.main-menu .main-menu__list > li:hover > a,
.stricky-header .main-menu__list > li.current > a,
.stricky-header .main-menu__list > li:hover > a {
  color: var(--govity-black);
  text-shadow: 1px 0 0 rgba(21, 21, 21, 0.8);
}

.main-menu .main-menu__list > li > ul,
.main-menu .main-menu__list > li > ul > li > ul,
.stricky-header .main-menu__list > li > ul,
.stricky-header .main-menu__list > li > ul > li > ul {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 270px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  opacity: 0;
  visibility: hidden;
  transform-origin: top center;
  transform: scaleY(0) translateZ(100px);
  transition:
    opacity 500ms ease,
    visibility 500ms ease,
    transform 700ms ease;
  z-index: 99;
  background-color: rgb(255, 255, 255);
  padding: 13px 20px 16px;
}

.sub-menu {
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
}

.main-menu .main-menu__list > li > ul > li > ul > li > ul,
.stricky-header .main-menu__list > li > ul > li > ul > li > ul {
  display: none;
}

.main-menu .main-menu__list > li:hover > ul,
.main-menu .main-menu__list > li > ul > li:hover > ul,
.stricky-header .main-menu__list > li:hover > ul,
.stricky-header .main-menu__list > li > ul > li:hover > ul {
  opacity: 1;
  visibility: visible;
  transform: scaleY(1) translateZ(0px);
}

.main-menu .main-menu__list > li > ul > li,
.main-menu .main-menu__list > li > ul > li > ul > li,
.stricky-header .main-menu__list > li > ul > li,
.stricky-header .main-menu__list > li > ul > li > ul > li {
  flex: 1 1 100%;
  width: 100%;
  position: relative;
}

.main-menu .main-menu__list > li > ul > li + li,
.main-menu .main-menu__list > li > ul > li > ul > li + li,
.stricky-header .main-menu__list > li > ul > li + li,
.stricky-header .main-menu__list > li > ul > li > ul > li + li {
  border-top: none;
  margin-top: 4px;
}

.main-menu .main-menu__list > li > ul > li > a,
.main-menu .main-menu__list > li > ul > li > ul > li > a,
.stricky-header .main-menu__list > li > ul > li > a,
.stricky-header .main-menu__list > li > ul > li > ul > li > a {
  position: relative;
  font-size: 16px;
  line-height: 30px;
  color: var(--govity-gray);
  font-weight: 400;
  display: flex;
  padding: 8px 20px 4px;
  transition: 500ms;
  background-color: var(--govity-white);
  letter-spacing: var(--govity-letter-spacing-two);
}

.main-menu .main-menu__list > li > ul > li:hover > a,
.main-menu .main-menu__list > li > ul > li > ul > li:hover > a,
.stricky-header .main-menu__list > li > ul > li:hover > a,
.stricky-header .main-menu__list > li > ul > li > ul > li:hover > a {
  background-color: var(--govity-primary);
  color: var(--govity-black);
  text-shadow: 1px 0 0 rgba(21, 21, 21, 0.8);
}

.main-menu .main-menu__list > li > ul > li > a::before,
.main-menu .main-menu__list > li > ul > li > ul > li > a::before,
.stricky-header .main-menu__list > li > ul > li > a::before,
.stricky-header .main-menu__list > li > ul > li > ul > li > a::before {
  position: absolute;
  top: 50%;
  right: 20px;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f105';
  font-size: 14px;
  color: var(--govity-base);
  transform: translateY(-50%) scale(0);
  transition: 500ms;
}

.main-menu .main-menu__list > li > ul > li:hover > a::before,
.main-menu .main-menu__list > li > ul > li > ul > li:hover > a::before,
.stricky-header .main-menu__list > li > ul > li:hover > a::before,
.stricky-header .main-menu__list > li > ul > li > ul > li:hover > a::before {
  transform: translateY(-50%) scale(1);
}

.main-menu .main-menu__list > li > ul > li > ul,
.stricky-header .main-menu__list > li > ul > li > ul {
  top: 0;
  left: 100%;
}

.main-menu .main-menu__list li ul li > ul.right-align,
.stricky-header .main-menu__list li ul li > ul.right-align {
  top: 0;
  left: auto;
  right: 100%;
}

.main-menu-three__main-menu-box .main-menu__list > .megamenu,
.main-menu-two__main-menu-box .main-menu__list > .megamenu,
.main-menu__wrapper .main-menu__list > .megamenu {
  position: static;
}

.main-menu-three__main-menu-box .main-menu__list > .megamenu > ul,
.main-menu-two__main-menu-box .main-menu__list > .megamenu > ul,
.main-menu__wrapper .main-menu__list > .megamenu > ul {
  top: 100% !important;
  left: 0 !important;
  right: 0 !important;
  background-color: transparent;
}

.main-menu-three__main-menu-box .main-menu__list > .megamenu > ul > li,
.main-menu-two__main-menu-box .main-menu__list > .megamenu > ul > li,
.main-menu__wrapper .main-menu__list > .megamenu > ul > li {
  padding: 0 !important;
}

.stricky-header {
  position: fixed;
  z-index: 991;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  visibility: hidden;
  transform: translateY(-120%);
  transition:
    transform 500ms ease,
    visibility 500ms ease;
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
}

@media (max-width: 1199px) {
  .stricky-header {
    display: none !important;
  }
}

.stricky-header.stricky-fixed {
  transform: translateY(0);
  visibility: visible;
}

.stricky-header .main-menu__inner {
  box-shadow: none;
  padding-right: 0;
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
}

.mobile-nav__buttons {
  display: flex;
  margin-left: auto;
  margin-right: 10px;
}

@media (min-width: 1200px) {
  .mobile-nav__buttons {
    display: none;
  }
}

.mobile-nav__buttons a {
  font-size: 20px;
  color: var(--govity-base);
  cursor: pointer;
}

.mobile-nav__buttons a + a {
  margin-left: 10px;
}

.mobile-nav__buttons a:hover {
  color: var(--govity-base);
}

.main-menu .mobile-nav__toggler {
  font-size: 20px;
  color: var(--govity-base);
  cursor: pointer;
  transition: 500ms;
}

.main-menu .mobile-nav__toggler:hover {
  color: var(--govity-black);
}

@media (min-width: 1200px) {
  .main-menu .mobile-nav__toggler {
    display: none;
  }
}

/*--------------------------------------------------------------
# Mobile Nav
--------------------------------------------------------------*/
.mobile-nav__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  transform: translateX(-100%);
  transform-origin: left center;
  transition:
    transform 500ms ease 500ms,
    visibility 500ms ease 500ms;
  visibility: hidden;
}

.mobile-nav__wrapper .container {
  padding-left: 0;
  padding-right: 0;
}

.mobile-nav__wrapper.expanded {
  opacity: 1;
  transform: translateX(0%);
  visibility: visible;
  transition:
    transform 500ms ease 0ms,
    visibility 500ms ease 0ms;
}

.mobile-nav__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  opacity: 0.5;
  cursor: pointer;
}

.mobile-nav__content {
  width: 300px;
  background-color: var(--govity-black);
  z-index: 10;
  position: relative;
  height: 100%;
  overflow-y: auto;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  opacity: 0;
  visibility: hidden;
  transform: translateX(-100%);
  transition:
    opacity 500ms ease 0ms,
    visibility 500ms ease 0ms,
    transform 500ms ease 0ms;
}

.mobile-nav__wrapper.expanded .mobile-nav__content {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
  transition:
    opacity 500ms ease 500ms,
    visibility 500ms ease 500ms,
    transform 500ms ease 500ms;
}

.mobile-nav__content .logo-box {
  margin-bottom: 40px;
  display: flex;
}

.mobile-nav__close {
  position: absolute;
  top: 20px;
  right: 15px;
  font-size: 18px;
  color: var(--govity-white);
  cursor: pointer;
}

.mobile-nav__content .main-menu__list,
.mobile-nav__content .main-menu__list > li > ul,
.mobile-nav__content .main-menu__list > li > ul > li > ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.mobile-nav__content .main-menu__list > li > ul,
.mobile-nav__content .main-menu__list > li > ul > li > ul {
  display: none;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin-left: 0.5em;
}

.mobile-nav__content .main-menu__list > li:not(:last-child),
.mobile-nav__content .main-menu__list > li > ul > li:not(:last-child),
.mobile-nav__content .main-menu__list > li > ul > li > ul > li:not(:last-child) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-nav__content .main-menu__list > li > a > .main-menu-border {
  display: none !important;
}

.mobile-nav__content .main-menu__list > li > a,
.mobile-nav__content .main-menu__list > li > ul > li > a,
.mobile-nav__content .main-menu__list > li > ul > li > ul > li > a {
  display: flex;
  justify-content: space-between;
  line-height: 30px;
  color: #ffffff;
  font-size: 14px;
  font-family: var(--govity-font);
  font-weight: 500;
  height: 46px;
  align-items: center;
  transition: 500ms;
}

.mobile-nav__content
  .main-menu__list
  > li
  > a.expanded
  .mobile-nav__content
  .main-menu__list
  > li
  > ul
  > li
  > a.expanded
  .mobile-nav__content
  .main-menu__list
  > li
  > ul
  > li
  > ul
  > li
  > a.expanded {
  color: var(--govity-base);
}

.mobile-nav__content .main-menu__list li a.expanded {
  color: var(--govity-base);
}

.mobile-nav__content .main-menu__list > li > a > button,
.mobile-nav__content .main-menu__list > li > ul > li > a > button,
.mobile-nav__content .main-menu__list > li > ul > li > ul > li > a > button {
  width: 30px;
  height: 30px;
  background-color: var(--govity-base);
  border: none;
  outline: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transform: rotate(-90deg);
  transition: transform 500ms ease;
  padding: 0;
}

.mobile-nav__content .main-menu__list > li > a > button.expanded,
.mobile-nav__content .main-menu__list > li > ul > li > a > button.expanded,
.mobile-nav__content .main-menu__list > li > ul > li > ul > li > a > button.expanded {
  transform: rotate(0deg);
  background-color: #fff;
  color: var(--govity-base);
}

/* no menu after 2rd level dropdown */
.mobile-nav__content .main-menu__list > li > ul > li > ul > li > a > button,
.mobile-nav__content .main-menu__list > li > ul > li > ul > li > ul {
  display: none !important;
}

.mobile-nav__content .main-menu__list li.cart-btn span {
  position: relative;
  top: auto;
  right: auto;
  transform: translate(0, 0);
}

.mobile-nav__content .main-menu__list li.cart-btn i {
  font-size: 16px;
}

.mobile-nav__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.mobile-nav__top .main-menu__login a {
  color: var(--govity-text-dark);
}

.mobile-nav__container {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-nav__social {
  display: flex;
  align-items: center;
}

.mobile-nav__social a {
  font-size: 16px;
  color: var(--govity-white);
  transition: 500ms;
}

.mobile-nav__social a + a {
  margin-left: 30px;
}

.mobile-nav__social a:hover {
  color: var(--govity-base);
}

.mobile-nav__contact {
  margin-bottom: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}

.mobile-nav__contact li {
  color: var(--govity-text-dark);
  font-size: 14px;
  font-weight: 500;
  position: relative;
  display: flex;
  align-items: center;
}

.mobile-nav__contact li + li {
  margin-top: 15px;
}

.mobile-nav__contact li a {
  color: #ffffff;
  transition: 500ms;
}

.mobile-nav__contact li a:hover {
  color: var(--govity-base);
}

.mobile-nav__contact li > i {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--govity-base);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 11px;
  margin-right: 10px;
  color: #fff;
}

.mobile-nav__container .main-logo,
.mobile-nav__container .topbar__buttons,
.mobile-nav__container .main-menu__language,
.mobile-nav__container .main-menu__login {
  display: none;
}

/*--------------------------------------------------------------
# Home Showcase
--------------------------------------------------------------*/
.home-showcase {
  margin-top: -13px;
  margin-bottom: -13px;
}

.home-showcase__inner {
  padding: 40px 40px 36px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
}

.home-showcase .row {
  --bs-gutter-x: 42px;
  --bs-gutter-y: 20px;
}

.home-showcase__image {
  position: relative;
  background-color: var(--govity-base);
  overflow: hidden;
}

.home-showcase__image > img {
  width: 100%;
  transition: 500ms ease;
  transform: scale(1);
}

.home-showcase__image:hover > img {
  opacity: 0.75;
}

.home-showcase__image:hover .home-showcase__buttons {
  transform: scale(1, 1);
  opacity: 1;
  visibility: visible;
}

.home-showcase__buttons {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transform: scale(1, 0);
  opacity: 0;
  visibility: hidden;
  transform-origin: bottom center;
  transition: 500ms ease;
}

.home-showcase__buttons__item {
  padding: 13px 20px 9px;
  width: 150px;
  text-align: center;
}

.home-showcase__buttons__item + .home-showcase__buttons__item {
  margin-top: 10px;
}

.home-showcase__title {
  margin: 0;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  color: var(--govity-black);
  margin-top: 17px;
}

.mobile-nav__wrapper .home-showcase .row [class*='col-'] {
  flex: 0 0 100%;
}

.mobile-nav__wrapper .home-showcase__inner {
  padding: 15px 0px;
  background-color: rgba(0, 0, 0, 0);
}

.mobile-nav__wrapper .home-showcase__title {
  color: var(--govity-white, #ffffff);
}

/*--------------------------------------------------------------
# Navigations Two
--------------------------------------------------------------*/
.main-header-two {
  background: transparent;
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0;
  width: 100%;
  transition: all 500ms ease;
  z-index: 999;
}

.stricky-header.main-menu-two {
  background-color: var(--govity-white);
}

.main-header-two__top {
  position: relative;
  display: block;
}

.main-header-two__top-inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 6px 0;
}

.main-header-two__contact-list {
  position: relative;
  display: flex;
  align-items: center;
}

.main-header-two__contact-list li {
  position: relative;
  display: flex;
  align-items: center;
}

.main-header-two__contact-list li + li {
  margin-left: 30px;
}

.main-header-two__contact-list li .icon {
  position: relative;
  display: flex;
  align-items: center;
  top: 2px;
}

.main-header-two__contact-list li .icon i {
  font-size: 12px;
  color: var(--govity-base);
}

.main-header-two__contact-list li .text {
  margin-left: 10px;
}

.main-header-two__contact-list li .text p {
  font-size: 14px;
  font-weight: 400;
  color: #777777;
  letter-spacing: var(--govity-letter-spacing-two);
}

.main-header-two__contact-list li .text p a {
  color: #777777;
  transition: all 500ms ease;
}

.main-header-two__contact-list li .text p a:hover {
  color: var(--govity-base);
}

.main-header-two__top-menu {
  position: relative;
  display: flex;
  align-items: center;
}

.main-header-two__top-menu li + li {
  margin-left: 35px;
}

.main-header-two__top-menu li a {
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: #777777;
  transition: all 500ms ease;
}

.main-header-two__top-menu li a:hover {
  color: var(--govity-base);
}

.main-header-two__top-menu li a:before {
  content: '';
  position: absolute;
  top: 8px;
  bottom: 8px;
  left: -17px;
  width: 1px;
  background-color: #777777;
  transform: rotate(10deg);
}

.main-header-two__top-menu li:first-child a:before {
  display: none;
}

.main-menu-two__wrapper {
  position: relative;
  display: block;
  z-index: 1;
}

.main-menu-two__wrapper-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
  padding-left: 40px;
}

.main-menu-two__logo {
  display: block;
}

.main-menu-two__right {
  display: flex;
  align-items: center;
}

.main-menu-two__main-menu-box {
  display: block;
}

.main-menu-two__search-btn-box {
  display: flex;
  align-items: center;
}

.main-menu-two__search-box {
  position: relative;
  display: block;
  margin-left: 60px;
  margin-right: 30px;
  padding: 33.5px 0;
}

.main-menu-two__search-box:before {
  content: '';
  position: absolute;
  top: 20px;
  bottom: 20px;
  left: -30px;
  width: 1px;
  background-color: var(--govity-bdr-color);
}

.main-menu-two__search {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 21px;
  color: var(--govity-black);
  transition: all 500ms ease;
}

.main-menu-two__btn-box {
  position: relative;
  display: block;
}

.main-menu-two__btn {
  padding: 29px 50px 29px;
}

.main-menu-two .main-menu__list > li + li,
.stricky-header.main-menu-two .main-menu__list > li + li {
  margin-left: 45px;
}

.main-menu-two .main-menu__list > li,
.stricky-header.main-menu-two .main-menu__list > li {
  padding-top: 29px;
  padding-bottom: 29px;
}

.stricky-header .main-menu-two__wrapper-inner {
  box-shadow: none;
  padding-left: 0;
}

/*--------------------------------------------------------------
# Navigations Three
--------------------------------------------------------------*/
.main-header-three {
  position: relative;
  display: block;
  z-index: 999;
}

.main-header-three__top {
  position: relative;
  display: block;
  background-color: var(--govity-black);
}

.main-header-three__top-inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.main-header-three__logo {
  position: relative;
  display: block;
}

.main-header-three__logo a {
  position: relative;
  display: block;
  padding: 27px 0;
}

.main-header-three__content-box {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #000000;
  padding-left: 50px;
  padding-top: 24px;
  padding-bottom: 24px;
  z-index: 1;
}

.main-header-three__content-box:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 100%;
  right: -100000px;
  background-color: #000000;
  z-index: -1;
}

.main-header-three__contact-list {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.main-header-three__contact-list li {
  position: relative;
  display: flex;
  align-items: center;
}

.main-header-three__contact-list li + li {
  margin-left: 30px;
}

.main-header-three__contact-list li .icon {
  position: relative;
  display: flex;
  align-items: center;
  top: 2px;
}

.main-header-three__contact-list li .icon i {
  font-size: 12px;
  color: var(--govity-base);
}

.main-header-three__contact-list li .text {
  margin-left: 10px;
}

.main-header-three__contact-list li .text p {
  font-size: 14px;
  font-weight: 400;
  color: #777777;
  letter-spacing: var(--govity-letter-spacing-two);
}

.main-header-three__contact-list li .text p a {
  color: #777777;
  transition: all 500ms ease;
}

.main-header-three__contact-list li .text p a:hover {
  color: var(--govity-base);
}

.main-header-three__search-lan-switcher-btn {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 95px;
}

.main-header-three__search-box {
  position: relative;
  display: block;
  margin-right: 30px;
  padding: 14.5px 0;
}

.main-header-three__search-box:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: -30px;
  width: 1px;
  background-color: rgba(var(--govity-white-rgb), 0.1);
}

.main-header-three__search {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 21px;
  color: var(--govity-white);
  transition: all 500ms ease;
}

.main-header-three__search:hover {
  color: var(--govity-base);
}

.main-header-three__language-switcher {
  position: relative;
  display: block;
  padding: 11px 0;
}

.main-header-three__language-switcher.language-switcher .bootstrap-select > .dropdown-toggle,
.main-header-three__language-switcher #polyglotLanguageSwitcher a {
  color: #777777;
}

.main-header-three__language-switcher.language-switcher .bootstrap-select > .dropdown-toggle::after,
.main-header-three__language-switcher #polyglotLanguageSwitcher span.trigger:before {
  color: #777777;
}

.main-header-three__btn-box {
  position: relative;
  display: block;
  margin-left: 30px;
}

.main-header-three__btn {
  font-size: 14px;
  padding: 10px 50px 10px;
}

.call-to-action__btn {
  font-size: 14px;
  padding: 20px 100px 20px;
}

.main-header-three__btn:hover {
  color: var(--govity-black);
}

.main-header-three__btn::before {
  background-color: var(--govity-white);
}

.main-menu-three__wrapper {
  position: relative;
  display: block;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
}

.main-menu-three__wrapper-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.main-menu-three__main-menu-box {
  display: block;
}

.main-menu-three__social {
  position: relative;
  display: flex;
  align-items: center;
}

.main-menu-three__social a {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 15px;
  color: var(--govity-gray);
  transition: all 500ms ease;
}

.main-menu-three__social a + a {
  margin-left: 25px;
}

.main-menu-three__social a:hover {
  color: var(--govity-base);
}

.main-menu-three .main-menu__list > li,
.stricky-header.main-menu-three .main-menu__list > li {
  padding-top: 19px;
  padding-bottom: 19px;
}

/*--------------------------------------------------------------
# Search Popup
--------------------------------------------------------------*/
.search-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-110%);
  transition:
    transform 500ms ease,
    opacity 500ms ease;
}

.search-popup.active {
  transform: translateY(0%);
}

.search-popup__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--govity-black);
  opacity: 0.75;
  cursor: pointer;
}

.search-popup__content {
  width: 100%;
  max-width: 560px;
}

.search-popup__content form {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  background-color: #fff;
  overflow: hidden;
}

.search-popup__content form input[type='search'],
.search-popup__content form input[type='text'] {
  width: 100%;
  background-color: #fff;
  font-size: 16px;
  border: none;
  outline: none;
  height: 66px;
  padding-left: 30px;
}

.search-popup__content .thm-btn {
  padding: 0;
  width: 68px;
  height: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 0;
  right: -1px;
  border-radius: 0;
  background-color: var(--govity-base);
  border: 0;
}

.search-popup__content .thm-btn i {
  height: auto;
  width: auto;
  background-color: transparent;
  border-radius: 50%;
  color: var(--govity-white);
  font-size: 22px;
  line-height: inherit;
  text-align: center;
  top: 0;
  margin-right: 0;
  padding-left: 0;
  transition: all 500ms ease;
}

.search-popup__content .thm-btn:hover:before {
  width: 150%;
}

/*--------------------------------------------------------------
# Main Slider
--------------------------------------------------------------*/
.main-slider {
  position: relative;
  background-color: var(--govity-black);
  z-index: 10;
}

.main-slider .item {
  background-color: var(--govity-black);
  position: relative;
  padding-top: 200px;
  padding-bottom: 200px;
  z-index: 10;
}

.main-slider__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transform: scale(1);
  z-index: 1;
}

.owl-item.active .main-slider__bg {
  transform: scale(1.15);
  transition: transform 7000ms linear;
}

.main-slider__bg:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.main-slider__shape-1 {
  position: absolute;
  top: 0;
  right: 0;
  mix-blend-mode: hard-light;
  transform: translateX(500px);
  opacity: 0;
}

.active .main-slider__shape-1 {
  opacity: 1;
  transform: translateX(0px);
  transition: all 1000ms ease;
  z-index: 1;
}

.main-slider__shape-1 img {
  width: auto;
}

.main-slider__shape-2 {
  position: absolute;
  top: 0;
  right: 0;
  mix-blend-mode: soft-light;
  transform: translateX(600px);
  opacity: 0;
  z-index: 1;
}

.active .main-slider__shape-2 {
  opacity: 0.3;
  transform: translateX(0px);
  transition: all 1000ms ease;
  transition-delay: 1500ms;
}

.main-slider__shape-2 img {
  width: auto;
}

.main-slider__shape-3 {
  position: absolute;
  top: 0;
  right: 0;
  mix-blend-mode: soft-light;
  transform: translateY(600px);
  opacity: 0;
  z-index: 1;
}

.active .main-slider__shape-3 {
  opacity: 0.1;
  transform: translateX(0px);
  transition: all 1000ms ease;
  transition-delay: 2000ms;
}

.main-slider__shape-3 img {
  width: auto;
}

.main-slider__meta-box {
  position: absolute;
  bottom: 52px;
  left: 80px;
  z-index: 1;
}

.main-slider__meta {
  position: relative;
  display: flex;
  align-items: center;
}

.main-slider__meta li {
  position: relative;
  display: flex;
  align-items: center;
}

.main-slider__meta li + li {
  margin-left: 26px;
}

.main-slider__meta li .icon {
  position: relative;
  display: inline-block;
  top: 1px;
}

.main-slider__meta li .icon span {
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: var(--govity-white);
}

.main-slider__meta li .text {
  margin-left: 12px;
}

.main-slider__meta li .text p {
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  color: var(--govity-white);
}

.main-slider__content {
  position: relative;
  display: block;
  z-index: 10;
}

.main-slider__sub-title {
  font-size: 18px;
  color: var(--govity-white);
  position: relative;
  display: inline-block;
  padding: 6px 30px 8px;
  letter-spacing: var(--govity-letter-spacing-two);
  background-color: rgba(var(--govity-black-rgb), 0.1);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-120px);
  transition-delay: 1000ms;
  transition:
    transform 2000ms ease,
    opacity 2000ms ease;
}

.main-slider__title {
  position: relative;
  font-size: 90px;
  color: var(--govity-white);
  font-weight: 700;
  line-height: 90px;
  letter-spacing: var(--govity-letter-spacing);
  margin-bottom: 35px;
  margin-top: 19px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-120px);
  transition-delay: 1000ms;
  transition:
    transform 2000ms ease,
    opacity 2000ms ease;
}

.main-slider__btn-box {
  position: relative;
  display: block;
  opacity: 0;
  visibility: hidden;
  transform: translateY(120px);
  transition-delay: 1000ms;
  transition:
    transform 2000ms ease,
    opacity 2000ms ease;
}

.active .main-slider__title,
.active .main-slider__sub-title,
.active .main-slider__btn-box {
  visibility: visible;
  opacity: 1;
  transform: translateY(0) translateX(0);
}

.main-slider .owl-theme .owl-nav {
  position: absolute;
  top: 41%;
  left: 50%;
  z-index: 100;
  transform: translateY(-50%) translateX(-50%);
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  max-width: 1200px;
  width: 100%;
  padding: 0 15px;
  height: 0;
  line-height: 0;
}

.main-slider .owl-theme .owl-nav [class*='owl-'] {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  z-index: 100;
  width: 65px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: rgba(var(--govity-black-rgb), 1);
  background-color: rgba(var(--govity-white-rgb), 0.2);
  border: none;
  border-radius: 50%;
  margin: 0;
  text-align: center;
  transition: all 500ms ease;
  padding: 23px 0 !important;
}

.main-slider .owl-theme .owl-nav [class*='owl-']:hover {
  color: rgba(var(--govity-black-rgb), 1);
  background-color: rgba(var(--govity-white-rgb), 1);
}

.main-slider .owl-theme .owl-nav [class*='owl-'] + [class*='owl-'] {
  margin-top: 10px;
}

.main-slider .owl-theme .owl-dots {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  position: absolute;
  bottom: 60px;
  left: 0;
  right: 0;
  padding: 0 80px;
  height: 0;
  line-height: 0;
}

.main-slider .owl-theme .owl-dots .owl-dot + .owl-dot {
  margin-left: 10px;
}

.main-slider .owl-theme .owl-dots .owl-dot span {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: rgba(var(--govity-white-rgb), 0.3);
  margin: 0;
  transition: all 300ms ease;
}

.main-slider .owl-theme .owl-dots .owl-dot:hover span,
.main-slider .owl-theme .owl-dots .owl-dot.active span {
  background-color: rgba(var(--govity-white-rgb), 1);
}

/*--------------------------------------------------------------
# Main Slider Two
--------------------------------------------------------------*/
.main-slider-two {
  position: relative;
  background-color: var(--govity-black);
  z-index: 10;
}

.main-slider-two .item {
  background-color: var(--govity-black);
  position: relative;
  padding-top: 350px;
  padding-bottom: 200px;
  z-index: 10;
}

.main-slider-two__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  mix-blend-mode: luminosity;
  transform: scale(1);
  opacity: 0.05;
  z-index: 1;
}

.owl-item.active .main-slider-two__bg {
  transform: scale(1.15);
  transition: transform 7000ms linear;
}

.main-slider-two__shadow {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 307px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 3;
}

.main-slider-two__img-box {
  position: absolute;
  bottom: -185px;
  right: 350px;
  z-index: 2;
}

.main-slider-two__img-shape-1 {
  position: absolute;
  top: 140px;
  left: 80px;
  border-radius: 50%;
  background: rgb(15, 15, 15);
  background: linear-gradient(90deg, rgb(15, 15, 15) 3%, rgba(15, 15, 15, 0) 100%);
  width: 550px;
  height: 550px;
}

.main-slider-two__img-shape-2 {
  position: absolute;
  top: 330px;
  right: -45px;
  border-radius: 50%;
  background-color: rgb(12, 165, 84);
  width: 208px;
  height: 209px;
}

.main-slider-two__sign {
  position: absolute;
  bottom: 105px;
  right: 300px;
  z-index: 3;
}

.main-slider-two__sign img {
  width: auto !important;
}

.main-slider-two__img {
  position: relative;
  display: block;
  transform: translateX(500px);
  z-index: 2;
}

.main-slider-two__img img {
  width: auto !important;
}

.active .main-slider-two__img {
  opacity: 1;
  transform: translateX(0px);
  transition: all 1000ms ease;
  z-index: 1;
}

.main-slider-two__content {
  position: relative;
  display: block;
  z-index: 15;
}

.main-slider-two__shape-1 {
  position: absolute;
  bottom: 0;
  left: 465px;
}

.main-slider-two__shape-1 img {
  width: auto;
}

.main-slider-two__sub-title {
  font-size: 20px;
  color: #777777;
  position: relative;
  display: inline-block;
  letter-spacing: var(--govity-letter-spacing-two);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-120px);
  transition-delay: 1000ms;
  transition:
    transform 2000ms ease,
    opacity 2000ms ease;
}

.main-slider-two__sub-title::before {
  content: '';
  position: absolute;
  width: 240px;
  height: 8px;
  top: -20px;
  left: 0;
  background-color: var(--govity-base);
}

.main-slider-two__title {
  position: relative;
  font-size: 70px;
  color: var(--govity-white);
  font-weight: 700;
  line-height: 84px;
  letter-spacing: var(--govity-letter-spacing);
  margin-bottom: 42px;
  margin-top: 15px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-120px);
  transition-delay: 1000ms;
  transition:
    transform 2000ms ease,
    opacity 2000ms ease;
}

.main-slider-two__btn-box {
  position: relative;
  display: block;
  opacity: 0;
  visibility: hidden;
  transform: translateY(120px);
  transition-delay: 1000ms;
  transition:
    transform 2000ms ease,
    opacity 2000ms ease;
}

.main-slider-two__btn:hover {
  color: var(--govity-base);
}

.main-slider-two__btn:before {
  background-color: var(--govity-white);
}

.active .main-slider-two__title,
.active .main-slider-two__sub-title,
.active .main-slider-two__btn-box {
  visibility: visible;
  opacity: 1;
  transform: translateY(0) translateX(0);
}

/*--------------------------------------------------------------
# Main Slider Three
--------------------------------------------------------------*/
.main-slider-three {
  position: relative;
  background-color: var(--govity-black);
  z-index: 10;
}

.main-slider-three .item {
  background-color: var(--govity-black);
  position: relative;
  padding-top: 189px;
  padding-bottom: 200px;
  z-index: 10;
}

.main-slider-three__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  transform: scale(1);
  background-position: center;
  z-index: 1;
}

.owl-item.active .main-slider-three__bg {
  transform: scale(1.15);
  transition: transform 7000ms linear;
}

.main-slider-three__bg:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.main-slider-three__content {
  position: relative;
  display: block;
  z-index: 15;
}

.main-slider-three__title {
  position: relative;
  font-size: 45px;
  color: var(--govity-white);
  font-weight: 700;
  line-height: 90px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-120px);
  transition-delay: 1000ms;
  transition:
    transform 2000ms ease,
    opacity 2000ms ease;
}

.main-slider-three__sub-title {
  font-size: 20px;
  line-height: 30px;
  color: var(--govity-white);
  font-weight: 500;
  position: relative;
  display: inline-block;
  letter-spacing: var(--govity-letter-spacing-two);
  margin-top: 27px;
  margin-bottom: 42px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-120px);
  transition-delay: 1000ms;
  transition:
    transform 2000ms ease,
    opacity 2000ms ease;
}

.main-slider-three__btn-box {
  position: relative;
  display: block;
  opacity: 0;
  visibility: hidden;
  transform: translateY(120px);
  transition-delay: 1000ms;
  transition:
    transform 2000ms ease,
    opacity 2000ms ease;
}

.main-slider-three__btn:hover {
  color: var(--govity-base);
}

.main-slider-three__btn:before {
  background-color: var(--govity-white);
}

.active .main-slider-three__title,
.active .main-slider-three__sub-title,
.active .main-slider-three__btn-box {
  visibility: visible;
  opacity: 1;
  transform: translateY(0) translateX(0);
}

.main-slider-three__weather-box {
  position: absolute;
  right: 0;
  bottom: -172px;
  max-width: 415px;
  width: 100%;
  z-index: 1;
}

.main-slider-three__weather-list {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main-slider-three__weather-list li {
  position: relative;
  display: block;
  background-color: var(--govity-primary);
  max-width: 206px;
  width: 100%;
  padding: 42px 30px 29px;
}

.main-slider-three__weather-list li:last-child {
  background-color: var(--govity-white);
}

.main-slider-three__weather-list li + li {
  margin-left: 2px;
}

.main-slider-three__weather-list li .icon {
  position: absolute;
  top: 30px;
  right: 30px;
}

.main-slider-three__weather-list li .icon span {
  position: relative;
  display: inline-block;
  font-size: 32px;
  color: var(--govity-base);
}

.main-slider-three__weather-list li:last-child .icon span {
  font-size: 27px;
}

.main-slider-three__weather-list li .text {
  position: relative;
  display: block;
}

.main-slider-three__weather-list li .text span {
  font-size: 14px;
  font-weight: 500;
  color: var(--govity-black);
}

.main-slider-three__weather-list li .text p {
  font-size: 18px;
  line-height: 18px;
  color: var(--govity-gray);
  margin-top: -1px;
}

.main-slider-three .owl-theme .owl-nav {
  position: absolute;
  top: 41%;
  left: 50%;
  z-index: 100;
  transform: translateY(-50%) translateX(-50%);
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  max-width: 1200px;
  width: 100%;
  padding: 0 15px;
  height: 0;
  line-height: 0;
}

.main-slider-three .owl-theme .owl-nav [class*='owl-'] {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  z-index: 100;
  width: 65px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: rgba(var(--govity-black-rgb), 1);
  background-color: rgba(var(--govity-white-rgb), 0.2);
  border: none;
  border-radius: 50%;
  margin: 0;
  text-align: center;
  transition: all 500ms ease;
  padding: 23px 0 !important;
}

.main-slider-three .owl-theme .owl-nav [class*='owl-']:hover {
  color: rgba(var(--govity-black-rgb), 1);
  background-color: rgba(var(--govity-white-rgb), 1);
}

.main-slider-three .owl-theme .owl-nav [class*='owl-'] + [class*='owl-'] {
  margin-top: 10px;
}

/*--------------------------------------------------------------
# Update One
--------------------------------------------------------------*/
.update-one {
  position: relative;
  display: block;
  background-color: var(--govity-base);
  padding: 20px 0;
  z-index: 1;
}

.update-one__left {
  position: relative;
  display: block;
  margin-right: 4px;
}

.update-one__city-update-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--govity-black);
  padding: 5px 0;
}

.update-one__city-update-icon {
  position: relative;
  display: flex;
  align-items: center;
}

.update-one__city-update-icon img {
  width: auto;
}

.update-one__city-update-text {
  margin-left: 10px;
}

.update-one__city-update-text p {
  font-size: 14px;
  color: var(--govity-white);
  font-weight: 700;
  letter-spacing: var(--govity-letter-spacing-two);
}

.update-one__right {
  position: relative;
  display: block;
}

.update-one__carousel {
  position: relative;
  display: block;
}

.update-one__single {
  position: relative;
  display: block;
}

.update-one__text {
  font-size: 18px;
  color: #ceffe5;
  line-height: 28px;
  letter-spacing: var(--govity-letter-spacing-two);
  padding-top: 7px;
}

.update-one__carousel.owl-theme .owl-nav {
  position: absolute;
  top: 62%;
  right: 0;
  margin: 0;
  transform: translateY(-50%);
}

.update-one__carousel.owl-theme .owl-nav .owl-next {
  color: var(--govity-white);
  font-size: 18px;
  margin: 0;
  text-align: center;
  transition: all 500ms ease;
}

.update-one__carousel.owl-theme .owl-nav .owl-prev {
  color: var(--govity-white);
  font-size: 18px;
  margin: 0;
  text-align: center;
  transition: all 500ms ease;
}

.update-one__carousel.owl-theme .owl-nav .owl-prev {
  margin-right: 10px;
}

.update-one__carousel.owl-theme .owl-nav .owl-next span,
.update-one__carousel.owl-theme .owl-nav .owl-prev span {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.update-one__carousel.owl-theme .owl-nav .owl-next:hover,
.update-one__carousel.owl-theme .owl-nav .owl-prev:hover {
  color: var(--govity-black);
  background-color: transparent;
}

/*--------------------------------------------------------------
# Department One
--------------------------------------------------------------*/
.department-one {
  position: relative;
  display: block;
  background-color: var(--govity-base);
  padding: 60px 0 26px;
  z-index: 1;
}

.department-one__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.02;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
}

.department-one__inner {
  position: relative;
  display: block;
}

.department-one__list {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-right: -9px;
  margin-left: -9px;
}

.department-one__single {
  position: relative;
  flex: 0 0 20%;
  max-width: 20%;
  width: 100%;
  padding-left: 9px;
  padding-right: 9px;
  margin-bottom: 30px;
}

.department-one__content {
  position: relative;
  display: block;
  background-color: var(--govity-white);
  padding: 70px 30px 27px;
  overflow: hidden;
}

.department-one__title {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: var(--govity-letter-spacing-two);
}

.department-one__title a {
  color: var(--govity-black);
  transition: all 500ms ease;
}

.department-one__title a:hover {
  color: var(--govity-base);
}

.department-one__icon {
  position: absolute;
  top: -56px;
  right: -35px;
  width: 141px;
  height: 141px;
  border-radius: 50%;
  background-color: #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.department-one__icon:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  border-radius: 50%;
  background-color: var(--govity-black);
  transform: scale(0);
  transform-origin: center;
  transform-style: preserve-3d;
  transition: all 0.4s cubic-bezier(0.62, 0.21, 0.45, 1.52);
  z-index: -1;
}

.department-one__single:hover .department-one__icon:before {
  transform: scaleX(1);
}

.department-one__icon span {
  font-size: 34px;
  color: var(--govity-base);
  position: relative;
  top: 24px;
  right: 12px;
  transition: all 500ms linear;
  transition-delay: 0.1s;
  transform: scale(1);
}

.department-one__single:hover .department-one__icon span {
  transform: scale(0.9);
  color: var(--govity-white);
}

.department-one__text {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 28px;
  color: #ceffe5;
  text-align: center;
  padding-top: 23px;
  border-top: 1px solid rgba(var(--govity-white-rgb), 0.1);
  letter-spacing: var(--govity-letter-spacing-two);
}

.department-one__text a {
  color: var(--govity-white);
  transition: all 500ms ease;
}

.department-one__text a:hover {
  color: var(--govity-black);
}

/*--------------------------------------------------------------
# About One
--------------------------------------------------------------*/
.about-one {
  position: relative;
  display: block;
  padding: 120px 0 120px;
  z-index: 1;
}

.about-one__left {
  position: relative;
  display: block;
  margin-right: 135px;
}

.about-one__shape-3 {
  position: absolute;
  top: 170px;
  left: -335px;
  opacity: 0.1;
  z-index: 2;
}

.about-one__shape-3 img {
  width: auto;
}

.about-one__img-box {
  position: relative;
  display: block;
}

.about-one__img {
  position: relative;
  display: block;
  z-index: 1;
}

.about-one__img img {
  width: 100%;
}

.about-one__img-2 {
  position: absolute;
  right: -131px;
  bottom: -26px;
  z-index: 2;
}

.about-one__img-2 img {
  width: auto;
}

.about-one__shape-2 {
  position: absolute;
  bottom: 93px;
  left: -40px;
  opacity: 0.1;
}

.about-one__shape-2 img {
  width: auto;
}

.about-one__video-link {
  position: absolute;
  top: 83px;
  right: -38px;
  z-index: 2;
}

.about-one__video-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  font-size: 23px;
  color: var(--govity-white);
  background-color: var(--govity-base);
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.about-one__video-icon:hover {
  background-color: var(--govity-black);
  color: var(--govity-white);
}

.about-one__video-icon:before {
  position: absolute;
  top: -20px;
  left: -20px;
  right: -20px;
  bottom: -20px;
  content: '';
  border: 1px solid var(--govity-bdr-color);
}

.about-one__video-icon:after {
  position: absolute;
  top: -40px;
  left: -40px;
  right: -40px;
  bottom: -40px;
  content: '';
  border: 1px solid var(--govity-bdr-color);
}

.about-one__video-link .ripple,
.about-one__video-icon .ripple:before,
.about-one__video-icon .ripple:after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 160px;
  height: 160px;
  transform: translate(-50%, -50%);
  -ms-box-shadow: 0 0 0 0 rgba(var(--govity-base-rgb), 0.6);
  -o-box-shadow: 0 0 0 0 rgba(var(--govity-base-rgb), 0.6);
  box-shadow: 0 0 0 0 rgba(var(--govity-base-rgb), 0.6);
  animation: ripple 3s infinite;
}

.about-one__video-icon .ripple:before {
  animation-delay: 0.9s;
  content: '';
  position: absolute;
}

.about-one__video-icon .ripple:after {
  animation-delay: 0.6s;
  content: '';
  position: absolute;
}

.about-one__call-box {
  position: absolute;
  left: 74px;
  bottom: -53px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 43px 0px rgba(0, 0, 0, 0.07);
  max-width: 305px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px 30px 14px;
  z-index: 2;
}

.about-one__call-box::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 10px;
  background-color: var(--govity-base);
}

.about-one__call-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: var(--govity-primary);
}

.about-one__call-icon span {
  position: relative;
  display: inline-block;
  font-size: 32px;
  color: var(--govity-base);
  transition: all 500ms linear;
  transition-delay: 0.1s;
}

.about-one__call-box:hover .about-one__call-icon span {
  transform: scale(0.9);
}

.about-one__call-box-content {
  position: relative;
  display: block;
  margin-left: 20px;
}

.about-one__call-text {
  font-size: 14px;
  letter-spacing: var(--govity-letter-spacing-two);
}

.about-one__call-number {
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: var(--govity-letter-spacing-two);
}

.about-one__call-number a {
  color: var(--govity-black);
  transition: all 500ms ease;
}

.about-one__call-number a:hover {
  color: var(--govity-base);
}

.about-one__shape-1 {
  position: absolute;
  top: 200px;
  right: -241px;
}

.about-one__shape-1 img {
  width: auto;
}

.about-one__right {
  position: relative;
  display: block;
  margin-left: 68px;
}

.about-one__right .section-title {
  margin-bottom: 29px;
}

.about-one__icon-and-big-text {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 31px;
  padding-bottom: 33px;
  border-bottom: 1px solid var(--govity-bdr-color);
}

.about-one__icon {
  position: relative;
  display: inline-block;
}

.about-one__icon span {
  position: relative;
  display: inline-block;
  font-size: 62px;
  color: var(--govity-black);
  transition: all 500ms linear;
  transition-delay: 0.1s;
}

.about-one__icon:hover span {
  transform: scale(0.9);
}

.about-one__big-text {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: var(--govity-letter-spacing-two);
  color: var(--govity-base);
  margin-left: 20px;
}

.about-one__btn-box-and-signature {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30px;
}

.about-one__signature {
  position: relative;
  display: block;
  margin-left: 30px;
}

.about-one__signature img {
  width: auto;
}

/*--------------------------------------------------------------
# Feature One
--------------------------------------------------------------*/
.feature-one {
  position: relative;
  display: block;
  padding-bottom: 90px;
  z-index: 1;
}

.feature-one__single {
  position: relative;
  display: block;
  margin-bottom: 30px;
}

.feature-one__img-box {
  position: relative;
  display: block;
}

.feature-one__img {
  position: relative;
  display: block;
  overflow: hidden;
  z-index: 1;
}

.feature-one__img img {
  width: 100%;
  transition: all 500ms ease;
}

.feature-one__single:hover .feature-one__img img {
  transform: scale(1.05);
}

.feature-one__content {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  text-align: center;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
  padding: 49px 30px 34px;
  transition: all 500ms ease;
  z-index: 1;
}

.feature-one__single:hover .feature-one__content {
  opacity: 0;
}

.feature-one__icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -59px;
  left: 50%;
  transform: translateX(-50%);
  width: 123px;
  height: 123px;
  border-radius: 50%;
  background-image: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(243, 243, 243) 100%);
  z-index: -1;
}

.feature-one__icon span {
  position: relative;
  display: inline-block;
  font-size: 64px;
  color: var(--govity-base);
}

.feature-one__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
}

.feature-one__title a {
  color: var(--govity-black);
}

.feature-one__content-two {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  text-align: center;
  background-position: bottom left;
  transform: scale(1, 0);
  transform-origin: bottom center;
  transition: transform 500ms ease;
  z-index: 2;
}

.feature-one__single:hover .feature-one__content-two {
  transform: scale(1, 1);
}

.feature-one__content-two-top {
  position: relative;
  display: block;
  background-color: var(--govity-base);
  padding: 30px 30px 29px;
}

.feature-one__icon-2 {
  position: relative;
  display: inline-block;
}

.feature-one__icon-2 span {
  position: relative;
  display: inline-block;
  font-size: 62px;
  color: var(--govity-white);
  transition: all 500ms linear;
  transition-delay: 0.1s;
}

.feature-one__single:hover .feature-one__icon-2 span {
  transform: scale(0.9);
}

.feature-one__title-2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  margin-top: 9px;
}

.feature-one__title-2 a {
  color: var(--govity-white);
}

.feature-one__content-two-bottom {
  position: relative;
  display: block;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
  padding: 21px 10px 10px;
}

.feature-one__content-two-btn-box {
  position: relative;
  display: block;
  background-color: var(--govity-primary);
  margin-top: 24px;
}

.feature-one__content-two-read-more {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: var(--govity-gray);
  letter-spacing: var(--govity-letter-spacing-two);
  padding: 13px 30px 13px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 500ms ease;
}

.feature-one__content-two-read-more:hover {
  color: var(--govity-black);
}

/*--------------------------------------------------------------
# Services One
--------------------------------------------------------------*/
.services-one {
  position: relative;
  display: block;
}

.services-one::before {
  content: '';
  position: absolute;
  top: 78px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--govity-primary);
}

.services-one__left {
  position: relative;
  display: block;
  margin-right: -340px;
  margin-left: -276px;
  margin-top: -22px;
}

.services-one__shape-2 {
  position: absolute;
  top: 230px;
  left: 48px;
}

.services-one__shape-2 img {
  width: auto;
}

.services-one__img-box {
  position: relative;
  display: block;
}

.services-one__img {
  position: relative;
  display: block;
  mix-blend-mode: darken;
  z-index: 1;
}

.services-one__img img {
  width: 100%;
}

.services-one__img-shadow {
  position: absolute;
  left: 131px;
  bottom: 0px;
  width: 545px;
  height: 200px;
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgb(255, 255, 255) 11%, rgba(255, 255, 255, 0) 100%);
}

.services-one__right {
  position: relative;
  display: block;
  z-index: 1;
}

.services-one__right::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: -10000px;
  bottom: 0;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
  z-index: -1;
}

.services-one__shape-1 {
  position: absolute;
  top: 106px;
  left: -83px;
}

.services-one__shape-1 img {
  width: auto;
}

.services-one__shape-3 {
  position: absolute;
  bottom: 0;
  right: -362px;
  opacity: 0.15;
}

.services-one__shape-3 img {
  width: auto;
}

.services-one__points-title-box {
  position: relative;
  display: block;
  background-color: var(--govity-base);
  padding: 20px 78px 24px;
  z-index: 1;
}

.services-one__points-title-box::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: -10000px;
  bottom: 0;
  background-color: var(--govity-base);
  z-index: -1;
}

.services-one__points-title-box::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 78px;
  border-top: 10px solid var(--govity-base);
  border-left: 38px solid transparent;
  border-right: 38px solid transparent;
  z-index: -1;
}

.services-one__points-title-box p {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  color: var(--govity-white);
  letter-spacing: var(--govity-letter-spacing-two);
}

.services-one__points-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-left: 78px;
  padding-bottom: 44px;
  padding-top: 38px;
}

.services-one__points-list {
  position: relative;
  display: block;
  max-width: 300px;
  width: 100%;
}

.services-one__points-list li {
  position: relative;
  display: block;
}

.services-one__points-list li a {
  font-size: 18px;
  letter-spacing: var(--govity-letter-spacing-two);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--govity-gray);
  padding-bottom: 11px;
  padding-top: 14px;
  border-bottom: 1px solid var(--govity-bdr-color);
  transition: all 500ms ease;
}

.services-one__points-list li:last-child a {
  border-bottom: none;
}

.services-one__points-list li:hover a {
  color: var(--govity-black);
}

.services-one__points-list li:hover a span {
  color: var(--govity-base);
}

/*--------------------------------------------------------------
# Video One
--------------------------------------------------------------*/
.video-one {
  position: relative;
  display: block;
  padding: 120px 0 174px;
  background-color: var(--govity-black);
  z-index: 1;
}

.video-one__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.2;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
}

.video-one__inner {
  position: relative;
  display: block;
  text-align: center;
  z-index: 1;
}

.video-one__inner .video-one__video-icon {
  margin: 0 auto;
}

.video-one__video-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 87px;
  height: 87px;
  line-height: 87px;
  text-align: center;
  font-size: 23px;
  color: var(--govity-white);
  background-color: var(--govity-base);
  border-radius: 50%;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  z-index: 1;
}

.video-one__video-icon::before {
  content: '';
  position: absolute;
  top: -13px;
  left: -13px;
  right: -13px;
  bottom: -13px;
  border: 1px solid rgba(var(--govity-white-rgb), 0.2);
  border-radius: 50%;
}

.video-one__video-icon:hover {
  background-color: var(--govity-white);
  color: var(--govity-base);
}

.video-one__video-link .ripple,
.video-one__video-icon .ripple:before,
.video-one__video-icon .ripple:after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 111px;
  height: 111px;
  transform: translate(-50%, -50%);
  -ms-box-shadow: 0 0 0 0 rgba(var(--govity-primary-rgb), 0.6);
  -o-box-shadow: 0 0 0 0 rgba(var(--govity-primary-rgb), 0.6);
  box-shadow: 0 0 0 0 rgba(var(--govity-primary-rgb), 0.6);
  animation: ripple 3s infinite;
  border-radius: 50%;
}

.video-one__video-icon .ripple:before {
  animation-delay: 0.9s;
  content: '';
  position: absolute;
}

.video-one__video-icon .ripple:after {
  animation-delay: 0.6s;
  content: '';
  position: absolute;
}

.video-one__shape {
  position: absolute;
  top: 135px;
  left: 0;
  right: 0;
  z-index: -1;
}

.video-one__shape img {
  width: auto;
}

.video-one__inner .video-one__video-title {
  position: relative;
  margin-top: 27px;
  font-size: 60px;
  font-weight: 700;
  color: var(--govity-white);
  font-family: var(--govity-font);
  line-height: 72px;
}

/*--------------------------------------------------------------
# Documents One
--------------------------------------------------------------*/
.documents-one {
  position: relative;
  display: block;
  margin-top: -70px;
  z-index: 2;
}

.documents-one__inner {
  position: relative;
  display: flex;
  align-items: center;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
  padding-left: 10px;
}

.documents-one__left {
  position: relative;
  display: block;
  max-width: 280px;
  background-color: var(--govity-black);
  width: 100%;
  padding: 35px 50px 33px;
}

.documents-one__left p {
  font-size: 26px;
  font-weight: 700;
  line-height: 31px;
  color: var(--govity-white);
  letter-spacing: var(--govity-letter-spacing);
}

.documents-one__right {
  position: relative;
  display: block;
  max-width: 880px;
  width: 100%;
}

.documents-one__points {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.documents-one__points li {
  position: relative;
  display: block;
  flex: 0 0 50%;
  max-width: 50%;
  width: 100%;
}

.documents-one__single {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--govity-white);
  padding: 40px 50px 29px;
  border-right: 1px solid var(--govity-bdr-color);
  transition: all 500ms ease;
}

.documents-one__single::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(243, 243, 243) 100%);
  transition: all 500ms ease;
}

.documents-one__content {
  position: relative;
  display: block;
}

.documents-one__icon {
  position: relative;
  display: inline-block;
}

.documents-one__icon span {
  position: relative;
  display: inline-block;
  font-size: 24px;
  color: var(--govity-base);
}

.documents-one__title {
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: var(--govity-letter-spacing-two);
}

.documents-one__title a {
  color: var(--govity-black);
  transition: all 500ms ease;
}

.documents-one__title a:hover {
  color: var(--govity-base);
}

.documents-one__text {
  margin-top: 1px;
  letter-spacing: var(--govity-letter-spacing-two);
}

.documents-one__icon-two {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  background-color: var(--govity-base);
  border-radius: 50%;
  transition: all 500ms ease;
}

.documents-one__icon-two span {
  position: relative;
  display: inline-block;
  font-size: 24px;
  color: var(--govity-white);
  transition: all 500ms ease;
}

.documents-one__icon-two:hover {
  background-color: var(--govity-black);
}

/*--------------------------------------------------------------
# Counter One
--------------------------------------------------------------*/
.counter-one {
  position: relative;
  display: block;
  background-color: var(--govity-base);
  padding: 180px 0 105px;
  margin-top: -80px;
  z-index: 1;
}

.counter-one__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.1;
  mix-blend-mode: multiply;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
}

.counter-one__inner {
  position: relative;
  display: block;
}

.counter-one__list {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.counter-one__list li {
  position: relative;
  display: flex;
  align-items: center;
}

.counter-one__count {
  position: relative;
  display: block;
  margin-right: 26px;
}

.counter-one__count h3 {
  font-size: 60px;
  font-weight: 700;
  line-height: 60px !important;
  font-family: var(--govity-font) !important;
  letter-spacing: var(--govity-letter-spacing);
  color: var(--govity-white);
}

.counter-one__count > span {
  font-size: 60px;
  font-weight: 700;
  line-height: 60px !important;
  font-family: var(--govity-font) !important;
  letter-spacing: var(--govity-letter-spacing);
  color: var(--govity-white);
  top: 14px;
  position: relative;
  left: -5px;
}

.counter-one__text {
  font-size: 20px;
  line-height: 24px;
  color: #ceffe5;
  letter-spacing: var(--govity-letter-spacing-two);
}

/*--------------------------------------------------------------
# Team One
--------------------------------------------------------------*/
.team-one {
  position: relative;
  display: block;
  padding: 120px 0 90px;
  z-index: 1;
}

.team-one__inner {
  position: relative;
  display: block;
  margin-left: 60px;
  margin-right: 60px;
}

.team-one__single {
  position: relative;
  display: block;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
  border: 2px solid transparent;
  border-bottom: none;
  padding: 30px 30px 40px;
  border-top-left-radius: 165px;
  border-top-right-radius: 165px;
  text-align: center;
  margin-bottom: 30px;
  transition: all 500ms ease;
}

.team-one__single:hover {
  border: 2px solid var(--govity-base);
  border-bottom: none;
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
}

.team-one__single::before {
  content: '';
  position: absolute;
  left: -2px;
  right: -2px;
  bottom: 0;
  height: 2px;
  background-color: var(--govity-base);
}

.team-one__img-box {
  position: relative;
  display: block;
}

.team-one__img {
  position: relative;
  display: block;
  border-radius: 50%;
  overflow: hidden;
  z-index: 1;
}

.team-one__img:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: '';
  border-radius: 50%;
  background-color: rgba(var(--govity-black-rgb), 0.5);
  transform: scale(0.5);
  opacity: 0;
  transition: all 0.4s linear;
  z-index: 1;
}

.team-one__single:hover .team-one__img:before {
  transform: scale(1);
  opacity: 1;
}

.team-one__img img {
  width: 100%;
  border-radius: 50%;
  transition: all 500ms ease;
}

.team-one__single:hover .team-one__img img {
  transform: scale(1.08);
}

.team-one__content {
  position: relative;
  display: block;
  margin-top: 21px;
}

.team-one__name {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
}

.team-one__name a {
  color: var(--govity-black);
  transition: all 500ms ease;
}

.team-one__name a:hover {
  color: var(--govity-base);
}

.team-one__social {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.team-one__social a {
  position: relative;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--govity-black);
  background-color: var(--govity-primary);
  font-size: 14px;
  border-radius: 50%;
  overflow: hidden;
  transition: all 500ms ease;
  z-index: 1;
}

.team-one__social a:hover {
  color: var(--govity-white);
  background-color: var(--govity-base);
}

.team-one__social a:after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: var(--govity-base);
  transition-delay: 0.1s;
  transition-timing-function: ease-in-out;
  transition-duration: 0.4s;
  transition-property: all;
  opacity: 1;
  transform-origin: top;
  transform-style: preserve-3d;
  transform: scaleY(0);
  z-index: -1;
}

.team-one__social a:hover:after {
  opacity: 1;
  transform: scaleY(1);
}

.team-one__social a + a {
  margin-left: 10px;
}

.team-one__shape-1 {
  position: absolute;
  bottom: 44px;
  left: 50%;
  transform: translateX(-50%);
}

.team-one__shape-1 img {
  width: auto;
}

.team-one__shape-2 {
  position: absolute;
  bottom: 76px;
  left: 50%;
  transform: translateX(-50%);
}

.team-one__shape-2 img {
  width: auto;
}

.team-one__shape-3 {
  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
}

.team-one__shape-3 img {
  width: auto;
}

/*--------------------------------------------------------------
# Testimonial One
--------------------------------------------------------------*/
.testimonial-one {
  position: relative;
  display: block;
  background-color: var(--govity-primary);
  padding: 120px 0 120px;
  z-index: 1;
}

.testimonial-one__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  mix-blend-mode: luminosity;
}

.testimonial-one__bottom {
  position: relative;
  display: block;
}

.testimonial-one__carousel {
  position: relative;
  display: block;
}

.testimonial-one__single {
  position: relative;
  display: block;
  border-radius: 30px;
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
  z-index: 1;
  transform: translateY(0px);
  transition: transform 500ms ease;
}

.testimonial-one__single:hover {
  transform: translateY(-10px);
}

.testimonial-one__single-inner {
  position: relative;
  display: block;
  border-radius: 30px;
  background-color: rgb(255, 255, 255);
  padding: 30px 0px 23px;
  overflow: hidden;
  z-index: 1;
}

.testimonial-one__text {
  font-size: 18px;
  line-height: 34px;
  letter-spacing: var(--govity-letter-spacing-two);
  padding-left: 40px;
  padding-right: 64px;
}

.testimonial-one__client-info {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 29px;
}

.testimonial-one__client-img-box {
  position: relative;
  display: block;
  width: 158px;
  height: 158px;
  border-radius: 50%;
  background-color: var(--govity-base);
  padding: 10px;
}

.testimonial-one__client-img {
  position: relative;
  display: block;
  height: 138px;
  width: 138px;
  border-radius: 50%;
  background-color: var(--govity-white);
}

.testimonial-one__client-img img {
  width: auto;
  border-radius: 50%;
  border: 11px solid var(--govity-white);
}

.testimonial-one__client-content {
  position: relative;
  display: block;
  margin-left: 40px;
  top: -13px;
}

.testimonial-one__client-rating {
  position: relative;
  display: flex;
  align-items: center;
}

.testimonial-one__client-rating span {
  position: relative;
  display: inline-block;
  font-size: 15px;
  color: var(--govity-base);
}

.testimonial-one__client-rating span + span {
  margin-left: 3px;
}

.testimonial-one__client-name {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  margin-top: 12px;
}

.testimonial-one__client-sub-title {
  font-size: 14px;
  line-height: 21px;
}

.testimonial-one__quote {
  position: absolute;
  right: 40px;
  bottom: 82px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 72px;
  border: 1px solid var(--govity-bdr-color);
  border-radius: 50%;
  background-color: var(--govity-white);
  transition: all 500ms ease;
}

.testimonial-one__single:hover .testimonial-one__quote {
  border: 1px solid var(--govity-base);
}

.testimonial-one__quote span {
  position: relative;
  display: inline-block;
  font-size: 32px;
  color: #dddddd;
  transition: all 500ms ease;
}

.testimonial-one__single:hover .testimonial-one__quote span {
  color: var(--govity-base);
}

.testimonial-one__shape-1 {
  position: absolute;
  left: -98px;
  right: -98px;
  top: 0;
  opacity: 0.07;
  mix-blend-mode: luminosity;
  z-index: -1;
}

.testimonial-one__shape-1 img {
  width: auto;
}

.testimonial-one__shape-2 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 181px;
  z-index: -1;
}

.testimonial-one__shape-2 img {
  width: auto;
}

.testimonial-one__carousel.owl-carousel .owl-stage-outer {
  overflow: visible;
}

.testimonial-one__carousel.owl-carousel .owl-item {
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 500ms ease,
    visibility 500ms ease;
}

.testimonial-one__carousel.owl-carousel .owl-item.active {
  opacity: 1;
  visibility: visible;
}

/*--------------------------------------------------------------
# Portfolio One
--------------------------------------------------------------*/
.portfolio-one {
  position: relative;
  display: block;
  padding: 120px 0 0;
}

.portfolio-one__top {
  position: relative;
  display: block;
}

.portfolio-one__left {
  position: relative;
  display: block;
}

.portfolio-one__right {
  position: relative;
  display: block;
  margin-left: 70px;
  margin-top: 28px;
}

.portfolio-one__text {
  position: relative;
  display: block;
}

.portfolio-one__bottom {
  position: relative;
  display: block;
}

.portfolio-one__bottom .container {
  max-width: 1318px;
}

.portfolio-one__carousel {
  position: relative;
  display: block;
}

.portfolio-one__carousel.owl-carousel .owl-stage-outer {
  overflow: visible;
}

.portfolio-one__single {
  position: relative;
  display: block;
}

.portfolio-one__single.mar-top {
  margin-top: 105px;
}

.portfolio-one__img-box {
  position: relative;
  display: block;
}

.portfolio-one__img {
  position: relative;
  display: block;
  overflow: hidden;
  z-index: 1;
}

.portfolio-one__img:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(var(--govity-black-rgb), 0.4);
  visibility: hidden;
  transform-origin: top center;
  transform: scaleY(0) translateZ(100px);
  transition:
    opacity 500ms ease,
    visibility 500ms ease,
    transform 700ms ease;
}

.portfolio-one__single:hover .portfolio-one__img:before {
  visibility: visible;
  transform: scaleY(1) translateZ(0px);
}

.portfolio-one__img img {
  width: 100%;
}

.portfolio-one__content {
  position: absolute;
  bottom: 30px;
  left: 0;
  transform: translateY(66%);
  transition:
    opacity 500ms ease,
    visibility 500ms ease,
    transform 500ms ease;
  visibility: hidden;
  opacity: 0;
  z-index: 2;
}

.portfolio-one__single:hover .portfolio-one__content {
  visibility: visible;
  transform: translateY(0%);
  transition-delay: 500ms;
  opacity: 1;
}

.portfolio-one__sub-title {
  position: relative;
  display: inline;
  font-size: 12px;
  color: var(--govity-white);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 12px;
  background-color: var(--govity-base);
  padding: 7px 30px 7px;
}

.portfolio-one__title {
  position: relative;
  display: block;
  background-color: var(--govity-white);
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
  padding: 18px 30px 18px;
  padding-right: 60px;
}

.portfolio-one__title a {
  color: var(--govity-black);
  transition: all 500ms ease;
}

.portfolio-one__title a:hover {
  color: var(--govity-base);
}

/*--------------------------------------------------------------
# Brand One
--------------------------------------------------------------*/
.brand-one {
  position: relative;
  display: block;
  padding: 110px 0 60px;
}

.brand-one__title {
  position: relative;
  text-align: center;
  margin: 0 auto;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: 60px;
}

.brand-one__title:before {
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 0;
  content: '';
  height: 1px;
  background-color: #dddddd;
  max-width: 445px;
  width: 100%;
  transform: translateY(-50%);
}

.brand-one__title:after {
  position: absolute;
  top: 50%;
  bottom: 0;
  right: 0;
  content: '';
  height: 1px;
  background-color: #dddddd;
  max-width: 445px;
  width: 100%;
  transform: translateY(-50%);
}

.brand-one__carousel {
  position: relative;
  display: block;
}

.brand-one__single {
  position: relative;
  display: block;
  text-align: center;
  z-index: 1;
}

.brand-one__img:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--govity-primary);
  opacity: 0;
  transition: 0.5s;
  z-index: -1;
}

.brand-one__single:hover .brand-one__img:before {
  opacity: 1;
}

.brand-one__img {
  position: relative;
  display: inline-block;
  z-index: 10;
}

.brand-one__img > img {
  position: relative;
  transition: 500ms;
  opacity: 0.2;
}

.brand-one__single:hover .brand-one__img > img {
  opacity: 0.6;
}

/*--------------------------------------------------------------
# Event One
--------------------------------------------------------------*/
.event-one {
  position: relative;
  display: block;
}

.event-one__shape-1 {
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0.2;
  mix-blend-mode: luminosity;
  z-index: 2;
}

.event-one__shape-1 img {
  width: auto;
}

.event-one__left {
  position: relative;
  display: block;
  padding: 120px 0 60px;
  padding-right: 60px;
  background-color: var(--govity-primary);
  z-index: 1;
}

.event-one__left::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100000px;
  right: 0;
  bottom: 0;
  background-color: var(--govity-primary);
  z-index: -1;
}

.event-one__points {
  position: relative;
  display: block;
}

.event-one__points > li + li {
  margin-top: 30px;
}

.event-one__single {
  position: relative;
  display: flex;
  align-items: center;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
  padding: 20px 30px 20px;
}

.event-one__img-box {
  position: relative;
  display: block;
}

.event-one__img {
  position: relative;
  display: block;
  width: 166px;
  border-radius: 50%;
  overflow: hidden;
  z-index: 1;
}

.event-one__img:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: '';
  border-radius: 50%;
  background-color: rgba(var(--govity-black-rgb), 0.5);
  transform: scale(0.5);
  opacity: 0;
  transition: all 0.4s linear;
  z-index: 1;
}

.event-one__single:hover .event-one__img:before {
  transform: scale(1);
  opacity: 1;
}

.event-one__img img {
  width: 100%;
  border-radius: 50%;
  transition: all 500ms ease;
}

.event-one__single:hover .event-one__img img {
  transform: scale(1.08);
}

.event-one__date {
  position: absolute;
  top: 0;
  left: -10px;
  width: 65px;
  height: 65px;
  background-color: var(--govity-base);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  z-index: 2;
}

.event-one__date p {
  font-size: 12px;
  line-height: 14px;
  color: var(--govity-white);
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}

.event-one__content {
  position: relative;
  display: block;
  margin-left: 40px;
}

.event-one__tag {
  position: relative;
  display: inline-block;
  background-color: var(--govity-primary);
  padding: 8px 20px 8px;
}

.event-one__tag p {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: var(--govity-gray);
}

.event-one__meta {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 4px;
}

.event-one__meta li {
  position: relative;
  display: flex;
  align-items: center;
}

.event-one__meta li + li {
  margin-left: 8px;
}

.event-one__meta li .icon {
  position: relative;
  display: inline-block;
}

.event-one__meta li .icon span {
  position: relative;
  display: inline-block;
  font-size: 15px;
  color: var(--govity-base);
}

.event-one__meta li .text {
  position: relative;
  display: block;
  margin-left: 4px;
}

.event-one__meta li .text p {
  font-size: 14px;
}

.event-one__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
}

.event-one__title a {
  color: var(--govity-black);
  transition: all 500ms ease;
}

.event-one__title a:hover {
  color: var(--govity-base);
}

.event-one__right {
  position: relative;
  display: block;
}

.event-one__subscribe-top {
  position: relative;
  display: block;
  background-color: var(--govity-black);
  text-align: center;
  padding-left: 100px;
  padding-right: 40px;
  padding-top: 120px;
  padding-bottom: 30px;
  z-index: 1;
}

.event-one__subscribe-top-bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: -362px;
  opacity: 0.2;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
}

.event-one__subscribe-top::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: -10000px;
  bottom: 0;
  background-color: var(--govity-black);
  z-index: -1;
}

.event-one__subscribe-top::after {
  content: '';
  position: absolute;
  bottom: 76px;
  left: 214px;
  width: 1px;
  height: 64px;
  background-color: #3e3c3c;
}

.event-one__subscribe-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 133px;
  height: 133px;
  background-color: rgba(var(--govity-white-rgb), 0.1);
  border-radius: 50%;
  margin: 0 auto;
  transition: all 500ms ease;
}

.event-one__subscribe-icon:hover {
  background-color: var(--govity-base);
}

.event-one__subscribe-icon span {
  position: relative;
  display: inline-block;
  font-size: 65px;
  color: var(--govity-white);
}

.event-one__subscribe-text {
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  color: var(--govity-white);
  letter-spacing: var(--govity-letter-spacing);
  margin-top: 23px;
  margin-bottom: 100px;
}

.event-one__subscribe-text-2 {
  color: var(--govity-white);
  letter-spacing: var(--govity-letter-spacing-two);
}

.event-one__subscribe-bottom {
  position: relative;
  display: block;
  padding-left: 60px;
  background-color: var(--govity-base);
  padding-bottom: 48px;
  padding-top: 60px;
  z-index: 1;
}

.event-one__subscribe-bottom-bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: -362px;
  mix-blend-mode: multiply;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
}

.event-one__subscribe-bottom::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: -10000px;
  bottom: 0;
  background-color: var(--govity-base);
  z-index: -1;
}

.event-one__subscribe-bottom::after {
  content: '';
  position: absolute;
  top: 0px;
  left: 148px;
  border-left: 59px solid transparent;
  border-right: 59px solid transparent;
  border-top: 20px solid #151515;
}

.event-one__email-box {
  position: relative;
  display: block;
}

.event-one__email-input-box {
  position: relative;
  display: block;
  margin-bottom: 10px;
}

.event-one__email-input-box input[type='email'] {
  height: 54px;
  width: 100%;
  background-color: var(--govity-white);
  outline: none;
  font-size: 14px;
  border: none;
  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
  color: var(--govity-gray);
}

.event-one__subscribe-btn {
  outline: none;
  border: none;
  width: 100%;
  background-color: var(--govity-black);
}

.event-one__subscribe-btn:hover {
  color: var(--govity-base);
}

.event-one__subscribe-btn:before {
  background-color: var(--govity-white);
}

.event-one__subscribe-text-3 {
  color: #ceffe5;
  letter-spacing: var(--govity-letter-spacing-two);
  text-align: center;
  margin-top: 21px;
}

/*--------------------------------------------------------------
# News One
--------------------------------------------------------------*/
.news-one {
  position: relative;
  display: block;
  padding: 120px 0 90px;
  z-index: 1;
}

.news-one__single {
  position: relative;
  display: block;
  margin-bottom: 30px;
}

.news-one__img-box {
  position: relative;
  display: block;
}

.news-one__img {
  position: relative;
  display: block;
  overflow: hidden;
  z-index: 1;
}

.news-one__img:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  background-color: rgba(var(--govity-black-rgb), 0.3);
  opacity: 1;
  transition: 0.5s;
  transform: perspective(400px) rotateY(-90deg) scale(0.2);
  transform-origin: top;
  z-index: 1;
}

.news-one__single:hover .news-one__img:before {
  opacity: 1;
  transform: perspective(400px) rotateY(0deg) scale(1);
}

.news-one__img img {
  width: 100%;
  transition-delay: 0.1s;
  transition-timing-function: ease-in-out;
  transition-duration: 0.7s;
  transition-property: all;
}

.news-one__single:hover .news-one__img img {
  transform: scale(1.1) rotate(2deg);
}

.news-one__date {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: var(--govity-base);
  padding: 12px 15px 12px;
  z-index: 2;
}

.news-one__date::before {
  content: '';
  position: absolute;
  bottom: -6px;
  left: 0;
  right: 0;
  height: 6px;
  background-color: rgba(var(--govity-base-rgb), 0.3);
}

.news-one__date p {
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  color: var(--govity-white);
  text-transform: uppercase;
  text-align: center;
}

.news-one__content {
  position: relative;
  display: block;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
  padding: 30px 40px 13px;
}

.news-one__user-and-meta {
  position: relative;
  display: flex;
  align-items: center;
}

.news-one__user {
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 20px;
  margin-right: 20px;
  border-right: 1px solid var(--govity-bdr-color);
}

.news-one__user-img {
  position: relative;
  display: block;
  z-index: 1;
}

.news-one__user-img::before {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
  z-index: -1;
}

.news-one__user-img img {
  width: auto;
  border-radius: 50%;
}

.news-one__user-text {
  position: relative;
  display: block;
  margin-left: 10px;
}

.news-one__user-text p {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: var(--govity-letter-spacing-two);
}

.news-one__meta {
  position: relative;
  display: flex;
  align-items: center;
}

.news-one__meta .icon {
  position: relative;
  display: inline-block;
  margin-right: 4px;
}

.news-one__meta .icon span {
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: var(--govity-base);
}

.news-one__meta .text {
  position: relative;
  display: block;
}

.news-one__meta .text p {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: var(--govity-letter-spacing-two);
}

.news-one__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  margin-top: 13px;
  padding-bottom: 22px;
  margin-bottom: 13px;
  border-bottom: 1px solid var(--govity-bdr-color);
}

.news-one__title a {
  color: var(--govity-black);
  transition: all 500ms ease;
}

.news-one__title a:hover {
  color: var(--govity-base);
}

.news-one__btn {
  position: relative;
  display: block;
}

.news-one__btn a {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: var(--govity-letter-spacing-two);
  line-height: 24px;
  color: var(--govity-gray);
  transition: all 500ms ease;
}

.news-one__btn a:hover {
  color: var(--govity-base);
}

.news-one__btn a i {
  position: relative;
  top: 1px;
  left: 9px;
}

/*--------------------------------------------------------------
# Site Footer
--------------------------------------------------------------*/
.site-footer {
  position: relative;
  display: block;
  background-color: var(--govity-black);
  z-index: 1;
}

.site-footer__img {
  position: absolute;
  bottom: 0;
  left: 0;
  mix-blend-mode: multiply;
  opacity: 0.1;
  z-index: -1;
}

.site-footer__img img {
  width: auto;
}

.site-footer__top {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #000000;
  padding: 40px 60px 40px;
}

.footer-widget__logo {
  position: relative;
  display: block;
}

.footer-widget__subscribe-box {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 785px;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-widget__subscribe-box .mc-form__response {
  flex: 0 0 100%;
}

.footer-widget__subscribe-box:before {
  content: '';
  position: absolute;
  top: 0;
  left: -30px;
  bottom: 0;
  width: 1px;
  background-color: rgba(var(--govity-white-rgb), 0.1);
}

.footer-widget__subscribe-text {
  position: relative;
  display: block;
}

.footer-widget__subscribe-text p {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  color: var(--govity-white);
  letter-spacing: var(--govity-letter-spacing);
}

.footer-widget__email-box {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 540px;
  width: 100%;
}

.footer-widget__email-input-box {
  position: relative;
  display: block;
  max-width: 353px;
  width: 100%;
}

.footer-widget__email-input-box input[type='email'] {
  height: 54px;
  width: 100%;
  background-color: var(--govity-white);
  outline: none;
  font-size: 14px;
  border: none;
  padding-left: 30px;
  padding-right: 30px;
  color: var(--govity-gray);
}

.footer-widget__subscribe-btn {
  outline: none;
  border: none;
  background-color: var(--govity-base);
  margin-left: 10px;
  padding: 12px 50px 12px;
}

.footer-widget__subscribe-btn:hover {
  color: var(--govity-base);
}

.footer-widget__subscribe-btn:before {
  background-color: var(--govity-white);
}

.footer-widget__subscribe-text-3 {
  color: #ceffe5;
  letter-spacing: var(--govity-letter-spacing-two);
  text-align: center;
  margin-top: 21px;
}

.site-footer__middle {
  position: relative;
  display: block;
  padding-top: 73px;
  padding-bottom: 80px;
}

.footer-widget__title-box {
  position: relative;
  display: block;
  margin-bottom: 24px;
}

.footer-widget__title {
  font-size: 20px;
  color: var(--govity-white);
  line-height: 28px;
  font-weight: 700;
  letter-spacing: var(--govity-letter-spacing-two);
}

.footer-widget__Contact {
  position: relative;
  display: block;
}

.footer-widget__Contact-text {
  letter-spacing: var(--govity-letter-spacing-two);
  color: #777777;
}

.footer-widget__Contact-list {
  position: relative;
  display: block;
  padding-top: 22px;
}

.footer-widget__Contact-list li {
  position: relative;
  display: flex;
  align-items: center;
}

.footer-widget__Contact-list li .icon {
  font-size: 16px;
  margin-right: 12px;
}

.footer-widget__Contact-list li:first-child .icon {
  position: relative;
  top: 3px;
}

.footer-widget__Contact-list li .icon span {
  color: var(--govity-base);
}

.footer-widget__Contact-list li .text p {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: var(--govity-letter-spacing-two);
}

.footer-widget__Contact-list li .text p a {
  color: var(--govity-white);
}

.footer-widget__Contact-list li .text p a:hover {
  color: var(--govity-base);
  transition: all 500ms ease;
}

.site-footer__social {
  display: flex;
  align-items: center;
  margin-top: 22px;
}

.site-footer__social a {
  position: relative;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--govity-white);
  background-color: #000000;
  font-size: 15px;
  border-radius: 50%;
  overflow: hidden;
  transition: all 500ms ease;
  z-index: 1;
}

.site-footer__social a:hover {
  color: var(--govity-base);
  background-color: var(--govity-white);
}

.site-footer__social a:after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: var(--govity-white);
  transition-delay: 0.1s;
  transition-timing-function: ease-in-out;
  transition-duration: 0.4s;
  transition-property: all;
  opacity: 1;
  transform-origin: top;
  transform-style: preserve-3d;
  transform: scaleY(0);
  z-index: -1;
}

.site-footer__social a:hover:after {
  opacity: 1;
  transform: scaleY(1);
}

.site-footer__social a + a {
  margin-left: 10px;
}

.footer-widget__link {
  position: relative;
  display: block;
}

.footer-widget__link-list {
  position: relative;
  display: block;
}

.footer-widget__link-list li + li {
  margin-top: 8px;
}

.footer-widget__link-list li a {
  position: relative;
  color: #777777;
  letter-spacing: var(--govity-letter-spacing-two);
  transition: all 500ms ease;
}

.footer-widget__link-list li a:hover {
  color: var(--govity-white);
}

.footer-widget__departments {
  position: relative;
  display: block;
}

.footer-widget__explore {
  position: relative;
  display: block;
}

.footer-widget__gallery {
  position: relative;
  display: block;
  margin-left: -9px;
}

.footer-widget__gallery .footer-widget__title-box {
  margin-bottom: 33px;
}

.footer-widget__gallery-list {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-right: -7.5px;
  margin-left: -7.5px;
}

.footer-widget__gallery-list li {
  position: relative;
  display: inline-block;
  padding-left: 7.5px;
  padding-right: 7.5px;
  margin-bottom: 15px;
}

.footer-widget__gallery-img {
  position: relative;
  display: block;
  overflow: hidden;
  width: 80px;
  z-index: 1;
}

.footer-widget__gallery-img:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: '';
  background-color: rgba(var(--govity-black-rgb), 0.3);
  transition: all 700ms ease;
  transform: translateY(-100%);
  z-index: 1;
}

.footer-widget__gallery-list li:hover .footer-widget__gallery-img:before {
  transform: translateY(0%);
}

.footer-widget__gallery-img img {
  width: 100%;
  transition-delay: 0.1s;
  transition-timing-function: ease-in-out;
  transition-duration: 0.7s;
  transition-property: all;
}

.footer-widget__gallery-list li:hover .footer-widget__gallery-img img {
  transform: scale(1.1) rotate(2deg);
}

.footer-widget__gallery-img a {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  color: var(--govity-white);
  opacity: 0;
  transition: all 700ms ease;
  z-index: 2;
}

.footer-widget__gallery-list li:hover .footer-widget__gallery-img a {
  opacity: 1;
  transition-delay: 0.3s;
}

.footer-widget__gallery-list li .footer-widget__gallery-img a:hover {
  color: var(--govity-base);
}

.site-footer__bottom {
  position: relative;
  display: block;
  padding: 32px 0 34px;
  border-top: 1px solid #2d2d2d;
  background-color: rgba(0, 40, 104, 0);
  box-shadow: 0px -1px 0px 0px rgba(255, 255, 255, 0.1);
}

.site-footer__bottom-inner {
  position: relative;
  display: block;
  text-align: center;
}

.site-footer__bottom-text {
  color: #777777;
}

.site-footer__bottom-text a {
  color: #777777;
  transition: all 500ms ease;
}

.site-footer__bottom-text a:hover {
  color: var(--govity-base);
}

/*--------------------------------------------------------------
# Feature Two
--------------------------------------------------------------*/
.feature-two {
  position: relative;
  display: block;
  counter-reset: count;
  z-index: 5;
}

.feature-two::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 85px;
  background-color: var(--govity-black);
}

.feature-two__single {
  position: relative;
  display: block;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
  padding: 109px 30px 32px;
  margin-bottom: 30px;
}

.feature-two__sub-title {
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.feature-two__title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: var(--govity-letter-spacing-two);
}

.feature-two__title a {
  color: var(--govity-base);
  transition: all 500ms ease;
}

.feature-two__title a:hover {
  color: var(--govity-black);
}

.feature-two__icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  top: 20px;
  right: 40px;
  border-radius: 50%;
  background-color: var(--govity-primary);
  transition: all 500ms ease;
}

.feature-two__single:hover .feature-two__icon {
  background-color: var(--govity-base);
}

.feature-two__icon span {
  position: relative;
  display: inline-block;
  font-size: 24px;
  color: var(--govity-base);
  transition: all 500ms ease;
}

.feature-two__single:hover .feature-two__icon span {
  color: var(--govity-white);
}

.feature-two__count {
  position: absolute;
  top: 33px;
  left: 30px;
}

.feature-two__count:before {
  position: absolute;
  font-size: 30px;
  line-height: 40px;
  font-weight: 400;
  counter-increment: count;
  content: '0' counter(count);
  color: var(--govity-primary);
  letter-spacing: var(--govity-letter-spacing);
}

/*--------------------------------------------------------------
# About Two
--------------------------------------------------------------*/
.about-two {
  position: relative;
  display: block;
  padding: 90px 0 112px;
  z-index: 1;
}

.about-two__shape-3 {
  position: absolute;
  left: -578px;
  top: -670px;
  width: 1100px;
  height: 1100px;
  border-radius: 50%;
  background-color: #f8f8f8;
  z-index: -1;
}

.about-two__left {
  position: relative;
  display: block;
  margin-right: 210px;
}

.about-two__img-box {
  position: relative;
  display: block;
}

.about-two__img {
  position: relative;
  display: block;
  z-index: 1;
}

.about-two__img img {
  width: 100%;
}

.about-two__img-2 {
  position: absolute;
  right: -140px;
  top: 100px;
  z-index: 1;
}

.about-two__img-2 img {
  width: auto;
}

.about-two__shape-1 {
  position: absolute;
  top: -50px;
  right: -50px;
  width: 180px;
  height: 167px;
  border: 5px solid var(--govity-base);
  z-index: -1;
}

.about-two__shape-2 {
  position: absolute;
  left: -117px;
  bottom: -86px;
}

.about-two__shape-2 img {
  width: auto;
}

.about-two__right {
  position: relative;
  display: block;
}

.about-two__right .section-title {
  margin-bottom: 31px;
}

.about-two__text-1 {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  color: var(--govity-base);
  letter-spacing: var(--govity-letter-spacing-two);
}

.about-two__text-2 {
  margin-top: 33px;
  margin-bottom: 22px;
}

.about-two__progress {
  position: relative;
  display: block;
  width: 100%;
}

.about-two__progress-single {
  position: relative;
  display: block;
}

.about-two__progress-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  color: var(--govity-black);
  margin-bottom: 6px;
  letter-spacing: var(--govity-letter-spacing-two);
}

.about-two__progress .bar {
  position: relative;
  width: 100%;
  height: 13px;
  border-radius: 6px;
  background-color: var(--govity-primary);
  margin-bottom: 12px;
}

.about-two__progress .bar-inner {
  position: relative;
  display: block;
  width: 0px;
  height: 13px;
  border-radius: 6px;
  background: var(--govity-base);
  transition: all 1500ms ease;
}

.about-two__progress .count-text {
  position: absolute;
  right: 0px;
  bottom: 21px;
  color: var(--govity-gray);
  line-height: 24px;
  font-size: 14px;
  text-align: center;
  font-weight: 400;
  letter-spacing: var(--govity-letter-spacing-two);
  opacity: 0;
  transition: all 500ms ease;
}

.about-two__progress .bar-inner.counted .count-text {
  opacity: 1;
}

.about-two_progress .bar.marb-0 {
  margin-bottom: 0;
}

.about-two__points {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 32px;
}

.about-two__points li {
  position: relative;
  display: block;
}

.about-two__points-title-box {
  position: relative;
  display: flex;
  align-items: center;
}

.about-two__points-title-box .icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  background-color: var(--govity-base);
  border-radius: 50%;
}

.about-two__points-title-box .icon span {
  position: relative;
  display: inline-block;
  font-size: 10px;
  color: var(--govity-white);
}

.about-two__points-title-box .title {
  position: relative;
  display: block;
  margin-left: 8px;
}

.about-two__points-title-box .title h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
}

.about-two__points-text {
  letter-spacing: var(--govity-letter-spacing-two);
  margin-top: 22px;
}

/*--------------------------------------------------------------
# Feature Three
--------------------------------------------------------------*/
.feature-three {
  position: relative;
  display: block;
  padding-bottom: 90px;
}

.feature-three__shape-1 {
  position: absolute;
  bottom: -606px;
  right: 0;
  opacity: 0.05;
  z-index: -1;
}

.feature-three__shape-1 img {
  width: auto;
}

.feature-three__single {
  position: relative;
  display: block;
  margin-bottom: 30px;
}

.feature-three__single .row {
  --bs-gutter-x: 0;
}

.feature-three__left {
  position: relative;
  display: block;
  background-color: var(--govity-black);
  padding: 41px 50px 40px;
  overflow: hidden;
  z-index: 1;
}

.feature-three__left-bg-icon {
  position: absolute;
  top: 50%;
  right: -90px;
  transform: translateY(-50%);
  z-index: -1;
}

.feature-three__left-bg-icon span {
  font-size: 192px;
  color: var(--govity-white);
  opacity: 0.04;
}

.feature-three__title {
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
}

.feature-three__title a {
  color: var(--govity-white);
  transition: all 500ms ease;
}

.feature-three__title a:hover {
  color: var(--govity-base);
}

.feature-three__btn-box {
  position: relative;
  display: block;
  margin-top: 40px;
}

.feature-three__btn {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #777777;
  letter-spacing: var(--govity-letter-spacing-two);
  transition: all 500ms ease;
}

.feature-three__btn:hover {
  color: var(--govity-white);
}

.feature-three__btn i {
  position: relative;
  font-size: 16px;
  margin-left: 10px;
  top: 1px;
}

.feature-three__btn:hover i {
  color: var(--govity-white);
}

.feature-three__single-two .feature-three__btn:hover {
  color: var(--govity-white);
}

.feature-three__single-two .feature-three__btn:hover i {
  color: var(--govity-white);
}

.feature-three__right {
  position: relative;
  display: block;
}

.feature-three__img-box {
  position: relative;
  display: block;
}

.feature-three__img {
  position: relative;
  display: block;
}

.feature-three__img img {
  width: 100%;
}

.feature-three__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 123px;
  height: 123px;
  background-color: var(--govity-white);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(-50%) translateY(-50%);
  transition: all 500ms ease;
}

.feature-three__icon:hover {
  background-color: var(--govity-base);
}

.feature-three__icon span {
  position: relative;
  display: inline-block;
  font-size: 64px;
  color: var(--govity-base);
  transition: all 500ms ease;
}

.feature-three__icon:hover span {
  color: var(--govity-white);
}

.feature-three__single-two .feature-three__left {
  background-color: var(--govity-base);
}

.feature-three__single-two .feature-three__btn {
  color: #ceffe5;
}

.feature-three__single-two .feature-three__title a:hover {
  color: var(--govity-black);
}

/*--------------------------------------------------------------
# Services Two
--------------------------------------------------------------*/
.services-two {
  position: relative;
  display: block;
  z-index: 3;
}

.services-two__inner {
  position: relative;
  display: block;
  max-width: 1370px;
  width: 100%;
  background-color: var(--govity-primary);
  margin: 0 auto;
  padding: 100px 0 70px;
}

.services-two__left {
  position: relative;
  display: block;
}

.services-two__right {
  position: relative;
  display: block;
  margin-top: 82px;
}

.services-two__single {
  position: relative;
  display: flex;
  align-items: center;
  background-color: var(--govity-white);
  padding: 26px 30px 26px;
  padding-right: 20px;
  margin-bottom: 30px;
  justify-content: space-between;
  overflow: hidden;
  transition: all 0.5s linear;
  z-index: 1;
}

.services-two__single::before {
  transition-duration: 800ms;
  position: absolute;
  width: 200%;
  height: 200%;
  content: '';
  top: 110%;
  left: 50%;
  background-color: var(--govity-base);
  transform: translateX(-50%);
  border-radius: 50%;
  z-index: -1;
}

.services-two__single:hover:before {
  top: -40%;
}

.services-two__title {
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: var(--govity-letter-spacing-two);
}

.services-two__title a {
  color: var(--govity-black);
  transition: all 500ms ease;
}

.services-two__single:hover .services-two__title a {
  color: var(--govity-white);
}

.services-two__icon {
  position: relative;
  display: inline-block;
  top: 4px;
  margin-left: 16px;
}

.services-two__icon span {
  position: relative;
  display: inline-block;
  font-size: 24px;
  color: var(--govity-base);
  transition: all 500ms ease;
}

.services-two__single:hover .services-two__icon span {
  color: var(--govity-white);
}

.services-two__shape-1 {
  position: absolute;
  right: 20px;
  top: 100px;
}

.services-two__shape-1 img {
  width: auto;
}

/*--------------------------------------------------------------
# Counter Two
--------------------------------------------------------------*/
.counter-two {
  position: relative;
  display: block;
  background-color: var(--govity-black);
  padding: 220px 0 105px;
  margin-top: -100px;
  z-index: 1;
}

.counter-two__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.2;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
}

.counter-two .section-title__tagline {
  color: var(--govity-white);
}

.counter-two .section-title__title {
  color: var(--govity-white);
}

.counter-two__inner {
  position: relative;
  display: block;
  border-top: 8px solid rgba(var(--govity-white-rgb), 0.2);
  padding-top: 40px;
}

.counter-two__list {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.counter-two__list li {
  position: relative;
  display: flex;
  align-items: center;
}

.counter-two__count {
  position: relative;
  display: block;
  margin-right: 26px;
}

.counter-two__count h3 {
  font-size: 60px;
  font-weight: 700;
  line-height: 60px !important;
  font-family: var(--govity-font) !important;
  letter-spacing: var(--govity-letter-spacing);
  color: var(--govity-base);
}

.counter-two__count > span {
  font-size: 60px;
  font-weight: 700;
  line-height: 60px !important;
  font-family: var(--govity-font) !important;
  letter-spacing: var(--govity-letter-spacing);
  color: var(--govity-base);
  top: 14px;
  position: relative;
  left: -5px;
}

.counter-two__text {
  font-size: 20px;
  line-height: 24px;
  color: var(--govity-white);
  letter-spacing: var(--govity-letter-spacing-two);
}

/*--------------------------------------------------------------
# Portfolio Two
--------------------------------------------------------------*/
.portfolio-two {
  position: relative;
  display: block;
  padding: 120px 0 0;
  z-index: 1;
}

.portfolio-two__bg-box {
  position: absolute;
  top: 0;
  right: 0;
  width: 870px;
  height: 730px;
  background-color: var(--govity-base);
  overflow: hidden;
  z-index: -1;
  display: none;
}

@media (min-width: 1200px) {
  .portfolio-two__bg-box {
    display: block;
  }
}

.portfolio-two__shape-1 {
  position: absolute;
  bottom: -10px;
  right: 0;
  mix-blend-mode: multiply;
  opacity: 0.1;
}

.portfolio-two__shape-1 img {
  width: auto;
}

.portfolio-two__top {
  position: relative;
  display: block;
}

.portfolio-two__left {
  position: relative;
  display: block;
  margin-right: 97px;
}

.portfolio-two__left .section-title {
  margin-bottom: 29px;
}

.portfolio-two__text {
  letter-spacing: var(--govity-letter-spacing-two);
}

.portfolio-two__right {
  position: relative;
  display: block;
  background-color: var(--govity-base);
  padding: 30px;
  margin-top: 30px;
}

@media (min-width: 1200px) {
  .portfolio-two__right {
    margin-left: 160px;
    padding: 0;
    margin-top: 0;
    background-color: transparent;
  }
}

.portfolio-two__points-box {
  position: relative;
  display: block;
}

.portfolio-two__points-top {
  position: relative;
  display: flex;
  align-items: center;
}

.portfolio-two__points-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  background-color: var(--govity-black);
  border-radius: 50%;
  transition: all 500ms ease;
}

.portfolio-two__points-icon:hover {
  background-color: var(--govity-white);
}

.portfolio-two__points-icon:hover span {
  color: var(--govity-black);
}

.portfolio-two__points-icon span {
  position: relative;
  display: inline-block;
  font-size: 24px;
  color: var(--govity-white);
  transition: all 500ms ease;
}

.portfolio-two__points-text {
  margin-left: 20px;
  color: var(--govity-white);
}

.portfolio-two__points-text p {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: var(--govity-letter-spacing);
}

.portfolio-two__points-text p a {
  color: inherit;
  transition: all 500ms ease;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 2px no-repeat;
  transition: all 500ms ease;
}

.portfolio-two__points-text p a:hover {
  background-size: 100% 2px;
}

.portfolio-two__points-list {
  position: relative;
  display: block;
  margin-top: 23px;
}

.portfolio-two__points-list li {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.portfolio-two__points-list li + li {
  border-top: 1px solid rgba(var(--govity-white-rgb), 0.2);
  padding-top: 32px;
  margin-top: 30px;
}

.portfolio-two__points-list li .content {
  position: relative;
  display: block;
}

.portfolio-two__points-list li .content p {
  letter-spacing: var(--govity-letter-spacing-two);
  color: #ceffe5;
  line-height: 26px;
  margin-bottom: 1px;
}

.portfolio-two__points-list li .content h3 {
  font-size: 26px;
  font-weight: 500;
  line-height: 26px;
  color: var(--govity-white);
}

.portfolio-two__points-list li .content h3 a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95%/0px 2px no-repeat;
  transition: all 500ms ease;
}

.portfolio-two__points-list li .content h3 a:hover {
  background-size: 100% 2px;
}

.portfolio-two__points-list li .icon {
  position: relative;
  display: inline-block;
  top: 13px;
}

.portfolio-two__points-list li .icon span {
  position: relative;
  display: inline-block;
  font-size: 23px;
  color: var(--govity-white);
}

.portfolio-two__bottom {
  position: relative;
  display: block;
  padding-top: 45px;
}

.portfolio-two__bottom .container {
  max-width: 1600px;
}

.portfolio-two__bottom .masonary-layout {
  width: calc(100% + 10px);
  margin-left: -5px;
  margin-right: -5px;
}

.portfolio-two__single {
  position: relative;
  display: block;
  width: 100%;
}

.portfolio-two__single--all {
  min-height: 290px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.masonary-layout [class*='col-s-'] {
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 10px;
}

.masonary-layout .col-s-xl {
  width: 100%;
}

@media (min-width: 768px) {
  .masonary-layout .col-s-xl {
    width: 50%;
  }
}

@media (min-width: 992px) {
  .masonary-layout .col-s-xl {
    width: 25%;
  }
}

.masonary-layout .col-s-md {
  width: 100%;
}

@media (min-width: 768px) {
  .masonary-layout .col-s-md {
    width: 50%;
  }
}

@media (min-width: 992px) {
  .masonary-layout .col-s-md {
    width: 25%;
  }
}

.masonary-layout .col-s-sm {
  width: 100%;
}

@media (min-width: 768px) {
  .masonary-layout .col-s-sm {
    width: 50%;
  }
}

@media (min-width: 992px) {
  .masonary-layout .col-s-sm {
    width: 25%;
  }
}

.portfolio-two__img-box {
  position: relative;
  display: block;
  overflow: hidden;
  z-index: 1;
}

.portfolio-two__img {
  position: relative;
  display: block;
  overflow: hidden;
  z-index: 1;
}

.portfolio-two__img:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(var(--govity-black-rgb), 0.4);
  visibility: hidden;
  transform-origin: top center;
  transform: scaleY(0) translateZ(100px);
  transition:
    opacity 500ms ease,
    visibility 500ms ease,
    transform 700ms ease;
}

.portfolio-two__single:hover .portfolio-two__img:before {
  visibility: visible;
  transform: scaleY(1) translateZ(0px);
}

.portfolio-two__img img {
  width: 100%;
}

.portfolio-two__content {
  position: absolute;
  bottom: 30px;
  left: 0;
  transform: translateY(66%);
  transition:
    opacity 500ms ease,
    visibility 500ms ease,
    transform 500ms ease;
  visibility: hidden;
  opacity: 0;
  z-index: 2;
}

.portfolio-two__single:hover .portfolio-two__content {
  visibility: visible;
  transform: translateY(0%);
  transition-delay: 500ms;
  opacity: 1;
}

.portfolio-two__sub-title {
  position: relative;
  display: inline;
  font-size: 12px;
  color: var(--govity-white);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 12px;
  background-color: var(--govity-base);
  padding: 7px 30px 7px;
}

.portfolio-two__title {
  position: relative;
  display: block;
  background-color: var(--govity-white);
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
  padding: 18px 30px 18px;
  padding-right: 60px;
}

.portfolio-two__title a {
  color: var(--govity-black);
  transition: all 500ms ease;
}

.portfolio-two__title a:hover {
  color: var(--govity-base);
}

.portfolio-two__all-portfolio {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.portfolio-two__all-portfolio-text {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 24px;
}

.portfolio-two__all-portfolio-icon {
  margin-left: 20px;
}

.portfolio-two__all-portfolio-icon a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 124px;
  width: 124px;
  background-color: var(--govity-primary);
  border-radius: 50%;
  font-size: 45px;
  color: var(--govity-gray);
  transition: all 500ms ease;
}

.portfolio-two__all-portfolio-icon a:hover {
  background-color: var(--govity-base);
  color: #fff;
}

/*--------------------------------------------------------------
# Department Two
--------------------------------------------------------------*/
.department-two {
  position: relative;
  display: block;
  background-color: var(--govity-primary);
  overflow: hidden;
  padding: 120px 0 112px;
  z-index: 1;
}

.department-two__bg-one {
  position: absolute;
  top: -100px;
  left: 0;
  right: 0;
  height: 414px;
  opacity: 0.15;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  mix-blend-mode: luminosity;
  z-index: -1;
}

.department-two__bg-two {
  position: absolute;
  bottom: -6px;
  left: 0;
  right: 0;
  height: 485px;
  opacity: 0.4;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  mix-blend-mode: luminosity;
  z-index: -1;
}

.department-two__single {
  position: relative;
  display: block;
  margin-bottom: 60px;
}

.department-two__img-box {
  position: relative;
  display: block;
}

.department-two__img {
  position: relative;
  display: block;
  overflow: hidden;
  z-index: 1;
}

.department-two__img:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: '';
  background-color: rgba(var(--govity-black-rgb), 0.4);
  transform: perspective(400px) rotateX(90deg) scaleY(0.5);
  transform-origin: center;
  transition-delay: 0.1s;
  transition-timing-function: ease-in-out;
  transition-duration: 0.5s;
  transition-property: all;
  z-index: 1;
}

.department-two__single:hover .department-two__img:before {
  transform: perspective(400px) rotateX(0deg) scaleY(1);
}

.department-two__img img {
  width: 100%;
  transform: scale(1);
  transition: all 500ms ease;
}

.department-two__single:hover .department-two__img img {
  transform: scale(1.05);
}

.department-two__content {
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 50px;
  background-color: var(--govity-white);
  padding: 15px 30px 15px;
  background-position: bottom left;
  transform: scale(1, 1);
  transform-origin: bottom center;
  transition: transform 500ms ease;
  z-index: 2;
}

.department-two__single:hover .department-two__content {
  transform: scale(0, 0);
  transition-delay: 300ms;
}

.department-two__title {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}

.department-two__title a {
  color: var(--govity-black);
}

.department-two__icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -23px;
  right: 20px;
  width: 46px;
  height: 46px;
  background-color: var(--govity-base);
  border-radius: 50%;
}

.department-two__icon span {
  position: relative;
  display: inline-block;
  font-size: 18px;
  color: var(--govity-white);
}

.department-two__content-two {
  position: absolute;
  bottom: -42px;
  left: 10px;
  right: 10px;
  background-color: var(--govity-white);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
  padding: 15px 30px 10px;
  background-position: bottom left;
  transform: scale(1, 0);
  transform-origin: bottom center;
  transition: transform 500ms ease;
  z-index: 2;
}

.department-two__single:hover .department-two__content-two {
  transform: scale(1, 1);
  transition-delay: 500ms;
}

.department-two__title-two {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}

.department-two__title-two a {
  color: var(--govity-black);
  transition: all 500ms ease;
}

.department-two__title-two a:hover {
  color: var(--govity-base);
}

.department-two__text {
  letter-spacing: var(--govity-letter-spacing-two);
  margin-top: 6px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--govity-bdr-color);
}

.department-two__btn-box {
  position: relative;
  display: block;
}

.department-two__btn {
  position: relative;
  display: flex;
  align-items: center;
  letter-spacing: var(--govity-letter-spacing-two);
  font-size: 14px;
  font-weight: 500;
  transition: all 500ms ease;
}

.department-two__btn:hover {
  color: var(--govity-base);
}

.department-two__btn i {
  font-size: 16px;
  margin-left: 10px;
  position: relative;
  top: 1px;
  transition: all 500ms ease;
}

.department-two__btn:hover i {
  color: var(--govity-base);
}

.department-two__icon-two {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -23px;
  right: 20px;
  width: 46px;
  height: 46px;
  background-color: var(--govity-base);
  border-radius: 50%;
}

.department-two__icon-two span {
  position: relative;
  display: inline-block;
  font-size: 18px;
  color: var(--govity-white);
}

.department-two__bottom-text {
  position: relative;
  display: block;
  text-align: center;
  padding-top: 23px;
  border-top: 1px solid var(--govity-bdr-color);
}

.department-two__bottom-text p {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: var(--govity-letter-spacing-two);
}

.department-two__bottom-text p a {
  color: var(--govity-black);
  transition: all 500ms ease;
}

.department-two__bottom-text p a:hover {
  color: var(--govity-base);
}

/*--------------------------------------------------------------
# Event Two
--------------------------------------------------------------*/
.event-two {
  position: relative;
  display: block;
}

.event-two__img {
  position: absolute;
  bottom: 0;
  left: 0;
  mix-blend-mode: luminosity;
  opacity: 0.1;
  z-index: 1;
}

.event-two__img img {
  width: auto;
}

.event-two__left {
  position: relative;
  display: block;
  padding-top: 120px;
  padding-bottom: 120px;
  padding-right: 74px;
  background-color: var(--govity-black);
}

.event-two__left-inner {
  position: relative;
  display: block;
  z-index: 2;
}

.event-two__left::before {
  content: '';
  position: absolute;
  top: 0;
  left: -10000px;
  right: -30px;
  bottom: 0;
  background-color: var(--govity-black);
}

.event-two__left .section-title {
  margin-bottom: 30px;
}

.event-two__left .section-title__title {
  color: var(--govity-white);
}

.event-two__text-1 {
  font-size: 22px;
  line-height: 34px;
  letter-spacing: var(--govity-letter-spacing-two);
  color: var(--govity-base);
}

.event-two__text-2 {
  color: #777777;
  margin-top: 30px;
  margin-bottom: 30px;
}

.event-two__points {
  position: relative;
  display: flex;
  align-items: center;
}

.event-two__points li {
  position: relative;
  display: flex;
  align-items: center;
}

.event-two__points li + li {
  margin-left: 37px;
}

.event-two__points li .icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  background-color: var(--govity-base);
  border-radius: 50%;
}

.event-two__points li .icon span {
  position: relative;
  display: inline-block;
  font-size: 10px;
  color: var(--govity-black);
}

.event-two__points li .text {
  position: relative;
  display: block;
  margin-left: 10px;
}

.event-two__points li .text p {
  font-weight: 500;
  color: var(--govity-white);
}

.event-two__btn-box {
  position: relative;
  display: block;
  margin-top: 51px;
}

.event-two__btn:hover {
  color: var(--govity-base);
}

.event-two__btn::before {
  background-color: var(--govity-white);
}

.event-two__right {
  position: relative;
  display: block;
  padding-left: 120px;
  padding-top: 120px;
  padding-bottom: 120px;
}

.event-two__list {
  position: relative;
  display: block;
}

.event-two__list li {
  position: relative;
  display: block;
}

.event-two__list > li + li {
  margin-top: 30px;
}

.event-two__img-box {
  position: relative;
  display: block;
}

.event-two__img-one {
  position: relative;
  display: block;
}

.event-two__img img {
  width: 100%;
}

.event-two__tag {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: var(--govity-base);
  padding: 8px 20px 8px;
}

.event-two__tag p {
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  color: var(--govity-white);
}

.event-two__content {
  position: absolute;
  bottom: 34px;
  left: 50px;
}

.event-two__meta {
  position: relative;
  display: flex;
  align-items: center;
}

.event-two__meta > li {
  position: relative;
  display: flex;
  align-items: center;
}

.event-two__meta > li + li {
  margin-left: 8px;
}

.event-two__meta li .icon {
  position: relative;
  display: inline-block;
}

.event-two__meta li .icon span {
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: var(--govity-base);
}

.event-two__meta li .text {
  position: relative;
  margin-left: 5px;
}

.event-two__meta li .text p {
  font-size: 14px;
  color: var(--govity-white);
}

.event-two__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  margin-top: 7px;
}

.event-two__title a {
  color: var(--govity-white);
  transition: all 500ms ease;
}

.event-two__title a:hover {
  color: var(--govity-base);
}

.event-two__carousel.owl-carousel .owl-dots {
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  right: -68px;
  bottom: 34%;
}

.event-two__carousel.owl-carousel .owl-dots::before {
  content: '';
  position: absolute;
  width: 4px;
  height: 270px;
  top: -47px;
  right: 10px;
  background-image: linear-gradient(90deg, rgb(255, 255, 255) 1%, rgb(12, 165, 84) 50%, rgb(255, 255, 255) 100%);
}

.event-two__carousel.owl-carousel .owl-dots .owl-dot {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 1px solid var(--govity-bdr-color);
  background-color: var(--govity-white);
  margin: 10px 0px;
  padding: 0px;
  transition: all 500ms ease;
}

.event-two__carousel.owl-carousel .owl-dot.active {
  border: 2px solid var(--govity-base);
}

.event-two__carousel.owl-carousel .owl-dot:focus {
  outline: none;
}

.event-two__carousel.owl-carousel .owl-dots .owl-dot span {
  display: none;
}

/*--------------------------------------------------------------
# Testimonial Two
--------------------------------------------------------------*/
.testimonial-two {
  position: relative;
  display: block;
  background-color: var(--govity-primary);
  padding: 120px 0 120px;
  z-index: 1;
}

.testimonial-two__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  mix-blend-mode: luminosity;
  z-index: -1;
}

.testimonial-two .container {
  max-width: 1326px;
}

.testimonial-two .section-title {
  margin-bottom: 54px;
}

.testimonial-two__bottom {
  position: relative;
  display: block;
}

.testimonial-two__carousel {
  position: relative;
  display: block;
}

.testimonial-two__single {
  position: relative;
  display: block;
  text-align: center;
  padding: 0 56px 45px;
  margin-left: -29px;
  margin-right: -29px;
  z-index: 1;
}

.testimonial-two__single::before {
  content: '';
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
  background-repeat: no-repeat;
  background-position: bottom left;
  transform: scale(1, 0);
  transform-origin: bottom center;
  transition: transform 500ms ease;
  z-index: -1;
}

.testimonial-two__single:hover::before {
  transform: scale(1, 1);
}

.testimonial-two__img {
  position: relative;
  display: block;
  width: 118px;
  margin: 0 auto;
}

.testimonial-two__img::before {
  content: '';
  position: absolute;
  top: -6px;
  left: -6px;
  right: -6px;
  bottom: -6px;
  border: 6px solid var(--govity-base);
  border-radius: 50%;
}

.testimonial-two__img img {
  width: 100%;
  border-radius: 50%;
  border: 6px solid var(--govity-white);
}

.testimonial-two__rating {
  position: relative;
  display: flex;
  background-color: var(--govity-base);
  align-items: center;
  justify-content: center;
  max-width: 150px;
  margin: 0 auto;
  padding: 7px 20px 7px;
  margin-top: -10px;
}

.testimonial-two__rating span {
  position: relative;
  display: inline-block;
  font-size: 10px;
  color: var(--govity-white);
}

.testimonial-two__rating span + span {
  margin-left: 4px;
}

.testimonial-two__text {
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 17px;
  margin-top: 22px;
}

.testimonial-two__client-name {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
}

.testimonial-two__client-sub-title {
  font-size: 14px;
  letter-spacing: var(--govity-letter-spacing-two);
  line-height: 20px;
}

.testimonial-two__carousel.owl-carousel .owl-stage-outer {
  overflow: visible;
}

.testimonial-two__carousel.owl-carousel .owl-item {
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 500ms ease,
    visibility 500ms ease;
}

.testimonial-two__carousel.owl-carousel .owl-item.active {
  opacity: 1;
  visibility: visible;
}

/*--------------------------------------------------------------
# News Two
--------------------------------------------------------------*/
.news-two {
  position: relative;
  display: block;
  padding: 120px 0 148px;
  z-index: 1;
}

.news-two__single {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
}

.news-two__img-box {
  position: relative;
  display: block;
}

.news-two__img {
  position: relative;
  display: block;
  width: 200px;
  overflow: hidden;
  z-index: 1;
}

.news-two__img:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  background-color: rgba(var(--govity-black-rgb), 0.3);
  opacity: 1;
  transition: 0.5s;
  transform: perspective(400px) rotateY(-90deg) scale(0.2);
  transform-origin: top;
  z-index: 1;
}

.news-two__single:hover .news-two__img:before {
  opacity: 1;
  transform: perspective(400px) rotateY(0deg) scale(1);
}

.news-two__img img {
  width: 100%;
  transition-delay: 0.1s;
  transition-timing-function: ease-in-out;
  transition-duration: 0.7s;
  transition-property: all;
}

.news-two__single:hover .news-two__img img {
  transform: scale(1.1) rotate(2deg);
}

.news-two__date {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: var(--govity-base);
  padding: 12px 15px 12px;
  z-index: 2;
}

.news-two__date::before {
  content: '';
  position: absolute;
  bottom: -6px;
  left: 0;
  right: 0;
  height: 6px;
  background-color: rgba(var(--govity-base-rgb), 0.3);
}

.news-two__date p {
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  color: var(--govity-white);
  text-transform: uppercase;
  text-align: center;
}

.news-two__content {
  position: relative;
  display: block;
  padding: 30px 40px 13px;
  max-width: 370px;
  width: 100%;
  background-color: rgb(255, 255, 255);
}

.news-two__user-and-meta {
  position: relative;
  display: flex;
  align-items: center;
}

.news-two__user {
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 20px;
  margin-right: 20px;
  border-right: 1px solid var(--govity-bdr-color);
}

.news-two__user-img {
  position: relative;
  display: block;
  z-index: 1;
}

.news-two__user-img::before {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
  z-index: -1;
}

.news-two__user-img img {
  width: auto;
  border-radius: 50%;
}

.news-two__user-text {
  position: relative;
  display: block;
  margin-left: 10px;
}

.news-two__user-text p {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: var(--govity-letter-spacing-two);
}

.news-two__meta {
  position: relative;
  display: flex;
  align-items: center;
}

.news-two__meta .icon {
  position: relative;
  display: inline-block;
  margin-right: 4px;
}

.news-two__meta .icon span {
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: var(--govity-base);
}

.news-two__meta .text {
  position: relative;
  display: block;
}

.news-two__meta .text p {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: var(--govity-letter-spacing-two);
}

.news-two__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  margin-top: 13px;
  margin-bottom: 13px;
}

.news-two__title a {
  color: var(--govity-black);
  transition: all 500ms ease;
}

.news-two__title a:hover {
  color: var(--govity-base);
}

.news-two__text {
  letter-spacing: var(--govity-letter-spacing-two);
}

.news-two__btn {
  position: relative;
  display: block;
  border-top: 1px solid var(--govity-bdr-color);
  padding-top: 12px;
  margin-top: 19px;
}

.news-two__btn a {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: var(--govity-letter-spacing-two);
  line-height: 24px;
  color: var(--govity-gray);
  transition: all 500ms ease;
}

.news-two__btn a:hover {
  color: var(--govity-base);
}

.news-two__btn a i {
  position: relative;
  top: 1px;
  left: 9px;
}

/*--------------------------------------------------------------
# Social One
--------------------------------------------------------------*/
.social-one {
  position: relative;
  display: block;
  background-color: var(--govity-base);
  padding: 98px 0 95px;
  z-index: 1;
}

.social-one__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.1;
  mix-blend-mode: multiply;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
}

.social-one__icon {
  position: absolute;
  top: -58px;
  left: 50%;
  width: 117px;
  height: 117px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(-50%);
  border-radius: 50%;
  background-color: rgb(21, 21, 21);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
  transition: all 500ms ease;
}

.social-one__icon:hover {
  background-color: var(--govity-white);
}

.social-one__icon span {
  position: relative;
  display: inline-block;
  font-size: 49px;
  color: var(--govity-white);
  transition: all 500ms ease;
}

.social-one__icon:hover span {
  color: var(--govity-black);
}

.social-one__inner {
  position: relative;
  display: block;
  text-align: center;
}

.social-one__text {
  font-size: 26px;
  font-weight: 400;
  line-height: 40px;
  color: var(--govity-white);
  letter-spacing: var(--govity-letter-spacing-two);
}

.social-one__text span {
  color: #ceffe5;
}

.social-one__text a {
  color: #ceffe5;
  transition: all 500ms ease;
}

.social-one__text a:hover {
  color: var(--govity-black);
}

.social-one__text-two {
  font-size: 18px;
  color: #ceffe5;
  letter-spacing: var(--govity-letter-spacing);
  margin-top: 37px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-one__text-two a {
  font-weight: 700;
  color: var(--govity-white);
  margin-right: 23px;
  transition: all 500ms ease;
}

.social-one__text-two a:hover {
  color: var(--govity-black);
}

/*--------------------------------------------------------------
# Feature Four
--------------------------------------------------------------*/
.feature-four {
  position: relative;
  display: block;
  padding: 120px 0 90px;
  z-index: 1;
}

.call-to-action {
  position: relative;
  display: block;
  padding: 60px 0 45px;
  z-index: 1;
}

.feature-four__single {
  position: relative;
  display: block;
  text-align: center;
  padding-bottom: 42px;
  margin-bottom: 30px;
  z-index: 1;
}

.feature-four__single::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 78px;
  border: 15px solid var(--govity-primary);
  z-index: -1;
}

.feature-four__img-box {
  position: relative;
  display: block;
  width: 180px;
  border-radius: 50%;
  margin: 0 auto;
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
}

.feature-four__img {
  position: relative;
  display: block;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
  z-index: 1;
}

.feature-four__img:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: '';
  border-radius: 50%;
  background-color: rgba(var(--govity-black-rgb), 0.5);
  transform: scale(0.5);
  opacity: 0;
  transition: all 0.4s linear;
  z-index: 1;
}

.feature-four__single:hover .feature-four__img:before {
  transform: scale(1);
  opacity: 1;
}

.feature-four__img img {
  width: 100%;
  border-radius: 50%;
  transition: all 500ms ease;
}

.feature-four__single:hover .feature-four__img img {
  transform: scale(1.08);
}

.feature-four__icon {
  position: absolute;
  bottom: -32px;
  left: 50%;
  width: 65px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--govity-base);
  transform: translateX(-50%);
  transition: all 500ms ease;
  z-index: 2;
}

.feature-four__single:hover .feature-four__icon {
  background-color: var(--govity-black);
}

.feature-four__icon span {
  position: relative;
  display: inline-block;
  font-size: 32px;
  color: var(--govity-white);
  transition: all 500ms linear;
  transition-delay: 0.1s;
}

.feature-four__single:hover .feature-four__icon span {
  transform: scale(0.9);
}

.feature-four__title {
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  margin-top: 46px;
}

.feature-four__title a {
  color: var(--govity-black);
  transition: all 500ms ease;
}

.feature-four__title a:hover {
  color: var(--govity-base);
}

/*--------------------------------------------------------------
# About Three
--------------------------------------------------------------*/
.about-three {
  position: relative;
  display: block;
  background-image: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(243, 243, 243) 100%);
  padding-bottom: 60px;
  z-index: 1;
}

.about-three__shape-5 {
  position: absolute;
  right: 93px;
  bottom: 0;
  opacity: 0.05;
  z-index: -1;
}

.about-three__shape-5 img {
  width: auto;
}

.about-three__left {
  position: relative;
  display: block;
  margin-right: 33px;
  margin-left: 264px;
}

.about-three__img-1 {
  position: absolute;
  top: 0;
  right: -180px;
  left: -630px;
  bottom: 0;
  mix-blend-mode: darken;
}

.about-three__img-1 img {
  width: auto;
}

.about-three__services-box {
  position: relative;
  display: block;
  background: var(--govity-base);
  padding: 50px 60px 60px;
  overflow: hidden;
}

.about-three__services-title {
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  color: var(--govity-white);
}

.about-three__services-list {
  position: relative;
  display: block;
  margin-top: 38px;
  margin-bottom: 31px;
}

.about-three__services-list li + li {
  margin-top: 10px;
}

.about-three__services-list li a {
  position: relative;
  display: flex;
  align-items: center;
  color: var(--govity-white);
  font-size: 18px;
  letter-spacing: var(--govity-letter-spacing-two);
  transition: all 500ms ease;
}

.about-three__services-list li a:hover {
  color: var(--govity-black);
}

.about-three__services-list li a i {
  position: relative;
  margin-right: 7px;
  color: var(--govity-white);
  transition: all 500ms ease;
}

.about-three__services-list li a:hover i {
  color: var(--govity-black);
}

.bout-three__btn-box {
  position: relative;
  display: block;
}

.about-three__btn {
  background-color: var(--govity-black);
}

.about-three__btn:hover {
  color: var(--govity-base);
}

.about-three__btn::before {
  background-color: var(--govity-white);
}

.about-three__shape-1 {
  position: absolute;
  left: -490px;
  bottom: 22px;
  z-index: 2;
}

.about-three__shape-1 img {
  width: auto;
}

.about-three__shape-2 {
  position: absolute;
  left: -390px;
  top: 126px;
}

.about-three__shape-2 img {
  width: auto;
}

.about-three__shadow {
  position: absolute;
  bottom: -60px;
  left: -435px;
  height: 200px;
  width: 638px;
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgb(255, 255, 255) 11%, rgba(255, 255, 255, 0) 100%);
}

.about-three__shape-3 {
  position: absolute;
  right: 0;
  bottom: -4px;
  opacity: 0.3;
}

.about-three__shape-3 img {
  width: auto;
}

.about-three__right {
  position: relative;
  display: block;
  margin-left: 60px;
  padding-top: 110px;
}

.about-three__right .section-title {
  margin-bottom: 29px;
}

.about-three__video-box {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.about-three__video-link {
  position: relative;
  display: block;
  z-index: 1;
}

.about-three__video-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 88px;
  height: 84px;
  line-height: 84px;
  text-align: center;
  font-size: 21px;
  color: var(--govity-black);
  background-color: var(--govity-base);
  border-radius: 50%;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  z-index: 1;
}

.about-three__video-icon:hover {
  color: var(--govity-white);
}

.about-three__video-icon::before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  right: 6px;
  bottom: 2px;
  background-color: var(--govity-white);
  border-radius: 50%;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  z-index: -1;
}

.about-three__video-icon:hover::before {
  background-color: var(--govity-black);
}

.about-three__video-link .ripple,
.about-three__video-icon .ripple:before,
.about-three__video-icon .ripple:after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 88px;
  height: 84px;
  transform: translate(-50%, -50%);
  -ms-box-shadow: 0 0 0 0 rgba(var(--govity-base-rgb), 0.6);
  -o-box-shadow: 0 0 0 0 rgba(var(--govity-base-rgb), 0.6);
  box-shadow: 0 0 0 0 rgba(var(--govity-base-rgb), 0.6);
  animation: ripple 3s infinite;
  border-radius: 50%;
}

.about-three__video-icon .ripple:before {
  animation-delay: 0.9s;
  content: '';
  position: absolute;
}

.about-three__video-icon .ripple:after {
  animation-delay: 0.6s;
  content: '';
  position: absolute;
}

.about-three__video-content {
  position: relative;
  display: block;
  margin-left: 20px;
}

.about-three__video-content h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: var(--govity-letter-spacing-two);
}

.about-three__video-content p {
  letter-spacing: var(--govity-letter-spacing-two);
}

.about-three__shape-4 {
  position: absolute;
  left: -93px;
  bottom: 85px;
}

.about-three__shape-4 img {
  width: auto;
}

/*--------------------------------------------------------------
# Department Three
--------------------------------------------------------------*/
.department-three {
  position: relative;
  display: block;
  background-color: var(--govity-black);
  padding: 120px 0 90px;
  overflow: hidden;
  z-index: 1;
}

.department-three__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.05;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  mix-blend-mode: luminosity;
  z-index: -1;
}

.department-three__bg-top {
  position: absolute;
  top: -130px;
  left: 0;
  right: 0;
  height: 414px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  mix-blend-mode: multiply;
  z-index: -1;
}

.department-three .section-title__title {
  color: var(--govity-white);
}

.department-three__single {
  position: relative;
  display: block;
  border: 1px solid #2d2d2d;
  background-color: var(--govity-black);
  padding: 29px 29px 9px;
  margin-bottom: 30px;
  overflow: hidden;
  transition: all 500ms ease;
  z-index: 1;
}

.department-three__single:hover {
  border: 1px solid var(--govity-white);
  transition-delay: 500ms;
}

.department-three__single:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: '';
  background-color: var(--govity-white);
  transform: scale(0.5);
  opacity: 0;
  transition: all 0.4s linear;
  z-index: -1;
}

.department-three__single:hover:before {
  transform: scale(1);
  opacity: 1;
}

.department-three__icon {
  position: relative;
  display: inline-block;
}

.department-three__icon span {
  position: relative;
  display: inline-block;
  font-size: 34px;
  color: var(--govity-base);
  transition: all 500ms linear;
  transition-delay: 0.1s;
}

.department-three__single:hover .department-three__icon span {
  transform: scale(0.9);
}

.department-three__title {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin-top: 7px;
  letter-spacing: var(--govity-letter-spacing-two);
  padding-bottom: 25px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(var(--govity-white-rgb), 0.1);
  transition: all 500ms ease;
}

.department-three__single:hover .department-three__title {
  border-bottom: 1px solid var(--govity-bdr-color);
}

.department-three__title a {
  color: var(--govity-white);
  transition: all 500ms ease;
}

.department-three__single:hover .department-three__title a {
  color: var(--govity-black);
}

.department-three__btn-box {
  position: relative;
  display: block;
}

.department-three__btn {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  letter-spacing: var(--govity-letter-spacing-two);
  color: #777777;
  transition: all 500ms ease;
}

.department-three__single:hover .department-three__btn {
  color: #888888;
}

.department-three__btn i {
  position: relative;
  top: 1px;
  margin-left: 10px;
  transition: all 500ms ease;
}

.department-three__single:hover .department-three__btn i {
  color: #888888;
}

.department-three__single-two {
  position: relative;
  display: block;
  background-color: var(--govity-base);
  padding: 25px 30px 106px;
}

.department-three__title-two {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: var(--govity-letter-spacing-two);
}

.department-three__title-two a {
  color: var(--govity-white);
  transition: all 500ms ease;
}

.department-three__title-two a:hover {
  color: var(--govity-black);
}

.department-three__icon-two {
  position: absolute;
  right: 21px;
  bottom: 15px;
}

.department-three__icon-two a {
  position: relative;
  display: inline-block;
  font-size: 63px;
  color: var(--govity-white);
  transition: all 500ms ease;
}

.department-three__icon-two a:hover {
  color: var(--govity-black);
}

/*--------------------------------------------------------------
# Event Three
--------------------------------------------------------------*/
.event-three {
  position: relative;
  display: block;
  padding: 120px 0 90px;
  z-index: 1;
}

.event-three__single {
  position: relative;
  display: block;
  margin-bottom: 30px;
}

.event-three__img-box {
  position: relative;
  display: block;
}

.event-three__img {
  position: relative;
  display: block;
  overflow: hidden;
  z-index: 1;
}

.event-three__img:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: '';
  background-color: rgba(var(--govity-black-rgb), 0.3);
  transform: perspective(400px) rotateX(90deg) scaleY(0.5);
  transform-origin: center;
  transition-delay: 0.1s;
  transition-timing-function: ease-in-out;
  transition-duration: 0.5s;
  transition-property: all;
  z-index: 1;
}

.event-three__single:hover .event-three__img:before {
  transform: perspective(400px) rotateX(0deg) scaleY(1);
}

.event-three__img img {
  width: 100%;
  transform: scale(1);
  transition: all 500ms ease;
}

.event-three__single:hover .event-three__img img {
  transform: scale(1.05);
}

.event-three__content {
  position: relative;
  display: block;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
  padding: 22px 10px 10px;
  margin-top: -140px;
  margin-left: 15px;
  margin-right: 15px;
  z-index: 2;
}

.event-three__meta-and-title {
  position: relative;
  display: block;
  padding-left: 30px;
  padding-right: 30px;
}

.event-three__meta {
  position: relative;
  display: flex;
  align-items: center;
}

.event-three__meta li {
  position: relative;
  display: flex;
  align-items: center;
}

.event-three__meta li + li {
  margin-left: 10px;
}

.event-three__meta li .icon {
  position: relative;
  display: inline-block;
}

.event-three__meta li .icon span {
  position: relative;
  display: inline-block;
  font-size: 15px;
  color: var(--govity-base);
}

.event-three__meta li .text {
  position: relative;
  display: block;
  margin-left: 4px;
}

.event-three__meta li .text p {
  font-size: 14px;
}

.event-three__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  margin-top: 5px;
  margin-bottom: 23px;
}

.event-three__title a {
  color: var(--govity-black);
  transition: all 500ms ease;
}

.event-three__title a:hover {
  color: var(--govity-base);
}

.event-three__btn-box {
  position: relative;
  display: block;
}

.event-three__btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  color: var(--govity-gray);
  letter-spacing: var(--govity-letter-spacing-two);
  background-color: var(--govity-primary);
  padding: 10px 30px 10px;
  transition: all 500ms ease;
}

.event-three__btn:hover {
  color: var(--govity-base);
}

.event-three__date {
  position: absolute;
  right: 0;
  top: -30px;
  background-color: var(--govity-base);
  padding: 4px 20px 4px;
}

.event-three__date p {
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  color: var(--govity-white);
}

/*--------------------------------------------------------------
# Tesimonial Three
--------------------------------------------------------------*/
.testimonial-three {
  position: relative;
  display: block;
  padding: 120px 0 120px;
  background-color: var(--govity-primary);
  z-index: 1;
}

.testimonial-three__bg {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 485px;
  opacity: 0.08;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  mix-blend-mode: luminosity;
  z-index: -1;
}

.testimonial-three .section-title {
  margin-bottom: 29px;
}

.testimonial-three__left {
  position: relative;
  display: block;
  z-index: 15;
}

.testimonial-three__right {
  position: relative;
  display: block;
}

.testimonial-three__carousel {
  position: relative;
  display: block;
}

.testimonial-three__single {
  position: relative;
  display: block;
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
  border-radius: 30px;
  z-index: 1;
  transform: translateY(0px);
  transition: transform 500ms ease;
}

.testimonial-three__single:hover {
  transform: translateY(-10px);
}

.testimonial-three__single-inner {
  position: relative;
  display: block;
  border-radius: 30px;
  background-color: rgb(255, 255, 255);
  padding: 30px 0px 23px;
  overflow: hidden;
  z-index: 1;
}

.testimonial-three__text {
  font-size: 18px;
  line-height: 34px;
  letter-spacing: var(--govity-letter-spacing-two);
  padding-left: 40px;
  padding-right: 64px;
}

.testimonial-three__client-info {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 29px;
}

.testimonial-three__client-img-box {
  position: relative;
  display: block;
  width: 158px;
  height: 158px;
  border-radius: 50%;
  background-color: var(--govity-base);
  padding: 10px;
}

.testimonial-three__client-img {
  position: relative;
  display: block;
  height: 138px;
  width: 138px;
  border-radius: 50%;
  background-color: var(--govity-white);
}

.testimonial-three__client-img img {
  width: auto;
  border-radius: 50%;
  border: 11px solid var(--govity-white);
}

.testimonial-three__client-content {
  position: relative;
  display: block;
  margin-left: 40px;
}

.testimonial-three__client-rating {
  position: relative;
  display: flex;
  align-items: center;
}

.testimonial-three__client-rating span {
  position: relative;
  display: inline-block;
  font-size: 15px;
  color: var(--govity-base);
}

.testimonial-three__client-rating span + span {
  margin-left: 3px;
}

.testimonial-three__client-name {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  margin-top: 12px;
}

.testimonial-three__client-sub-title {
  font-size: 14px;
  line-height: 21px;
}

.testimonial-three__quote {
  position: absolute;
  right: 40px;
  bottom: 82px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 72px;
  border: 1px solid var(--govity-bdr-color);
  border-radius: 50%;
  transition: all 500ms ease;
}

.testimonial-three__single:hover .testimonial-one__quote {
  border: 1px solid var(--govity-base);
}

.testimonial-three__quote span {
  position: relative;
  display: inline-block;
  font-size: 32px;
  color: #dddddd;
  transition: all 500ms ease;
}

.testimonial-three__single:hover .testimonial-three__quote span {
  color: var(--govity-base);
}

.testimonial-three__shape-1 {
  position: absolute;
  left: -98px;
  right: -98px;
  top: 0;
  opacity: 0.07;
  mix-blend-mode: luminosity;
  z-index: -1;
}

.testimonial-three__shape-1 img {
  width: auto;
}

.testimonial-three__shape-2 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 181px;
  z-index: -1;
}

.testimonial-three__shape-2 img {
  width: auto;
}

.testimonial-three__carousel.owl-carousel .owl-stage-outer {
  overflow: visible;
}

.testimonial-three__carousel.owl-carousel .owl-item {
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 500ms ease,
    visibility 500ms ease;
}

.testimonial-three__carousel.owl-carousel .owl-item.active {
  opacity: 1;
  visibility: visible;
}

/*--------------------------------------------------------------
# Portfolio Three
--------------------------------------------------------------*/
.portfolio-three {
  position: relative;
  display: block;
  padding-top: 120px;
  z-index: 1;
}

.portfolio-three .container {
  max-width: 1600px;
}

.portfolio-three__single {
  position: relative;
  display: block;
  margin-bottom: 30px;
}

.portfolio-three__img-box {
  position: relative;
  display: block;
}

.portfolio-three__img {
  position: relative;
  display: block;
  overflow: hidden;
  z-index: 1;
}

.portfolio-three__img:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  background-color: rgba(var(--govity-base-rgb), 0.9);
  width: 0%;
  transform: translateY(100%);
  border-radius: var(--govity-bdr-radius);
  transition: all 500ms ease;
  z-index: 1;
}

.portfolio-three__single:hover .portfolio-three__img:before {
  transform: translateY(0);
  width: 100%;
}

.portfolio-three__img img {
  width: 100%;
  transition-delay: 0.1s;
  transition-timing-function: ease-in-out;
  transition-duration: 0.7s;
  transition-property: all;
}

.portfolio-three__single:hover .portfolio-three__img img {
  transform: scale(1.1) rotate(2deg);
}

.portfolio-three__content {
  position: absolute;
  left: 40px;
  bottom: 33px;
  z-index: 2;
}

.portfolio-three__sub-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: var(--govity-base);
  transition: all 500ms ease;
}

.portfolio-three__single:hover .portfolio-three__sub-title {
  color: var(--govity-white);
}

.portfolio-three__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
}

.portfolio-three__title a {
  color: var(--govity-white);
  transition: all 500ms ease;
}

.portfolio-three__title a:hover {
  color: var(--govity-black);
}

.portfolio-three__arrow {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 2;
}

.portfolio-three__arrow a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  font-size: 16px;
  color: var(--govity-white);
  border: 2px solid var(--govity-white);
  border-radius: 50%;
  transform: rotate(45deg) scale(0);
  transition: all 600ms ease;
}

.portfolio-three__single:hover .portfolio-three__arrow a {
  transform: rotate(0deg) scale(1);
  transition-delay: 400ms;
}

.portfolio-three__arrow a:hover {
  color: var(--govity-black);
  border: 2px solid var(--govity-black);
}

/*--------------------------------------------------------------
# Brand Two
--------------------------------------------------------------*/
.brand-two {
  padding: 80px 0 100px;
}

/*--------------------------------------------------------------
# Great Place
--------------------------------------------------------------*/
.great-place {
  position: relative;
  display: block;
  background-color: var(--govity-base);
  padding: 110px 0 120px;
  z-index: 1;
}

.great-place__bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 43.55555%;
  opacity: 0.15;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  mix-blend-mode: luminosity;
  z-index: -1;
}

.great-place__left {
  position: relative;
  display: block;
}

.great-place__title {
  font-size: 45px;
  font-weight: 700;
  line-height: 54px;
  color: var(--govity-white);
}

.great-place__content-box {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 51px;
}

.great-place__img-box {
  position: relative;
  display: block;
}

.great-place__img-box::before {
  content: '';
  position: absolute;
  top: 0;
  left: -10px;
  width: 179px;
  height: 178px;
  background-color: rgba(var(--govity-black-rgb), 0.1);
  border-radius: 50%;
  z-index: -1;
}

.great-place__img {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 50%;
  z-index: 1;
}

.great-place__img:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: '';
  border-radius: 50%;
  background-color: rgba(var(--govity-black-rgb), 0.5);
  transform: scale(0.5);
  opacity: 0;
  transition: all 0.4s linear;
  z-index: 1;
}

.great-place__img:hover:before {
  transform: scale(1);
  opacity: 1;
}

.great-place__img img {
  border-radius: 50%;
  transition: all 500ms ease;
}

.great-place__img:hover img {
  transform: scale(1.08);
}

.great-place__text-box {
  position: relative;
  display: block;
  margin-left: 20px;
}

.great-place__text {
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  color: var(--govity-white);
  letter-spacing: var(--govity-letter-spacing-two);
}

.great-place__text-two {
  color: #ceffe5;
  letter-spacing: var(--govity-letter-spacing-two);
  margin-top: 31px;
}

.great-place__right {
  position: relative;
  display: block;
  margin-left: 80px;
}

.great-place__right-inner {
  position: relative;
  display: block;
  background-color: rgb(255, 255, 255);
  box-shadow:
    0px 10px 60px 0px rgba(0, 0, 0, 0.1),
    inset 0px 4px 0px 0px rgba(12, 165, 84, 0.004);
  padding: 44px 40px 40px;
  margin-top: -150px;
  z-index: 1;
}

.great-place__right-inner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background-color: var(--govity-base);
}

.great-place__shape-1 {
  position: absolute;
  top: 145px;
  left: -90px;
  opacity: 0.1;
  z-index: -1;
}

.great-place__shape-1 img {
  width: auto;
  opacity: 0.1;
}

.great-place__shape-2 {
  position: absolute;
  bottom: -120px;
  right: -282px;
  z-index: -1;
}

.great-place__shape-2 img {
  width: auto;
}

.great-place__right .section-title__tagline {
  font-size: 14px;
}

.great-place__right .section-title__title {
  font-size: 24px;
  line-height: 24px;
}

.great-place__right .section-title {
  margin-bottom: 26px;
}

.contact-form-box__input-box {
  position: relative;
  display: block;
  margin-bottom: 10px;
}

.contact-form-box__input-box p {
  font-size: 15px;
  color: #433838;
  opacity: 0.7;
  line-height: 20px;
  margin-bottom: 5px;
}

.contact-form-box__input-box input[type='text'],
.contact-form-box__input-box input[type='email'] {
  height: 54px;
  border: 0;
  width: 100%;
  font-size: 14px;
  color: var(--govity-gray);
  background-color: var(--govity-primary);
  border-radius: var(--govity-bdr-radius);
  padding: 0 20px;
  outline: none;
  text-align: center;
}

.contact-form-box__input-box textarea {
  height: 114px;
  width: 100%;
  border: none;
  color: var(--govity-gray);
  background-color: var(--govity-primary);
  border-radius: var(--govity-bdr-radius);
  padding: 20px 20px 20px;
  font-size: 14px;
  outline: none;
  text-align: center;
}

.contact-form-box__input-box.text-message-box {
  height: 114px;
  margin-bottom: 10px;
}

.contact-form-box__btn-box {
  position: relative;
  display: block;
}

.contact-form-box__btn {
  border: none;
  padding: 13px 0px 11px;
  background-color: var(--govity-black);
  color: var(--govity-white);
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: block;
}

.contact-form-box__btn:hover {
  color: var(--govity-white);
}

.contact-form-box__btn:before {
  background-color: var(--govity-base);
}

/*--------------------------------------------------------------
# Documents Two
--------------------------------------------------------------*/
.documents-two {
  position: relative;
  display: block;
  background-color: var(--govity-black);
  padding: 100px 0 100px;
  z-index: 1;
}

.documents-two__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.03;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
}

.documents-two__inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.documents-two__left {
  position: relative;
  display: block;
  max-width: 293px;
  width: 100%;
}

.documents-two__left p {
  font-size: 35px;
  font-weight: 700;
  line-height: 42px;
  color: var(--govity-white);
  letter-spacing: var(--govity-letter-spacing);
}

.documents-two__right {
  position: relative;
  display: block;
  max-width: 800px;
  width: 100%;
}

.documents-two__points {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.documents-two__points li {
  position: relative;
  display: block;
  flex: 0 0 50%;
  max-width: 50%;
  width: 100%;
}

.documents-two__single {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--govity-white);
  padding: 40px 40px 29px;
  border-right: 1px solid var(--govity-bdr-color);
  transition: all 500ms ease;
}

.documents-two__points li:last-child .documents-two__single {
  border-right: 1px solid transparent;
}

.documents-two__single::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(243, 243, 243) 100%);
  transition: all 500ms ease;
}

.documents-two__points li:last-child .documents-two__single::before {
  display: none;
}

.documents-two__content {
  position: relative;
  display: block;
}

.documents-two__icon {
  position: relative;
  display: inline-block;
}

.documents-two__icon span {
  position: relative;
  display: inline-block;
  font-size: 24px;
  color: var(--govity-base);
}

.documents-two__title {
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: var(--govity-letter-spacing-two);
}

.documents-two__title a {
  color: var(--govity-black);
  transition: all 500ms ease;
}

.documents-two__title a:hover {
  color: var(--govity-base);
}

.documents-two__text {
  margin-top: 1px;
  letter-spacing: var(--govity-letter-spacing-two);
}

.documents-two__icon-two {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  background-color: var(--govity-base);
  border-radius: 50%;
  transition: all 500ms ease;
}

.documents-two__icon-two span {
  position: relative;
  display: inline-block;
  font-size: 24px;
  color: var(--govity-white);
  transition: all 500ms ease;
}

.documents-two__icon-two:hover {
  background-color: var(--govity-black);
}

/*--------------------------------------------------------------
# History One
--------------------------------------------------------------*/
.history-one {
  position: relative;
  display: block;
  padding: 120px 0 0;
}

.history-one__top {
  position: relative;
  display: block;
}

.history-one__bottom {
  position: relative;
  display: block;
}

.history-one__wrap {
  position: relative;
  display: block;
  max-width: 1410px;
  width: 100%;
  margin: 0 auto 0;
  background-color: var(--govity-primary);
  overflow: hidden;
  z-index: 1;
}

.history-one__shape-1 {
  position: absolute;
  bottom: -2px;
  left: -15px;
  mix-blend-mode: luminosity;
  opacity: 0.04;
  z-index: -1;
}

.history-one__shape-1 img {
  width: auto;
}

.history-one__inner {
  position: relative;
  display: block;
  padding: 104px 0 100px;
}

.history-one__thumb-box {
  position: relative;
  display: block;
  padding-left: 40px;
  padding-right: 40px;
}

#history-one__thumb {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: center;
}

#history-one__thumb:before {
  content: '';
  position: absolute;
  top: 17px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: var(--govity-bdr-color);
}

.history-one__date-box {
  position: relative;
  display: block;
}

.history-one__circle-one {
  position: relative;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: var(--govity-primary);
  margin: 0 auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.history-one__circle-two {
  position: relative;
  height: 24px;
  width: 24px;
  background-color: var(--govity-white);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 500ms ease;
}

#history-one__thumb .swiper-slide-thumb-active .history-one__circle-two {
  background-color: var(--govity-base);
}

.history-one__circle-three {
  position: relative;
  display: block;
  height: 12px;
  width: 12px;
  background-color: var(--govity-base);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 500ms ease;
}

#history-one__thumb .swiper-slide-thumb-active .history-one__circle-three {
  background-color: var(--govity-white);
}

.history-one__date {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.history-one__main-content {
  position: relative;
  display: block;
}

.history-one__content {
  position: relative;
  display: block;
  margin-top: 50px;
}

.history-one__content-left {
  position: relative;
  display: block;
  margin-top: 20px;
}

.history-one__content-title {
  font-size: 40px;
  line-height: 50px;
}

.history-one__content-text {
  padding-top: 30px;
  padding-bottom: 29px;
}

.history-one__poins {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.history-one__poins li {
  position: relative;
  display: flex;
  align-items: center;
}

.history-one__poins li + li {
  margin-left: 35px;
}

.history-one__poins li .icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  background-color: var(--govity-base);
  border-radius: 50%;
  font-size: 9px;
  color: var(--govity-white);
}

.history-one__poins li .text {
  margin-left: 10px;
}

.history-one__poins li .text p {
  font-size: 16px;
  font-weight: 500;
  color: var(--govity-black);
}

.history-one__content-right {
  position: relative;
  display: block;
  margin-left: 90px;
}

.history-one__content-img {
  position: relative;
  display: block;
}

.history-one__content-img img {
  width: 100%;
}

.history-one__nav {
  position: absolute;
  top: 17px;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 100;
  height: 0;
  line-height: 0;
}

.history-one__nav .swiper-button-next,
.history-one__nav .swiper-button-prev {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: var(--govity-black);
  background-color: var(--govity-white);
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  margin: 0;
  text-align: center;
  opacity: 1;
  transition: all 500ms ease;
  z-index: 100;
}

.history-one__nav .swiper-button-next:hover,
.history-one__nav .swiper-button-prev:hover {
  color: var(--govity-white);
  background-color: var(--govity-black);
}

.history-one__nav .swiper-button-next {
  margin-left: 10px;
}

.history-one__nav .swiper-button-next i,
.history-one__nav .swiper-button-prev i {
  position: relative;
  display: flex;
  align-items: center;
}

.history-one__nav .swiper-button-next::after,
.history-one__nav .swiper-button-prev::after {
  display: none;
}

/*--------------------------------------------------------------
# News Three
--------------------------------------------------------------*/
.news-three {
  position: relative;
  display: block;
  padding: 120px 0 90px;
  z-index: 1;
}

.news-three .container {
  max-width: 1800px;
}

.news-three__carousel .owl-stage-outer {
  overflow: visible;
}

.news-three__carousel .owl-item {
  opacity: 0;
  visibility: hidden;
  transition: all 500ms ease;
}

.news-three__carousel .owl-item.active {
  opacity: 1;
  visibility: visible;
}

/*--------------------------------------------------------------
# Page Header
--------------------------------------------------------------*/
.page-header {
  position: relative;
  display: block;
  background-color: #000000;
  z-index: 1;
}

.page-header-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.5;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
}

.page-header__inner {
  position: relative;
  display: block;
  text-align: center;
  padding: 149px 0 150px;
  z-index: 15;
}

.page-header__inner h2 {
  font-size: 40px;
  color: var(--govity-white);
  line-height: 50px;
  font-weight: 700;
}

.thm-breadcrumb {
  position: relative;
  display: block;
}

.thm-breadcrumb li {
  position: relative;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: var(--govity-letter-spacing-two);
  color: var(--govity-white);
  transition: all 500ms ease;
}

.thm-breadcrumb li + li {
  margin-left: 3px;
}

.thm-breadcrumb li a {
  position: relative;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: var(--govity-letter-spacing-two);
  color: var(--govity-white);
  transition: all 500ms ease;
}

.thm-breadcrumb li:hover a {
  color: var(--govity-base);
  opacity: 1;
}

.thm-breadcrumb li span {
  font-size: 14px;
}

/*--------------------------------------------------------------
# News Details
--------------------------------------------------------------*/
.news-details {
  position: relative;
  display: block;
  padding: 120px 0 120px;
}

.news-details__left {
  position: relative;
  display: block;
}

.news-details__img-box {
  position: relative;
  display: block;
}

.news-details__img {
  position: relative;
  display: block;
}

.news-details__img img {
  width: 100%;
}

.news-details__date {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: var(--govity-base);
  padding: 12px 15px 12px;
  z-index: 2;
}

.news-details__date::before {
  content: '';
  position: absolute;
  bottom: -6px;
  left: 0;
  right: 0;
  height: 6px;
  background-color: rgba(var(--govity-base-rgb), 0.3);
}

.news-details__date p {
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  color: var(--govity-white);
  text-transform: uppercase;
  text-align: center;
}

.news-details__content {
  position: relative;
  display: block;
  margin-top: 22px;
}

.news-details__meta {
  position: relative;
  display: flex;
  align-items: center;
}

.news-details__meta li {
  position: relative;
  display: flex;
  align-items: center;
}

.news-details__meta li + li {
  margin-left: 10px;
}

.news-details__meta li .icon {
  position: relative;
  display: inline-block;
  margin-right: 4px;
}

.news-details__meta li .icon span {
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: var(--govity-base);
}

.news-details__meta li .text {
  position: relative;
  display: block;
}

.news-details__meta li .text p {
  font-size: 14px;
  letter-spacing: var(--govity-letter-spacing-two);
}

.news-details__title-1 {
  font-size: 34px;
  font-weight: 700;
  line-height: 44px;
  margin-top: 1px;
  margin-bottom: 10px;
}

.news-details__text-1 {
  letter-spacing: var(--govity-letter-spacing-two);
}

.news-details__text-2 {
  letter-spacing: var(--govity-letter-spacing-two);
  margin-top: 30px;
}

.news-details__bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 29px 0 30px;
  margin-top: 50px;
  border-top: 1px solid #ebe8e4;
}

.news-details__bottom p {
  margin: 0;
}

.news-details__tags span {
  color: var(--govity-black);
  font-size: 20px;
  margin-right: 20px;
  font-weight: 700;
  letter-spacing: var(--govity-letter-spacing);
}

.news-details__tags a {
  position: relative;
  color: var(--govity-white);
  font-size: 12px;
  background-color: var(--govity-base);
  display: inline-block;
  padding: 3px 20px 4px;
  font-weight: 700;
  transition: all 500ms ease;
}

.news-details__tags a:hover {
  background-color: var(--govity-black);
  color: var(--govity-white);
}

.news-details__tags a + a {
  margin-left: 7px;
}

.news-details__social-list {
  display: flex;
  align-items: center;
}

.news-details__social-list a {
  position: relative;
  height: 43px;
  width: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--govity-black);
  background-color: var(--govity-primary);
  font-size: 14px;
  border-radius: 50%;
  overflow: hidden;
  transition: all 500ms ease;
  z-index: 1;
}

.news-details__social-list a:hover {
  color: var(--govity-white);
}

.news-details__social-list a:after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: var(--govity-base);
  transition-delay: 0.1s;
  transition-timing-function: ease-in-out;
  transition-duration: 0.4s;
  transition-property: all;
  opacity: 1;
  transform-origin: top;
  transform-style: preserve-3d;
  transform: scaleX(0);
  z-index: -1;
}

.news-details__social-list a:hover:after {
  opacity: 1;
  transform: scalex(1);
}

.news-details__social-list a + a {
  margin-left: 10px;
}

.news-details__pagenation-box {
  position: relative;
  display: block;
  overflow: hidden;
  margin-bottom: 54px;
}

.news-details__pagenation {
  position: relative;
  display: block;
}

.news-details__pagenation li {
  position: relative;
  float: left;
  font-size: 20px;
  color: var(--govity-black);
  font-weight: 700;
  background-color: var(--govity-primary);
  line-height: 30px;
  transition: all 500ms ease;
  max-width: 370px;
  width: 100%;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 53px;
  padding-bottom: 52px;
  letter-spacing: var(--govity-letter-spacing);
}

.news-details__pagenation li + li {
  margin-left: 30px;
}

.news-details__pagenation li:hover {
  background-color: var(--govity-base);
  color: var(--govity-white);
}

/*--------------------------------------------------------------
## Comments
--------------------------------------------------------------*/
.comment-one__title,
.comment-form__title {
  margin: 0;
  font-size: 30px;
  margin-bottom: 52px;
  font-weight: 700;
}

.comment-one__single {
  display: flex;
  border-bottom: 1px solid var(--govity-bdr-color);
  padding-bottom: 60px;
  margin-bottom: 60px;
  position: relative;
  align-items: center;
}

.comment-one__content {
  position: relative;
  margin-left: 45px;
}

.comment-one__content h3 {
  margin: 0;
  font-size: 20px;
  margin-bottom: 25px;
  font-weight: 700;
}

.comment-one__content p {
  letter-spacing: var(--govity-letter-spacing-two);
}

.comment-one__btn {
  padding: 4px 22px;
  position: absolute;
  top: -15px;
  right: 0;
  font-size: 11px;
  font-weight: 700;
  color: var(--govity-white);
  background-color: var(--govity-black);
}

.comment-one__btn:hover {
  color: var(--govity-white);
}

.comment-one__btn:before {
  background-color: var(--govity-base);
}

.comment-one__image {
  position: relative;
  display: block;
  border-radius: 50%;
}

.comment-one__image img {
  border-radius: 50%;
}

.comment-form .comment-form__title {
  margin-top: -7px;
}

.comment-one__form .row {
  --bs-gutter-x: 20px;
}

.comment-form__input-box {
  position: relative;
  display: block;
  margin-bottom: 20px;
}

.comment-form__input-box input[type='text'],
.comment-form__input-box input[type='email'] {
  height: 58px;
  width: 100%;
  border: none;
  background-color: var(--govity-primary);
  padding-left: 30px;
  padding-right: 30px;
  outline: none;
  font-size: 14px;
  color: var(--govity-gray);
  display: block;
  letter-spacing: var(--govity-letter-spacing-two);
}

.comment-form__input-box textarea {
  font-size: 14px;
  color: var(--govity-gray);
  height: 190px;
  width: 100%;
  background-color: var(--govity-primary);
  padding: 20px 30px 30px;
  border: none;
  outline: none;
  margin-bottom: 0px;
  letter-spacing: var(--govity-letter-spacing-two);
}

.comment-form__btn {
  border: none;
}

.comment-form__input-box.text-message-box {
  height: 190px;
}

/*--------------------------------------------------------------
# Sidebar
--------------------------------------------------------------*/
@media (max-width: 991px) {
  .sidebar {
    margin-top: 50px;
  }
}

.sidebar__single + .sidebar__single {
  margin-top: 30px;
}

.sidebar__title {
  margin: 0;
  font-size: 20px;
  margin-bottom: 6px;
  font-weight: 700;
}

.sidebar__search {
  position: relative;
  display: block;
}

.sidebar__search-form {
  position: relative;
}

.sidebar__search-form input[type='search'] {
  display: block;
  border: none;
  outline: none;
  background-color: var(--govity-base);
  color: var(--govity-white);
  font-size: 16px;
  padding-left: 50px;
  height: 74px;
  width: 100%;
  padding-right: 80px;
  letter-spacing: var(--govity-letter-spacing-two);
}

.sidebar__search-form ::-moz-placeholder {
  color: var(--govity-white);
  opacity: 1;
}

.sidebar__search-form ::placeholder {
  color: var(--govity-white);
  opacity: 1;
}

.sidebar__search-form :-ms-input-placeholder {
  color: var(--govity-white);
}

.sidebar__search-form ::-ms-input-placeholder {
  color: var(--govity-white);
}

.sidebar__search-form button[type='submit'] {
  background-color: transparent;
  color: var(--govity-white);
  font-size: 22px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 72px;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  padding: 0;
}

.sidebar__post {
  position: relative;
  display: block;
  padding: 46px 30px 30px;
  background-color: var(--govity-primary);
  overflow: hidden;
  z-index: 1;
}

.sidebar__post .sidebar__title {
  margin-left: 20px;
}

.sidebar__post-list {
  margin: 0;
}

.sidebar__post-list li {
  display: flex;
  align-items: center;
  padding: 17px 20px 17px;
  transition: all 500ms ease;
}

.sidebar__post-list li:hover {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.05);
}

.sidebar__post-list li + li {
  margin-top: 10px;
}

.sidebar__post-image {
  margin-right: 20px;
}

.sidebar__post-image > img {
  width: 70px;
}

.sidebar__post-content {
  position: relative;
  top: -3px;
}

.sidebar__post-content h3 {
  font-size: 18px;
  margin: 0;
  line-height: 26px;
}

.sidebar__post-content-meta {
  line-height: 23px;
  font-size: 12px;
  color: var(--govity-gray);
  letter-spacing: var(--govity-letter-spacing-two);
  transition: all 500ms ease;
}

.sidebar__post-content-meta i {
  color: var(--govity-base);
  font-size: 13px;
  padding-right: 4px;
}

.sidebar__post-content h3 a {
  color: var(--govity-black);
  transition: all 500ms ease;
  display: block;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: var(--govity-letter-spacing-two);
}

.sidebar__category {
  position: relative;
  display: block;
  background-color: var(--govity-primary);
  padding: 46px 30px 33px;
  overflow: hidden;
  z-index: 1;
}

.sidebar__category .sidebar__title {
  padding-left: 20px;
  margin-bottom: 9px;
}

.sidebar__category-list {
  margin: 0;
}

.sidebar__category-list li + li {
  margin-top: 4px;
}

.sidebar__category-list li a {
  color: var(--govity-gray);
  position: relative;
  transition: all 500ms ease;
  display: block;
  background: none;
  padding: 7px 20px 7px;
  letter-spacing: var(--govity-letter-spacing-two);
}

.sidebar__category-list li a:hover {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
  color: var(--govity-black);
}

.sidebar__category-list li.active a {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
  color: var(--govity-black);
}

.sidebar__category-list li a span {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%) scale(0);
  transition: all 500ms ease;
  color: var(--govity-base);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}

.sidebar__category-list li a:hover span {
  color: var(--govity-base);
  transform: translateY(-50%) scale(1);
}

.sidebar__category-list li.active a span {
  transform: translateY(-50%) scale(1);
  color: var(--govity-base);
}

.sidebar__tags {
  position: relative;
  display: block;
  background: var(--govity-primary);
  padding: 45px 45px 50px;
  overflow: hidden;
  z-index: 1;
}

.sidebar__tags .sidebar__title {
  margin-left: 5px;
  margin-bottom: 25px;
}

.sidebar__tags-list {
  margin-top: -10px;
}

.sidebar__tags-list a {
  font-size: 12px;
  color: var(--govity-black);
  transition: all 0.4s ease;
  background: var(--govity-white);
  display: inline-block;
  padding: 4px 20px 4px;
  margin-left: 6px;
  font-weight: 700;
}

.sidebar__tags-list a + a {
  margin-left: 6px;
  margin-top: 10px;
}

.sidebar__tags-list a:hover {
  color: var(--govity-white);
  background: var(--govity-base);
}

.sidebar__comments {
  position: relative;
  display: block;
  background-color: var(--govity-primary);
  padding: 46px 50px 47px;
  overflow: hidden;
  z-index: 1;
}

.sidebar__comments .sidebar__title {
  margin-bottom: 22px;
}

.sidebar__comments-list {
  position: relative;
  display: block;
}

.sidebar__comments-list li {
  position: relative;
  display: block;
  padding-left: 65px;
}

.sidebar__comments-list li + li {
  margin-top: 28px;
}

.sidebar__comments-icon {
  height: 45px;
  width: 45px;
  background-color: var(--govity-white);
  border-radius: 50%;
  font-size: 15px;
  color: var(--govity-black);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.4s ease;
}

.sidebar__comments-list li:hover .sidebar__comments-icon {
  background-color: var(--govity-base);
  color: var(--govity-white);
}

.sidebar__comments-text-box p {
  margin: 0;
  letter-spacing: var(--govity-letter-spacing-two);
  line-height: 24px;
}

.sidebar__comments-text-box p span {
  color: var(--govity-black);
}

/*--------------------------------------------------------------
# News Sidebar
--------------------------------------------------------------*/
.news-sidebar {
  position: relative;
  display: block;
  padding: 120px 0 120px;
}

.news-sidebar__left {
  position: relative;
  display: block;
}

.news-sidebar__content {
  position: relative;
  display: block;
}

.news-sidebar__single {
  position: relative;
  display: block;
}

.news-sidebar__single + .news-sidebar__single {
  margin-top: 20px;
}

.news-sidebar__img {
  position: relative;
  display: block;
}

.news-sidebar__img img {
  width: 100%;
}

.news-sidebar__date {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: var(--govity-base);
  padding: 12px 15px 12px;
  z-index: 2;
}

.news-sidebar__date::before {
  content: '';
  position: absolute;
  bottom: -6px;
  left: 0;
  right: 0;
  height: 6px;
  background-color: rgba(var(--govity-base-rgb), 0.3);
}

.news-sidebar__date p {
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  color: var(--govity-white);
  text-transform: uppercase;
  text-align: center;
}

.news-sidebar__content-box {
  position: relative;
  display: block;
  margin-top: 22px;
}

.news-sidebar__meta {
  position: relative;
  display: flex;
  align-items: center;
}

.news-sidebar__meta li {
  position: relative;
  display: flex;
  align-items: center;
}

.news-sidebar__meta li + li {
  margin-left: 10px;
}

.news-sidebar__meta li .icon {
  position: relative;
  display: inline-block;
  margin-right: 4px;
}

.news-sidebar__meta li .icon span {
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: var(--govity-base);
}

.news-sidebar__meta li .text {
  position: relative;
  display: block;
}

.news-sidebar__meta li .text p {
  font-size: 14px;
  letter-spacing: var(--govity-letter-spacing-two);
}

.news-sidebar__title {
  font-size: 34px;
  line-height: 44px;
  font-weight: 700;
  margin-top: 1px;
  margin-bottom: 10px;
}

.news-sidebar__title a {
  color: var(--govity-black);
  transition: all 500ms ease;
}

.news-sidebar__title a:hover {
  color: var(--govity-base);
}

.news-sidebar__text {
  margin: 0;
  letter-spacing: var(--govity-letter-spacing-two);
}

.news-sidebar__read-more {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: var(--govity-letter-spacing-two);
  color: var(--govity-gray);
  margin-top: 10px;
  transition: all 500ms ease;
}

.news-sidebar__read-more:hover {
  color: var(--govity-base);
}

.news-sidebar__read-more span {
  position: relative;
  left: 10px;
  top: 1px;
  font-size: 16px;
}

.news-sidebar__bottom-box {
  position: relative;
  display: block;
  background-color: var(--govity-primary);
  padding: 60px 60px 50px;
  margin-top: 20px;
  overflow: hidden;
  z-index: 1;
}

.news-sidebar__bottom-box-icon {
  margin-bottom: 21px;
}

.news-sidebar__bottom-box-text {
  letter-spacing: var(--govity-letter-spacing-two);
}

.news-sidebar__government-services {
  position: relative;
  display: block;
  background-color: var(--govity-primary);
  padding: 60px 60px 60px;
  margin-top: 30px;
  margin-bottom: 30px;
  overflow: hidden;
  z-index: 1;
}

.news-sidebar__government-services-icon {
  margin-bottom: 24px;
}

.news-sidebar__government-services-title {
  font-size: 30px;
  line-height: 35px;
  font-weight: 700;
}

.news-sidebar__government-services-title a {
  color: var(--govity-black);
  transition: all 500ms ease;
}

.news-sidebar__government-services-title a:hover {
  color: var(--govity-base);
}

/*--------------------------------------------------------------
# Contact Page
--------------------------------------------------------------*/
.contact-page {
  position: relative;
  display: block;
  padding: 120px 0 34px;
  z-index: 1;
}

.contact-page__top {
  position: relative;
  display: block;
}

.contact-page__left {
  position: relative;
  display: block;
  margin-left: 30px;
}

.contact-page__img-box {
  position: relative;
  display: block;
}

.contact-page__img {
  position: relative;
  display: block;
}

.contact-page__img::before {
  content: '';
  position: absolute;
  top: 28px;
  left: -30px;
  bottom: 32px;
  width: 30px;
  background-color: var(--govity-base);
}

.contact-page__img img {
  width: 100%;
}

.contact-page__right {
  position: relative;
  display: block;
  margin-left: 100px;
}

.contact-page__right .section-title {
  margin-bottom: 29px;
}

.contact-page__text {
  letter-spacing: var(--govity-letter-spacing-two);
}

.contact-page__contact-list {
  position: relative;
  display: block;
  margin-top: 17px;
}

.contact-page__contact-list li {
  position: relative;
  display: flex;
  align-items: center;
}

.contact-page__contact-list li + li {
  margin-top: 10px;
}

.contact-page__contact-list li .icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 80px;
  background-color: var(--govity-primary);
  font-size: 23px;
  color: var(--govity-base);
  transition: all 500ms ease;
}

.contact-page__contact-list li:hover .icon {
  background-color: var(--govity-base);
  color: var(--govity-white);
}

.contact-page__contact-list li .content {
  margin-left: 30px;
}

.contact-page__contact-list li .content p {
  letter-spacing: var(--govity-letter-spacing-two);
}

.contact-page__contact-list li .content h4 {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: var(--govity-letter-spacing-two);
  line-height: 27px;
}

.contact-page__contact-list li .content h4 a {
  color: var(--govity-black);
  transition: all 500ms ease;
}

.contact-page__contact-list li .content h4 a:hover {
  color: var(--govity-base);
}

.contact-page__contact-list li .content h4 a span {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: var(--govity-gray);
  letter-spacing: var(--govity-letter-spacing-two);
}

.contact-page__bottom {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 32px;
  border-top: 1px solid var(--govity-bdr-color);
  margin-top: 80px;
}

.contact-page__bottom-left {
  position: relative;
  display: flex;
  align-items: center;
}

.contact-page__bottom-icon {
  position: relative;
  display: inline-block;
  top: 6px;
}

.contact-page__bottom-icon span {
  position: relative;
  display: inline-block;
  font-size: 32px;
  color: var(--govity-base);
}

.contact-page__bottom-content {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.contact-page__bottom-content li + li {
  margin-left: 40px;
}

.contact-page__bottom-content li span {
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  color: var(--govity-black);
  letter-spacing: var(--govity-letter-spacing-two);
}

.contact-page__bottom-content li p {
  letter-spacing: var(--govity-letter-spacing-two);
  line-height: 26px;
}

.contact-page__bottom-right {
  position: relative;
  display: block;
}

.contact-page__social-shape-1 {
  position: absolute;
  top: -19px;
  left: 97px;
}

.contact-page__social-shape-1 img {
  width: auto;
}

.contact-page__social span {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: var(--govity-letter-spacing);
  color: var(--govity-black);
  margin-right: 60px;
}

.contact-page__social {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.contact-page__social a {
  position: relative;
  height: 43px;
  width: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--govity-black);
  background-color: var(--govity-primary);
  font-size: 15px;
  border-radius: 50%;
  overflow: hidden;
  transition: all 500ms ease;
  z-index: 1;
}

.contact-page__social a:hover {
  color: var(--govity-white);
  background-color: var(--govity-base);
}

.contact-page__social a:after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: var(--govity-base);
  transition-delay: 0.1s;
  transition-timing-function: ease-in-out;
  transition-duration: 0.4s;
  transition-property: all;
  opacity: 1;
  transform-origin: top;
  transform-style: preserve-3d;
  transform: scaleY(0);
  z-index: -1;
}

.contact-page__social a:hover:after {
  opacity: 1;
  transform: scaleY(1);
}

.contact-page__social a + a {
  margin-left: 10px;
}

/*--------------------------------------------------------------
# Google Map
--------------------------------------------------------------*/
.google-map {
  position: relative;
  display: block;
  background-color: var(--govity-primary);
}

.google-map::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 1110px;
  height: 110px;
  background-color: var(--govity-white);
  z-index: 2;
}

.google-map__one {
  position: relative;
  display: block;
  border: none;
  height: 565px;
  width: 100%;
  mix-blend-mode: luminosity;
}

/*--------------------------------------------------------------
# Contact One
--------------------------------------------------------------*/
.contact-one {
  position: relative;
  display: block;
  padding-bottom: 120px;
  z-index: 1;
}

.contact-one__bg {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 485px;
  opacity: 0.3;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.contact-one .container {
  max-width: 800px;
}

.contact-one__form-box {
  position: relative;
  display: block;
}

.contact-one__form {
  position: relative;
  display: block;
}

.contact-one__form .row {
  --bs-gutter-x: 20px;
}

.comment-one__form .row {
  --bs-gutter-x: 20px;
}

.contact-one__input-box {
  position: relative;
  display: block;
  margin-bottom: 20px;
}

.contact-one__input-box input[type='text'],
.contact-one__input-box input[type='email'] {
  height: 58px;
  width: 100%;
  border: none;
  background-color: var(--govity-primary);
  padding-left: 30px;
  padding-right: 30px;
  outline: none;
  font-size: 14px;
  color: var(--govity-gray);
  display: block;
  letter-spacing: var(--govity-letter-spacing-two);
}

.contact-one__input-box textarea {
  font-size: 14px;
  color: var(--govity-gray);
  height: 190px;
  width: 100%;
  background-color: var(--govity-primary);
  padding: 20px 30px 30px;
  border: none;
  outline: none;
  margin-bottom: 0px;
  letter-spacing: var(--govity-letter-spacing-two);
}

.contact-one__btn-box {
  position: relative;
  display: block;
  margin: 0 auto;
  text-align: center;
}

.contact-one__btn {
  border: none;
}

.contact-one__input-box.text-message-box {
  height: 190px;
}

/*--------------------------------------------------------------
# Event Details
--------------------------------------------------------------*/
.event-details {
  position: relative;
  display: block;
  padding: 120px 0 120px;
  z-index: 1;
}

.event-details__left {
  position: relative;
  display: block;
}

.event-details__date-box {
  position: relative;
  display: block;
  background-color: var(--govity-base);
  padding: 40px 40px 35px;
  margin-bottom: 30px;
  overflow: hidden;
  z-index: 1;
}

.event-details__date-box-shape-1 {
  position: absolute;
  top: -44px;
  right: -94px;
  opacity: 0.1;
  z-index: -1;
}

.event-details__date-title-and-icon {
  position: relative;
  display: flex;
  align-items: center;
}

.event-details__date-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 58px;
  height: 58px;
  background-color: var(--govity-black);
  border-radius: 50%;
  transition: all 500ms ease;
}

.event-details__date-icon:hover {
  background-color: var(--govity-white);
}

.event-details__date-icon span {
  position: relative;
  display: inline-block;
  font-size: 32px;
  color: var(--govity-white);
  transition: all 500ms ease;
}

.event-details__date-icon:hover span {
  color: var(--govity-base);
}

.event-details__date-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: var(--govity-letter-spacing-two);
  color: var(--govity-white);
  margin-left: 20px;
}

.event-details__date-text {
  font-size: 18px;
  line-height: 30px;
  letter-spacing: var(--govity-letter-spacing-two);
  color: var(--govity-white);
  margin-top: 21px;
}

.event-details__location {
  position: relative;
  display: block;
  background-color: var(--govity-base);
  padding: 40px 40px 35px;
  margin-bottom: 30px;
  overflow: hidden;
  z-index: 1;
}

.event-details__location-shape-1 {
  position: absolute;
  top: -20px;
  right: -53px;
  opacity: 0.1;
  z-index: -1;
}

.event-details__location-title-and-icon {
  position: relative;
  display: flex;
  align-items: center;
}

.event-details__location-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 58px;
  height: 58px;
  background-color: var(--govity-black);
  border-radius: 50%;
  transition: all 500ms ease;
}

.event-details__location-icon:hover {
  background-color: var(--govity-white);
}

.event-details__location-icon span {
  position: relative;
  display: inline-block;
  font-size: 32px;
  color: var(--govity-white);
  transition: all 500ms ease;
}

.event-details__location-icon:hover span {
  color: var(--govity-base);
}

.event-details__location-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: var(--govity-letter-spacing-two);
  color: var(--govity-white);
  margin-left: 20px;
}

.event-details__location-text {
  font-size: 18px;
  line-height: 30px;
  letter-spacing: var(--govity-letter-spacing-two);
  color: var(--govity-white);
  margin-top: 21px;
}

.event-details__title-1 {
  font-size: 34px;
  font-weight: 700;
  line-height: 44px;
  margin-top: 11px;
  margin-bottom: 10px;
}

.event-details__text-1 {
  letter-spacing: var(--govity-letter-spacing-two);
}

.event-details__text-2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  color: var(--govity-base);
  letter-spacing: var(--govity-letter-spacing-two);
  margin-top: 28px;
  margin-bottom: 32px;
}

.event-details__text-3 {
  letter-spacing: var(--govity-letter-spacing-two);
}

.event-details__img {
  position: relative;
  display: block;
  margin-top: 50px;
  margin-bottom: 51px;
}

.event-details__img img {
  width: 100%;
}

.event-details__text-4 {
  letter-spacing: var(--govity-letter-spacing-two);
}

.event-details__btn-box {
  margin-top: 50px;
}

.event-details__right {
  position: relative;
  display: block;
}

.event-details__user-info {
  position: relative;
  display: block;
  background-color: rgb(255, 255, 255);
  box-shadow:
    0px 10px 60px 0px rgba(0, 0, 0, 0.1),
    inset 0px 4px 0px 0px rgba(12, 165, 84, 0.004);
  text-align: center;
  padding: 42px 50px 50px;
  margin-bottom: 30px;
}

.event-details__user-info::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background-color: var(--govity-base);
}

.event-details__user-name {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
}

.event-details__user-text {
  letter-spacing: var(--govity-letter-spacing-two);
  margin-top: 13px;
  margin-bottom: 21px;
}

.event-details__user-number {
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  color: var(--govity-base);
  letter-spacing: var(--govity-letter-spacing-two);
  transition: all 500ms ease;
}

.event-details__user-number:hover {
  color: var(--govity-black);
}

.event-details__social {
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: center;
}

.event-details__social a {
  position: relative;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--govity-black);
  background-color: var(--govity-primary);
  font-size: 15px;
  border-radius: 50%;
  overflow: hidden;
  transition: all 500ms ease;
  z-index: 1;
}

.event-details__social a:hover {
  color: var(--govity-white);
  background-color: var(--govity-base);
}

.event-details__social a:after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: var(--govity-base);
  transition-delay: 0.1s;
  transition-timing-function: ease-in-out;
  transition-duration: 0.4s;
  transition-property: all;
  opacity: 1;
  transform-origin: top;
  transform-style: preserve-3d;
  transform: scaleY(0);
  z-index: -1;
}

.event-details__social a:hover:after {
  opacity: 1;
  transform: scaleY(1);
}

.event-details__social a + a {
  margin-left: 10px;
}

.event-details__google-map {
  position: relative;
  display: block;
  background-color: var(--govity-primary);
}

.event-details__google-map-one {
  position: relative;
  display: block;
  border: none;
  height: 440px;
  width: 100%;
  mix-blend-mode: luminosity;
}

/*--------------------------------------------------------------
# Departments Details
--------------------------------------------------------------*/
.department-details {
  position: relative;
  display: block;
  padding: 120px 0 120px;
  z-index: 1;
}

.department-details__left {
  position: relative;
  display: block;
}

.department-details__icon-and-title {
  position: relative;
  display: flex;
  align-items: center;
}

.department-details__icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  background-color: var(--govity-base);
  border-radius: 50%;
  transition: all 500ms ease;
}

.department-details__icon:hover {
  background-color: var(--govity-black);
}

.department-details__icon span {
  position: relative;
  display: inline-block;
  font-size: 18px;
  color: var(--govity-white);
}

.department-details__title {
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  margin-left: 20px;
}

.department-details__text-1 {
  letter-spacing: var(--govity-letter-spacing-two);
  margin-top: 16px;
  margin-bottom: 29px;
}

.department-details__text-2 {
  letter-spacing: var(--govity-letter-spacing-two);
  font-size: 18px;
  font-weight: 700;
  color: var(--govity-black);
  line-height: 30px;
}

.department-details__text-3 {
  letter-spacing: var(--govity-letter-spacing-two);
  margin-top: 31px;
  margin-bottom: 53px;
}

.department-details__img-and-points-box {
  position: relative;
  display: block;
}

.department-details__img {
  position: relative;
  display: block;
}

.department-details__img img {
  width: 100%;
}

.department-details__opportunities-box {
  position: relative;
  display: flex;
  align-items: center;
  background-color: var(--govity-base);
  padding: 18px 30px 20px;
}

.department-details__opportunities-icon {
  position: relative;
  display: inline-block;
  top: 5px;
}

.department-details__opportunities-icon span {
  position: relative;
  display: inline-block;
  font-size: 32px;
  color: var(--govity-white);
}

.department-details__opportunities-text {
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: var(--govity-letter-spacing);
  color: var(--govity-white);
  margin-left: 20px;
}

.department-details__points-box {
  position: relative;
  display: block;
  margin-top: -8px;
}

.department-details__points-box-title {
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
}

.department-details__points-box-text {
  margin-top: 11px;
  margin-bottom: 23px;
}

.department-details__points-list {
  position: relative;
  display: block;
}

.department-details__points-list li {
  position: relative;
  display: flex;
  align-items: center;
}

.department-details__points-list li + li {
  margin-top: 8px;
}

.department-details__points-list li .icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  background-color: var(--govity-base);
  border-radius: 50%;
}

.department-details__points-list li .icon span {
  position: relative;
  display: inline-block;
  font-size: 8px;
  color: var(--govity-white);
}

.department-details__points-list li .text {
  position: relative;
  display: block;
  margin-left: 16px;
}

.department-details__points-list li .text p {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  color: var(--govity-black);
  letter-spacing: var(--govity-letter-spacing-two);
}

.department-details__title-two {
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  margin-top: 51px;
  margin-bottom: 21px;
}

.department-details__text-4 {
  letter-spacing: var(--govity-letter-spacing-two);
}

.department-details__text-5 {
  letter-spacing: var(--govity-letter-spacing-two);
  margin-top: 30px;
  margin-bottom: 41px;
}

.department-details__download {
  position: relative;
  display: block;
}

.department-details__download-title {
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 30px;
}

.department-details__download-list {
  position: relative;
  display: block;
}

.department-details__download-list li {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--govity-bdr-color);
  padding: 19px 24px 19px;
}

.department-details__download-list li + li {
  margin-top: 10px;
}

.department-details__download-content {
  position: relative;
  display: flex;
  align-items: center;
}

.department-details__download-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 51px;
  height: 51px;
  background-color: var(--govity-base);
  border-radius: 50%;
  transition: all 500ms ease;
}

.department-details__download-icon:hover {
  background-color: var(--govity-black);
}

.department-details__download-icon span {
  position: relative;
  display: inline-block;
  font-size: 24px;
  color: var(--govity-white);
  transition: all 500ms ease;
}

.department-details__download-icon:hover span {
  color: var(--govity-white);
}

.department-details__download-text {
  position: relative;
  display: block;
  margin-left: 20px;
}

.department-details__download-text p {
  font-weight: 500;
  letter-spacing: var(--govity-letter-spacing-two);
  color: var(--govity-black);
}

.department-details__download-btn-box {
  position: relative;
  display: block;
}

.department-details__download-btn-box span {
  font-size: 12px;
  letter-spacing: 0.1em;
  margin-right: 14px;
}

.department-details__download-btn {
  font-size: 14px;
  font-weight: 700;
  padding: 5px 30px 6px;
  color: var(--govity-gray);
  background-color: var(--govity-primary);
  letter-spacing: var(--govity-letter-spacing-two);
}

.department-details__right {
  position: relative;
  display: block;
  background-color: var(--govity-primary);
  padding: 25px 25px 25px;
}

.department-details__services-box {
  position: relative;
  display: block;
  margin-bottom: 25px;
}

.department-details__services-list {
  position: relative;
  display: block;
}

.department-details__services-list li {
  position: relative;
  display: block;
}

.department-details__services-list li a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: var(--govity-letter-spacing);
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
  color: var(--govity-gray);
  padding: 15px 30px 15px;
  transition: all 500ms ease;
}

.department-details__services-list li a:hover {
  color: var(--govity-base);
}

.department-details__great-place {
  position: relative;
  display: block;
  background-color: var(--govity-base);
  text-align: center;
  padding: 50px 40px 50px;
  margin-bottom: 25px;
  z-index: 1;
}

.department-details__great-place-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  mix-blend-mode: luminosity;
  z-index: -1;
}

.department-details__great-place-icon {
  position: relative;
  display: inline-block;
}

.department-details__great-place-icon span {
  position: relative;
  display: inline-block;
  font-size: 64px;
  color: var(--govity-white);
  transition: all 500ms linear;
  transition-delay: 0.1s;
}

.department-details__great-place-icon:hover span {
  transform: scale(0.9);
}

.department-details__great-place-text {
  font-size: 26px;
  font-weight: 700;
  line-height: 34px;
  color: var(--govity-white);
  margin-top: 15px;
  margin-bottom: 22px;
}

.department-details__great-place-btn {
  background-color: var(--govity-white);
  color: var(--govity-black);
  font-size: 14px;
  font-weight: 700;
  padding: 5px 30px 6px;
  letter-spacing: var(--govity-letter-spacing-two);
}

.department-details__call-box {
  position: relative;
  display: flex;
  align-items: center;
  background-color: var(--govity-black);
  padding: 17px 40px 22px;
}

.department-details__call-box-icon {
  position: relative;
  display: inline-block;
  top: 6px;
}

.department-details__call-box-icon span {
  position: relative;
  display: inline-block;
  font-size: 28px;
  color: var(--govity-base);
  transition: all 500ms linear;
  transition-delay: 0.1s;
}

.department-details__call-box-icon:hover span {
  transform: scale(0.9);
}

.department-details__call-box-content {
  position: relative;
  display: block;
  margin-left: 20px;
}

.department-details__call-box-content p {
  color: #777777;
  letter-spacing: var(--govity-letter-spacing-two);
}

.department-details__call-box-content h3 {
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  color: var(--govity-white);
  letter-spacing: var(--govity-letter-spacing-two);
}

.department-details__call-box-content h3 a {
  color: var(--govity-white);
  transition: all 500ms ease;
}

.department-details__call-box-content h3 a:hover {
  color: var(--govity-base);
}

.department-details__call-box-content h3 span {
  font-size: 16px;
  font-weight: 400;
  color: #777777;
}

/*--------------------------------------------------------------
# Service Details
--------------------------------------------------------------*/
.service-details {
  position: relative;
  display: block;
  padding: 120px 0 120px;
}

.service-details__left {
  position: relative;
  display: block;
}

.service-details__category {
  position: relative;
  display: block;
}

.service-details__category-list {
  position: relative;
  display: block;
}

.service-details__category-list li {
  position: relative;
  display: block;
}

.service-details__category-list li + li {
  margin-top: 10px;
}

.service-details__category-list li a {
  color: var(--govity-gray);
  font-size: 16px;
  font-weight: 500;
  position: relative;
  transition: all 500ms ease;
  display: block;
  background: none;
  padding: 12px 30px 11px;
  background-color: var(--govity-primary);
  z-index: 1;
  border: 1px solid var(--govity-bdr-color);
  letter-spacing: var(--govity-letter-spacing-two);
}

.service-details__category-list li:hover a {
  color: var(--govity-black);
  background-color: var(--govity-white);
}

.service-details__category-list li.active a {
  color: var(--govity-black);
  background-color: var(--govity-white);
}

.service-details__category-list li a:after {
  position: absolute;
  bottom: 0px;
  top: 0px;
  left: 0;
  right: 0px;
  content: '';
  border: 1px solid var(--govity-base);
  transform: scaleX(0);
  transition: all 500ms ease;
  z-index: -1;
}

.service-details__category-list li:hover a:after {
  transform: scaleX(1);
}

.service-details__category-list li.active a:after {
  transform: scaleX(1);
}

.service-details__category-list li a span {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%) scale(1);
  transition: all 500ms ease;
  color: var(--govity-gray);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}

.service-details__category-list li a:hover span {
  color: var(--govity-base);
  transform: translateY(-50%) scale(1);
}

.service-details__category-list li.active a span {
  transform: translateY(-50%) scale(1);
  color: var(--govity-base);
}

.service-details__need-help {
  position: relative;
  display: block;
  padding: 50px 50px 43px;
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
  z-index: 1;
  background-color: var(--govity-base);
}

.service-details__need-help-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.1;
  background-blend-mode: luminosity;
  z-index: -1;
}

.service-details__need-help-icon {
  height: 85px;
  width: 85px;
  background-color: var(--govity-black);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 auto 13px;
  transition: all 500ms ease;
}

.service-details__need-help-icon:hover {
  background-color: var(--govity-white);
}

.service-details__need-help-icon span {
  font-size: 30px;
  color: var(--govity-white);
  transition: all 500ms ease;
}

.service-details__need-help-icon:hover span {
  color: var(--govity-base);
}

.service-details__need-help-title {
  font-size: 30px;
  color: var(--govity-white);
  line-height: 36px;
  font-weight: 700;
}

.service-details__need-help-contact {
  position: relative;
  display: block;
  margin-top: 36px;
}

.service-details__need-help-contact p {
  font-size: 12px;
  color: var(--govity-white);
  margin: 0;
  line-height: 16px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.service-details__need-help-contact a {
  font-size: 20px;
  color: var(--govity-white);
  font-weight: 700;
  transition: all 500ms ease;
}

.service-details__need-help-contact a:hover {
  color: var(--govity-black);
}

.service-details__right {
  position: relative;
  display: block;
}

.service-details__img {
  position: relative;
  display: block;
}

.service-details__img img {
  width: 100%;
}

.service-details__title-1 {
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  margin-top: 21px;
  margin-bottom: 21px;
}

.service-details__text-1 {
  letter-spacing: var(--govity-letter-spacing-two);
}

.service-details__text-box {
  position: relative;
  display: block;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.07);
  padding: 18px 35px 22px;
  margin-top: 38px;
  margin-bottom: 46px;
}

.service-details__text-box::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 6px;
  background-color: var(--govity-base);
}

.service-details__text-box p {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  color: var(--govity-black);
  letter-spacing: var(--govity-letter-spacing-two);
}

.service-details__text-2 {
  letter-spacing: var(--govity-letter-spacing-two);
}

.service-details__business-box {
  position: relative;
  display: block;
  margin-top: 43px;
}

.service-details__business-single {
  position: relative;
  display: flex;
  align-items: center;
  background-color: var(--govity-primary);
  padding: 32px 40px 26px;
  margin-bottom: 30px;
}

.service-details__business-icon {
  position: relative;
  display: inline-block;
}

.service-details__business-icon span {
  position: relative;
  display: inline-block;
  font-size: 64px;
  color: var(--govity-base);
  transition: all 500ms linear;
  transition-delay: 0.1s;
}

.service-details__business-single:hover .service-details__business-icon span {
  transform: scale(0.9);
}

.service-details__business-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: var(--govity-letter-spacing-two);
  margin-left: 30px;
}

.service-details__benefit {
  position: relative;
  display: block;
  margin-top: 30px;
  margin-bottom: 52px;
}

.service-details__benefit-img {
  position: relative;
  display: block;
}

.service-details__benefit-img img {
  border-radius: 50%;
  width: 100%;
}

.service-details__benefit-content {
  position: relative;
  display: block;
  margin-top: 47px;
}

.service-details__benefit-title {
  font-size: 24px;
  font-weight: 800;
  line-height: 24px;
}

.service-details__benefit-text {
  font-size: 18px;
  padding-top: 21px;
  padding-bottom: 35px;
  letter-spacing: var(--govity-letter-spacing-two);
  color: var(--govity-base);
}

.service-details__benefit-points {
  position: relative;
  display: block;
}

.service-details__benefit-points li {
  position: relative;
  display: flex;
  align-items: center;
}

.service-details__benefit-points li + li {
  margin-top: 6px;
}

.service-details__benefit-points li .icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: var(--govity-base);
  font-size: 9px;
  color: var(--govity-white);
}

.service-details__benefit-points li .text {
  margin-left: 10px;
}

.service-details__benefit-points li .text p {
  font-size: 16px;
  font-weight: 500;
  color: var(--govity-gray);
  letter-spacing: var(--govity-letter-spacing-two);
}

.service-details__text-3 {
  letter-spacing: var(--govity-letter-spacing-two);
}

.service-details__text-4 {
  letter-spacing: var(--govity-letter-spacing-two);
  margin-top: 30px;
  margin-bottom: 53px;
}

.faq-one__right {
  position: relative;
  display: block;
  border: 1px solid var(--govity-bdr-color);
  padding: 3px 60px 20px;
}

.faq-one-accrodion .accrodion {
  position: relative;
  display: block;
  transition: all 500ms ease;
}

.faq-one-accrodion .accrodion-title {
  position: relative;
  display: block;
  cursor: pointer;
  padding: 41px 0px 25px;
  transition: all 200ms linear;
  transition-delay: 0.1s;
  border-bottom: 1px solid var(--govity-bdr-color);
}

.faq-one-accrodion .accrodion-title h4 {
  margin: 0;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  color: var(--govity-black);
  position: relative;
  transition: all 500ms ease;
}

.faq-one-accrodion .accrodion + .accrodion {
  margin-top: 0px;
}

.faq-one-accrodion .accrodion.active .accrodion-title {
  border-bottom: 1px solid transparent;
}

.faq-one-accrodion .accrodion-title h4::before {
  content: '\f067';
  font-family: 'Font Awesome 5 Free';
  font-weight: 700;
  font-size: 10px;
  color: var(--govity-black);
  position: absolute;
  top: 50%;
  right: 0;
  line-height: 15px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-50%);
  transition: all 500ms ease;
  text-align: center;
  width: 21px;
  height: 21px;
  border: 1px solid var(--govity-black);
}

.faq-one-accrodion .accrodion.active .accrodion-title h4::before {
  content: '\f068';
  color: var(--govity-base);
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--govity-base);
}

.faq-one-accrodion .accrodion-content {
  position: relative;
  border-bottom: 1px solid var(--govity-bdr-color);
  padding-bottom: 34px;
}

.faq-one-accrodion .accrodion-content p {
  margin: 0;
  letter-spacing: var(--govity-letter-spacing-two);
}

.faq-one-accrodion .accrodion.last-chiled .accrodion-title {
  border-bottom: 1px solid transparent;
}

.faq-one-accrodion .accrodion.active.last-chiled .accrodion-title {
  border-bottom: 1px solid var(--govity-bdr-color);
}

.faq-one-accrodion .accrodion.last-chiled .accrodion-content {
  border-bottom: 1px solid transparent;
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  position: relative;
  display: block;
  padding-top: 120px;
  z-index: 1;
}

.portfolio-details__img {
  position: relative;
  display: block;
}

.portfolio-details__img img {
  width: 100%;
}

.portfolio-details__left {
  position: relative;
  display: block;
  margin-top: 19px;
  margin-right: 300px;
}

.portfolio-details__title {
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
}

.portfolio-details__text {
  letter-spacing: var(--govity-letter-spacing-two);
  margin-top: 20px;
  margin-bottom: 41px;
}

.portfolio-details__points {
  position: relative;
  display: block;
}

.portfolio-details__points li {
  position: relative;
  display: flex;
  align-items: center;
}

.portfolio-details__points li + li {
  margin-top: 8px;
}

.portfolio-details__points li .icon {
  position: relative;
  display: inline-block;
}

.portfolio-details__points li .icon span {
  position: relative;
  display: inline-block;
  font-size: 17px;
  color: var(--govity-base);
}

.portfolio-details__points li .text {
  margin-left: 20px;
}

.portfolio-details__points li .text p {
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  color: var(--govity-black);
  letter-spacing: var(--govity-letter-spacing-two);
}

.portfolio-details__text-two {
  letter-spacing: var(--govity-letter-spacing-two);
  margin-top: 41px;
  margin-bottom: 90px;
}

.portfolio-details__right {
  position: absolute;
  bottom: -275px;
  right: 0;
}

.project-details__info-list {
  position: relative;
  display: block;
  background-color: rgb(255, 255, 255);
  box-shadow:
    0px 10px 60px 0px rgba(0, 0, 0, 0.07),
    inset 0px 4px 0px 0px rgba(12, 165, 84, 0.004);
  padding: 41px 50px 33px;
  padding-right: 40px;
}

.project-details__info-list::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 4px;
  background-color: var(--govity-base);
}

.project-details__info-list li {
  position: relative;
  display: block;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--govity-bdr-color);
}

.project-details__info-list li:last-child {
  border-bottom: none;
}

.project-details__info-list li + li {
  margin-top: 11px;
}

.project-details__info-list li span {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: var(--govity-letter-spacing-two);
  color: var(--govity-black);
}

.project-details__info-list li p {
  letter-spacing: var(--govity-letter-spacing-two);
}

.portfolio-details__pagination-box {
  position: relative;
  display: block;
  text-align: center;
  border-top: 1px solid var(--govity-bdr-color);
  border-bottom: 1px solid var(--govity-bdr-color);
  padding: 41px 0 38px;
}

.portfolio-details__pagination li {
  display: inline-block;
}

.portfolio-details__pagination li.next {
  float: left;
  position: relative;
}

.portfolio-details__pagination li a {
  font-size: 16px;
  color: var(--govity-black);
  font-weight: 700;
  display: flex;
  align-items: center;
  transition: all 500ms ease;
  letter-spacing: var(--govity-letter-spacing-two);
}

.portfolio-details__pagination li.next i {
  position: relative;
  height: 52px;
  width: 52px;
  background-color: var(--govity-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--govity-black);
  font-size: 16px;
  transition: all 500ms ease;
  margin-right: 20px;
  z-index: 1;
}

.portfolio-details__pagination li.previous {
  position: relative;
  float: right;
}

.portfolio-details__pagination li.previous i {
  position: relative;
  height: 52px;
  width: 52px;
  background-color: var(--govity-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--govity-black);
  font-size: 16px;
  transition: all 500ms ease;
  margin-left: 20px;
  z-index: 1;
}

.portfolio-details__pagination li a:hover i {
  color: var(--govity-white);
  background-color: var(--govity-base);
}

/*--------------------------------------------------------------
# Similar Portfolio
--------------------------------------------------------------*/
.similar-portfolio {
  position: relative;
  display: block;
  padding: 120px 0 90px;
  z-index: 1;
}

.similar-portfolio__single {
  position: relative;
  display: block;
  margin-bottom: 30px;
}

.similar-portfolio__img-box {
  position: relative;
  display: block;
}

.similar-portfolio__img {
  position: relative;
  display: block;
  overflow: hidden;
  z-index: 1;
}

.similar-portfolio__img:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(var(--govity-black-rgb), 0.6);
  visibility: hidden;
  transform-origin: top center;
  transform: scaleY(0) translateZ(100px);
  transition:
    opacity 500ms ease,
    visibility 500ms ease,
    transform 700ms ease;
}

.similar-portfolio__single:hover .similar-portfolio__img:before {
  visibility: visible;
  transform: scaleY(1) translateZ(0px);
}

.similar-portfolio__img img {
  width: 100%;
}

.similar-portfolio__content {
  position: absolute;
  bottom: 30px;
  left: 0;
  transform: translateY(66%);
  transition:
    opacity 500ms ease,
    visibility 500ms ease,
    transform 500ms ease;
  visibility: hidden;
  opacity: 0;
  z-index: 2;
}

.similar-portfolio__single:hover .similar-portfolio__content {
  visibility: visible;
  transform: translateY(0%);
  transition-delay: 500ms;
  opacity: 1;
}

.similar-portfolio__sub-title {
  position: relative;
  display: inline-block;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
  color: var(--govity-white);
  background-color: var(--govity-base);
  padding: 8px 30px 8px;
  letter-spacing: 0.1em;
}

.similar-portfolio__title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
}

.similar-portfolio__title a {
  color: var(--govity-black);
  display: inline-block;
  padding: 14px 30px 13px;
  padding-right: 60px;
  background-color: var(--govity-white);
  transition: all 500ms ease;
}

.similar-portfolio__title a:hover {
  color: var(--govity-base);
}

/*--------------------------------------------------------------
# Team Details
--------------------------------------------------------------*/
.team-details {
  position: relative;
  display: block;
  z-index: 1;
}

.team-details__top {
  position: relative;
  display: block;
  padding: 120px 0 100px;
  border-bottom: 1px solid var(--govity-bdr-color);
}

.team-details__left {
  position: relative;
  display: block;
  margin-right: 20px;
}

.team-details__img {
  position: relative;
  display: block;
}

.team-details__img img {
  width: 100%;
}

.team-details__shape-1 {
  position: absolute;
  right: -33px;
  bottom: -25px;
}

.team-details__shape-1 img {
  width: auto;
}

.team-details__right {
  position: relative;
  display: block;
  margin-left: 70px;
  margin-top: -11px;
}

.team-details__top-content {
  position: relative;
  display: block;
}

.team-details__top-name {
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
}

.team-details__top-title {
  letter-spacing: var(--govity-letter-spacing);
  margin-top: 5px;
  margin-bottom: 20px;
}

.team-details__social {
  position: relative;
  display: flex;
  align-items: center;
}

.team-details__social a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-size: 14px;
  color: var(--govity-black);
  background-color: var(--govity-primary);
  border-radius: 50%;
  overflow: hidden;
  transition: all 500ms ease;
  z-index: 1;
}

.team-details__social a:hover {
  color: var(--govity-white);
  background-color: var(--govity-base);
}

.team-details__social a:after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: var(--govity-base);
  transition-delay: 0.1s;
  transition-timing-function: ease-in-out;
  transition-duration: 0.4s;
  transition-property: all;
  opacity: 1;
  transform-origin: top;
  transform-style: preserve-3d;
  transform: scaleY(0);
  z-index: -1;
}

.team-details__social a:hover:after {
  opacity: 1;
  transform: scaleY(1);
}

.team-details__social a + a {
  margin-left: 10px;
}

.team-details__top-text-1 {
  font-size: 22px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: var(--govity-letter-spacing);
  color: var(--govity-base);
  margin-top: 21px;
  margin-bottom: 29px;
}

.team-details__top-text-2 {
  letter-spacing: var(--govity-letter-spacing-two);
}

.team-details__contact {
  position: relative;
  display: block;
  margin-top: 31px;
}

.team-details__contact p {
  font-size: 18px;
  letter-spacing: var(--govity-letter-spacing-two);
}

.team-details__contact p + p {
  margin-top: 6px;
}

.team-details__contact p span {
  font-weight: 700;
  color: var(--govity-black);
}

.team-details__contact p a {
  font-weight: 700;
  color: var(--govity-black);
  transition: all 500ms ease;
}

.team-details__contact p a:hover {
  color: var(--govity-base);
}

.team-details__bottom {
  position: relative;
  display: block;
  padding-top: 93px;
  padding-bottom: 120px;
}

.team-details__bottom-left {
  position: relative;
  display: block;
  margin-right: 80px;
  margin-top: -2px;
}

.team-details__bottom-left-title {
  font-size: 36px;
  font-weight: 700;
  line-height: 46px;
}

.team-details__bottom-left-text {
  font-size: 18px;
  line-height: 34px;
  padding-top: 30px;
  letter-spacing: var(--govity-letter-spacing-two);
}

.team-details__bottom-right {
  position: relative;
  display: block;
  margin-left: 70px;
}

.team-details__progress {
  position: relative;
  display: block;
  width: 100%;
}

.team-details__progress-single {
  position: relative;
  display: block;
}

.team-details__progress-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  color: var(--govity-black);
  margin-bottom: 6px;
  letter-spacing: var(--govity-letter-spacing-two);
}

.team-details__progress .bar {
  position: relative;
  width: 100%;
  height: 13px;
  background-color: var(--govity-primary);
  border-radius: 7px;
  margin-bottom: 12px;
}

.team-details__progress .bar-inner {
  position: relative;
  display: block;
  width: 0px;
  height: 13px;
  border-radius: 7px;
  background-color: var(--govity-base);
  transition: all 1500ms ease;
}

.team-details__progress .count-text {
  position: absolute;
  right: 0px;
  bottom: 21px;
  color: var(--govity-gray);
  line-height: 24px;
  font-size: 14px;
  text-align: center;
  opacity: 0;
  letter-spacing: var(--govity-letter-spacing-two);
  transition: all 500ms ease;
}

.team-details__progress .bar-inner.counted .count-text {
  opacity: 1;
}

.team-details__progress .bar.marb-0 {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Contact Two
--------------------------------------------------------------*/
.contact-two {
  position: relative;
  display: block;
  padding: 120px 0 120px;
  background-color: var(--govity-primary);
  z-index: 1;
}

.contact-two__bg {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 485px;
  opacity: 0.1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  mix-blend-mode: luminosity;
  z-index: -1;
}

.contact-two .container {
  max-width: 800px;
}

.contact-two__form-box {
  position: relative;
  display: block;
}

.contact-two__form {
  position: relative;
  display: block;
}

.contact-two__form .row {
  --bs-gutter-x: 20px;
}

.contact-two__input-box {
  position: relative;
  display: block;
  margin-bottom: 20px;
}

.contact-two__input-box input[type='text'],
.contact-two__input-box input[type='email'] {
  height: 58px;
  width: 100%;
  border: none;
  background-color: var(--govity-white);
  padding-left: 30px;
  padding-right: 30px;
  outline: none;
  font-size: 14px;
  color: var(--govity-gray);
  display: block;
  letter-spacing: var(--govity-letter-spacing-two);
}

.contact-two__input-box textarea {
  font-size: 14px;
  color: var(--govity-gray);
  height: 190px;
  width: 100%;
  background-color: var(--govity-white);
  padding: 20px 30px 30px;
  border: none;
  outline: none;
  margin-bottom: 0px;
  letter-spacing: var(--govity-letter-spacing-two);
}

.contact-two__btn-box {
  position: relative;
  display: block;
  margin: 0 auto;
  text-align: center;
}

.contact-two__btn {
  border: none;
}

.contact-two__input-box.text-message-box {
  height: 190px;
}

/*--------------------------------------------------------------
## Error Page
--------------------------------------------------------------*/
.error-page {
  position: relative;
  display: block;
  padding: 75px 0 120px;
  z-index: 1;
}

.error-page__bg {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 485px;
  opacity: 0.3;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.error-page__inner {
  position: relative;
  display: block;
  text-align: center;
}

.error-page__title-box {
  position: relative;
  display: inline-block;
}

.error-page__title-shape-1 {
  position: absolute;
  top: 45px;
  left: 214px;
}

.error-page__title-shape-1 img {
  width: auto;
}

.error-page__title {
  position: relative;
  display: inline-block;
  font-size: 350px;
  line-height: 350px;
  font-weight: 400;
  color: var(--govity-base);
}

.error-page__title span {
  margin-left: 149px;
}

.error-page__tagline {
  font-size: 40px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 16px;
  color: var(--govity-black);
  margin-top: 3px;
}

.error-page__text {
  margin: 0;
  font-size: 20px;
  font-weight: 400;
}

.error-page__form {
  position: relative;
  display: block;
  margin: 32px auto 20px;
}

.error-page__form-input {
  position: relative;
  display: block;
  max-width: 530px;
  width: 100%;
  margin: 0 auto;
}

.error-page__form input[type='search'] {
  height: 54px;
  width: 100%;
  border: none;
  outline: none;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
  font-size: 14px;
  color: var(--govity-gray);
  font-weight: 500;
  padding-left: 50px;
  padding-right: 75px;
}

.error-page__form button[type='submit'] {
  background-color: transparent;
  color: var(--govity-black);
  font-size: 22px;
  position: absolute;
  top: 0;
  right: 0px;
  bottom: 0;
  width: 72px;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  padding: 0;
}

.error-page__btn {
  padding-left: 50px;
}

/*--------------------------------------------------------------
# Search Box
--------------------------------------------------------------*/
.search-box {
  position: relative;
  display: block;
  padding: 120px 0 109px;
  z-index: 1;
}

.search-box__inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.search-box__left {
  position: relative;
  display: block;
  max-width: 800px;
  width: 100%;
  background-color: var(--govity-primary);
  padding: 53px 60px 53px;
}

.search-box__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
}

.search-box__form {
  position: relative;
  display: block;
  margin-top: 11px;
  margin-bottom: 11px;
}

.search-box__form-input {
  position: relative;
  display: block;
  max-width: 640px;
  width: 100%;
}

.search-box__form input[type='search'] {
  height: 54px;
  width: 100%;
  border: none;
  outline: none;
  background-color: var(--govity-white);
  font-size: 14px;
  color: var(--govity-gray);
  font-weight: 500;
  padding-left: 50px;
  padding-right: 75px;
}

.search-box__form button[type='submit'] {
  background-color: transparent;
  color: var(--govity-black);
  font-size: 22px;
  position: absolute;
  top: 0;
  right: 0px;
  bottom: 0;
  width: 72px;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  padding: 0;
}

.search-box__text {
  letter-spacing: var(--govity-letter-spacing-two);
}

.search-box__text span {
  color: var(--govity-black);
}

.search-box__right {
  position: relative;
  display: block;
  max-width: 370px;
  width: 100%;
  overflow: hidden;
  background-color: var(--govity-base);
  padding: 54px 60px 60px;
  z-index: 1;
}

.search-box__img {
  position: absolute;
  top: 0;
  right: -100px;
  mix-blend-mode: luminosity;
  opacity: 0.1;
}

.search-box__call {
  position: relative;
  display: block;
}

.search-box__call-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  color: var(--govity-white);
}

.search-box__call-content {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 34px;
}

.search-box__call-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: var(--govity-black);
  border-radius: 50%;
  transition: all 500ms ease;
}

.search-box__call-icon:hover {
  background-color: var(--govity-white);
}

.search-box__call-icon span {
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: var(--govity-white);
  transition: all 500ms ease;
}

.search-box__call-icon:hover span {
  color: var(--govity-base);
}

.search-box__call-number {
  position: relative;
  display: block;
  margin-left: 15px;
}

.search-box__call-number p {
  font-size: 12px;
  letter-spacing: var(--govity-letter-spacing-two);
  color: var(--govity-white);
  line-height: 12px;
}

.search-box__call-number h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: var(--govity-letter-spacing-two);
}

.search-box__call-number h3 a {
  color: var(--govity-white);
  transition: all 500ms ease;
}

.search-box__call-number h3 a:hover {
  color: var(--govity-black);
}

/*--------------------------------------------------------------
# FAQ Page
--------------------------------------------------------------*/
.faq-page {
  position: relative;
  display: block;
  padding-bottom: 120px;
  z-index: 1;
}

.faq-page__left {
  position: relative;
  display: block;
  margin-right: -21px;
}

.faq-page__left-icon-and-text {
  position: relative;
  display: flex;
  align-items: center;
}

.faq-page__left-icon {
  position: relative;
  display: inline-block;
  top: 7px;
}

.faq-page__left-icon span {
  position: relative;
  display: inline-block;
  font-size: 63px;
  color: var(--govity-base);
  transition: all 500ms linear;
  transition-delay: 0.1s;
}

.faq-page__left-icon:hover span {
  transform: scale(0.9);
}

.faq-page__text-box-2 {
  position: relative;
  display: block;
  padding-left: 30px;
  padding-top: 24px;
  padding-bottom: 30px;
  margin-top: 27px;
  border-left: 4px solid var(--govity-bdr-color);
}

.faq-page__left-text {
  margin-left: 20px;
}

.faq-page__left-text p {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  color: var(--govity-black);
  letter-spacing: var(--govity-letter-spacing);
}

.faq-page__left-text-2 {
  letter-spacing: var(--govity-letter-spacing-two);
  margin-bottom: 20px;
}

.faq-page__left-btn {
  font-size: 14px;
  padding: 8px 30px 7px;
}

.faq-page__right {
  position: relative;
  display: block;
  margin-left: 70px;
  margin-top: 11px;
}

.faq-page__right .faq-one__right {
  padding: 3px 60px 60px;
}

/*--------------------------------------------------------------
# Feature Five
--------------------------------------------------------------*/
.feature-five {
  position: relative;
  display: block;
  padding: 120px 0 90px;
  z-index: 1;
}

/*--------------------------------------------------------------
# Offering One
--------------------------------------------------------------*/
.offering-one {
  position: relative;
  display: block;
  padding-top: 120px;
  z-index: 1;
}

.offering-one__left {
  position: relative;
  display: block;
  margin-right: 95px;
}

.offering-one__left .section-title {
  margin-bottom: 29px;
}

.offering-one__text {
  letter-spacing: var(--govity-letter-spacing-two);
}

.offering-one__points {
  position: relative;
  display: block;
  margin-top: 30px;
  margin-bottom: 43px;
}

.offering-one__points li {
  position: relative;
  display: flex;
  align-items: center;
}

.offering-one__points li .icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  background-color: var(--govity-base);
  border-radius: 50%;
}

.offering-one__points li .icon span {
  position: relative;
  display: inline-block;
  font-size: 9px;
  color: var(--govity-white);
}

.offering-one__points li .text {
  margin-left: 10px;
}

.offering-one__points li .text p {
  font-weight: 700;
  letter-spacing: var(--govity-letter-spacing-two);
  color: var(--govity-black);
}

.offering-one__right {
  position: relative;
  display: block;
}

.offering-one__shape-1 {
  position: absolute;
  left: -105px;
  top: 150px;
}

.offering-one__shape-1 img {
  width: auto;
}

.offering-one__img-box {
  position: relative;
  display: block;
}

.offering-one__img {
  position: relative;
  display: block;
}

.offering-one__img::before {
  content: '';
  position: absolute;
  top: 60px;
  right: 160px;
  bottom: 60px;
  left: -59px;
  border: 6px solid var(--govity-base);
}

.offering-one__img img {
  width: 100%;
}

.offering-one__img-2 {
  position: absolute;
  bottom: 0;
  left: -127px;
}

.offering-one__img-2 img {
  width: auto;
}

/*--------------------------------------------------------------
# Brand three
--------------------------------------------------------------*/
.brand-three__img {
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0.04;
  mix-blend-mode: luminosity;
  z-index: -1;
}

.brand-three__img img {
  width: auto;
}

/*--------------------------------------------------------------
# CTA One
--------------------------------------------------------------*/
.cta-one {
  position: relative;
  display: block;
  background-color: var(--govity-base);
  padding: 88px 0 100px;
  z-index: 1;
}

.cta-one__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.1;
  mix-blend-mode: multiply;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.cta-one__inner {
  position: relative;
  display: block;
  text-align: center;
}

.cta-one__title {
  font-size: 60px;
  font-weight: 700;
  line-height: 72px;
  color: var(--govity-white);
}

.cta-one__btn-box {
  position: relative;
  display: block;
  margin-top: 34px;
}

.cta-one__btn {
  background-color: var(--govity-black);
}

.cta-one__btn:hover {
  color: var(--govity-base);
}

.cta-one__btn::before {
  background-color: var(--govity-white);
}

/*--------------------------------------------------------------
# About Four
--------------------------------------------------------------*/
.about-four {
  position: relative;
  display: block;
  padding: 120px 0 120px;
  z-index: 2;
}

.about-four__left {
  position: relative;
  display: block;
  margin-left: 135px;
}

.about-four__shape-1 {
  position: absolute;
  top: 149px;
  left: -266px;
}

.about-four__shape-1 img {
  width: auto;
}

.about-four__img-box {
  position: relative;
  display: block;
}

.about-four__img {
  position: relative;
  display: block;
}

.about-four__img img {
  width: 100%;
}

.about-four__img-2 {
  position: absolute;
  bottom: 133px;
  left: -135px;
}

.about-four__img-2 img {
  width: auto;
}

.about-four__content {
  position: absolute;
  bottom: -55px;
  left: -135px;
  max-width: 293px;
  width: 100%;
  background-color: var(--govity-base);
  padding: 63px 40px 41px;
}

.about-four__content h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  color: var(--govity-white);
}

.about-four__icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 105px;
  height: 105px;
  top: -52px;
  right: 20px;
  border-radius: 50%;
  background-color: var(--govity-white);
  transition: all 500ms ease;
}

.about-four__icon:hover {
  background-color: var(--govity-black);
}

.about-four__icon span {
  position: relative;
  display: inline-block;
  font-size: 64px;
  color: var(--govity-base);
  transition: all 500ms linear;
  transition-delay: 0.1s;
  transform: scale(1);
}

.about-four__icon:hover span {
  transform: scale(0.9);
  color: var(--govity-white);
}

.about-four__right {
  position: relative;
  display: block;
  margin-left: 70px;
}

.about-four__right .section-title {
  margin-bottom: 29px;
}

.about-four__points {
  position: relative;
  display: block;
  margin-top: 32px;
  margin-bottom: 40px;
}

.about-four__points li {
  position: relative;
  display: flex;
}

.about-four__points li + li {
  margin-top: 30px;
}

.about-four__points li .icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 52px;
  height: 52px;
  border-radius: 50%;
  width: 100%;
  background-color: var(--govity-base);
  top: 7px;
  transition: all 500ms ease;
}

.about-four__points li .icon:hover {
  background-color: var(--govity-black);
}

.about-four__points li .icon span {
  position: relative;
  display: inline-block;
  font-size: 20px;
  color: var(--govity-white);
  transition: all 500ms ease;
}

.about-four__points li .icon:hover span {
  color: var(--govity-white);
}

.about-four__points li .content {
  position: relative;
  display: block;
  margin-left: 20px;
}

.about-four__points li .content h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: var(--govity-letter-spacing-two);
  margin-bottom: 2px;
}

.about-four__points li .content p {
  letter-spacing: var(--govity-letter-spacing-two);
}

.about-four__btn-box {
  position: relative;
  display: block;
}

.about-four__btn {
  background-color: var(--govity-black);
}

.about-four__btn::before {
  background-color: var(--govity-base);
}

/*--------------------------------------------------------------
# Reawards One
--------------------------------------------------------------*/
.reawards-one {
  position: relative;
  display: block;
  padding-bottom: 30px;
  z-index: 1;
}

.reawards-one__shape-1 {
  position: absolute;
  right: 0;
  bottom: -224px;
  opacity: 0.05;
  z-index: -1;
}

.reawards-one__shape-1 img {
  width: auto;
}

.reawards-one .section-title {
  margin-bottom: 108px;
}

.reawards-one__single {
  position: relative;
  display: block;
  text-align: center;
  margin-bottom: 90px;
}

.reawards-one__content {
  position: relative;
  display: block;
  padding: 72px 30px 39px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
}

.reawards-one__img {
  position: absolute;
  top: -71px;
  left: 50%;
  transform: translateX(-50%);
  width: 146px;
  margin: 0 auto;
  mix-blend-mode: darken;
}

.reawards-one__img img {
  width: 100%;
}

.reawards-one__sub-title {
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.reawards-one__title {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}

.reawards-one__title a {
  color: var(--govity-black);
  transition: all 500ms ease;
}

.reawards-one__title a:hover {
  color: var(--govity-base);
}

/*--------------------------------------------------------------
# Counter Three
--------------------------------------------------------------*/
.counter-three {
  position: relative;
  display: block;
  background-color: var(--govity-black);
  overflow: hidden;
  padding: 120px 0 105px;
  z-index: 1;
}

.counter-three .section-title__tagline {
  color: var(--govity-white);
}

.counter-three .section-title__title {
  color: var(--govity-white);
}

/*--------------------------------------------------------------
# Team Two
--------------------------------------------------------------*/
.team-two {
  position: relative;
  display: block;
  padding: 120px 0 80px;
  z-index: 1;
}

/*--------------------------------------------------------------
# Brand Four
--------------------------------------------------------------*/
.brand-four {
  padding-bottom: 60px;
}

/*--------------------------------------------------------------
# Our History
--------------------------------------------------------------*/
.our-history {
  position: relative;
  display: block;
  padding: 120px 0 147px;
}

.our-history__inner {
  position: relative;
  display: block;
  z-index: 1;
}

.our-history__inner:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: -111px;
  left: 0;
  right: 0;
  width: 1px;
  background-color: var(--govity-bdr-color);
  margin: 0 auto 0;
  z-index: -1;
}

.our-history__inner:after {
  content: '';
  position: absolute;
  bottom: -111px;
  left: 50%;
  transform: translateX(-50%);
  width: 11px;
  height: 11px;
  background-color: var(--govity-bdr-color);
  border-radius: 50%;
  z-index: -1;
}

.our-history__single {
  position: relative;
  display: block;
}

.our-history__year-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 107px;
  width: 107px;
  background-color: var(--govity-base);
  border-radius: 50%;
  margin: 0 auto 0;
}

.our-history__year-box p {
  font-size: 30px;
  font-weight: 700;
  color: var(--govity-white);
  line-height: 30px;
  letter-spacing: var(--govity-letter-spacing);
}

.our-history__main-content-box {
  position: relative;
  display: block;
  margin-top: 86px;
  margin-bottom: 86px;
}

.our-history__main-content-single {
  position: relative;
  display: block;
}

.our-history__img-box {
  position: relative;
  display: block;
  margin-right: 89px;
}

.our-history__img {
  position: relative;
  display: block;
  transform: rotate(-12deg);
}

.our-history__img img {
  width: 100%;
  border: 5px solid transparent;
  transition: all 500ms ease;
}

.our-history__main-content-single:hover .our-history__img img {
  border: 5px solid var(--govity-base);
}

.our-history__content-one {
  position: relative;
  display: block;
  margin-left: 42px;
}

.our-history__content-one-title-box {
  position: relative;
  display: block;
}

.our-history__content-one-circle-one {
  position: absolute;
  top: 3px;
  left: -75px;
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--govity-white);
  transition: all 500ms ease;
}

.our-history__main-content-single:hover .our-history__content-one-circle-one {
  background-color: var(--govity-base);
}

.our-history__content-one-circle-two {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  background-color: var(--govity-base);
  border-radius: 50%;
  transition: all 500ms ease;
}

.our-history__main-content-single:hover .our-history__content-one-circle-two {
  background-color: var(--govity-white);
}

.our-history__content-one-circle-three {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 12px;
  width: 12px;
  background-color: var(--govity-white);
  border-radius: 50%;
  transition: all 500ms ease;
}

.our-history__content-one-sub-title {
  letter-spacing: var(--govity-letter-spacing-two);
}

.our-history__content-one-title {
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
}

.our-history__content-one-text-box {
  position: relative;
  display: block;
  margin-top: 40px;
}

.our-history__content-two {
  position: relative;
  display: block;
  margin-right: 40px;
  margin-top: 103px;
  text-align: right;
}

.our-history__content-two-title-box {
  position: relative;
  display: block;
}

.our-history__content-two-circle-one {
  position: absolute;
  top: 18px;
  right: -73px;
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--govity-white);
  transition: all 500ms ease;
}

.our-history__main-content-single:hover .our-history__content-two-circle-one {
  background-color: var(--govity-base);
}

.our-history__content-two-circle-two {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  background-color: var(--govity-base);
  border-radius: 50%;
  transition: all 500ms ease;
}

.our-history__main-content-single:hover .our-history__content-two-circle-two {
  background-color: var(--govity-white);
}

.our-history__content-two-circle-three {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 12px;
  width: 12px;
  background-color: var(--govity-white);
  border-radius: 50%;
  transition: all 500ms ease;
}

.our-history__content-two-sub-title {
  letter-spacing: var(--govity-letter-spacing-two);
}

.our-history__content-two-title {
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
}

.our-history__content-two-text-box {
  position: relative;
  display: block;
  margin-top: 40px;
}

.our-history__img-box-two {
  position: relative;
  display: block;
  margin-left: 91px;
  margin-top: 125px;
}

.our-history__img-two {
  position: relative;
  display: block;
  transform: rotate(-12deg);
}

.our-history__img-two img {
  width: 100%;
  border: 5px solid transparent;
  transition: all 500ms ease;
}

.our-history__main-content-single:hover .our-history__img-two img {
  border: 5px solid var(--govity-base);
}

/*--------------------------------------------------------------
# Pricing Page
--------------------------------------------------------------*/
.pricing-page {
  position: relative;
  display: block;
  padding: 112px 0 90px;
}

.pricing-page__main-tab-box {
  position: relative;
  display: block;
}

.pricing-page__main-tab-box .tab-buttons {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

.pricing-page__main-tab-box .tab-buttons .tab-btn {
  position: relative;
  display: flex;
  align-items: center;
}

.pricing-page__main-tab-box .tab-buttons .tab-btn + .tab-btn {
  margin-left: 0px;
}

.pricing-page__main-tab-box .tab-buttons .tab-btn span {
  position: relative;
  display: block;
  text-align: center;
  font-size: 12px;
  line-height: 12px;
  color: var(--govity-gray);
  background-color: var(--govity-primary);
  padding: 15px 30px 15px;
  font-weight: 700;
  cursor: pointer;
  overflow: hidden;
  border-radius: 0px;
  transition: all 0.5s linear;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  z-index: 1;
}

.pricing-page__main-tab-box .tab-buttons .tab-btn.active-btn span {
  color: var(--govity-white);
}

.pricing-page__main-tab-box .tab-buttons .tab-btn span:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 0%;
  background-color: var(--govity-base);
  transition: all 0.3s ease;
  z-index: -1;
}

.pricing-page__main-tab-box .tab-buttons .tab-btn.active-btn span:before {
  height: 100%;
}

.pricing-page__main-tab-box .tabs-content {
  position: relative;
  display: block;
}

.pricing-page__main-tab-box .tabs-content .tab {
  position: relative;
  display: none;
  transform: translateY(35px);
  transition: all 600ms ease;
  z-index: 10;
}

.pricing-page__main-tab-box .tabs-content .tab.active-tab {
  display: block;
  margin-top: 0px;
  transform: translateY(0px);
}

.pricing-page__inner {
  position: relative;
  display: block;
}

.pricing-page__single {
  position: relative;
  display: block;
  margin-bottom: 30px;
  background-color: rgb(255, 255, 255);
  transition: all 500ms ease;
}

.pricing-page__pack-name {
  position: absolute;
  top: 20px;
  right: -10px;
  background-color: var(--govity-base);
  padding: 10px 30px 10px;
  z-index: 2;
}

.pricing-page__pack-name:before {
  content: '';
  position: absolute;
  bottom: -10px;
  right: -10px;
  border-bottom: 10px solid transparent;
  border-left: 10px solid var(--govity-base);
  border-right: 10px solid transparent;
}

.pricing-page__pack-name p {
  font-size: 12px;
  font-weight: 500;
  color: var(--govity-white);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 12px;
}

.pricing-page__single:hover {
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.15);
}

.pricing-page__single-inner {
  position: relative;
  display: block;
  overflow: hidden;
  border: 1px solid var(--govity-bdr-color);
  padding: 60px 60px 60px;
  z-index: 1;
}

.pricing-page__shape-1 {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.08;
  z-index: -1;
}

.pricing-page__shape-1 img {
  width: auto;
}

.pricing-page__icon {
  position: relative;
  display: flex;
  align-items: center;
}

.pricing-page__icon span {
  position: relative;
  display: inline-block;
  font-size: 64px;
  color: var(--govity-base);
}

.pricing-page__pricing-box {
  position: relative;
  display: block;
  border-bottom: 1px solid var(--govity-bdr-color);
  padding-bottom: 38px;
  margin-top: 28px;
  margin-bottom: 33px;
}

.pricing-page__pricing {
  font-size: 40px;
  font-weight: 700;
  line-height: 40px;
}

.pricing-page__pricing span {
  font-size: 16px;
  color: var(--govity-gray);
  font-weight: 400;
  position: relative;
  left: -5px;
}

.pricing-page__pricing-text {
  font-size: 14px;
  line-height: 20px;
}

.pricing-page__services {
  position: relative;
  display: block;
}

.pricing-page__services-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 13px;
}

.pricing-page__services-list {
  position: relative;
  display: block;
}

.pricing-page__services-list li {
  position: relative;
  display: flex;
  align-items: center;
}

.pricing-page__services-list li + li {
  margin-top: 11px;
}

.pricing-page__services-list li .icon {
  position: relative;
  display: flex;
  align-items: center;
}

.pricing-page__services-list li .icon span {
  font-size: 15px;
  color: var(--govity-base);
}

.pricing-page__services-list li .text {
  margin-left: 10px;
}

.pricing-page__btn-box {
  position: relative;
  display: block;
  margin-top: 33px;
}

.pricing-page__btn {
  font-size: 14px;
  padding: 10px 50px 10px;
  background-color: var(--govity-black);
}

.pricing-page__btn:before {
  background-color: var(--govity-base);
}

.pricing-page__btn:hover:before {
  top: -40%;
}

/*--------------------------------------------------------------
# News Page
--------------------------------------------------------------*/
.news-page {
  position: relative;
  display: block;
  padding: 120px 0 90px;
  z-index: 1;
}

/*--------------------------------------------------------------
# Events Page
--------------------------------------------------------------*/
.events-page {
  position: relative;
  display: block;
  padding: 120px 0 90px;
  z-index: 1;
}

/*--------------------------------------------------------------
# Why Choose One
--------------------------------------------------------------*/
.why-choose-one {
  position: relative;
  display: block;
}

.why-choose-one__left {
  position: relative;
  display: block;
  z-index: -1;
}

.why-choose-one__img {
  position: relative;
  display: block;
}

.why-choose-one__img img {
  width: 100%;
}

.why-choose-one__right {
  position: relative;
  display: block;
  margin-left: -200px;
  margin-top: 100px;
  background-color: var(--govity-base);
  padding: 90px 100px 90px;
  overflow: hidden;
  z-index: 1;
}

.why-choose-one__shape-1 {
  position: absolute;
  bottom: -6px;
  right: 0;
  opacity: 0.2;
}

.why-choose-one__shape-1 img {
  width: auto;
}

.why-choose-one__title {
  font-size: 45px;
  font-weight: 700;
  line-height: 55px;
  color: var(--govity-white);
}

.why-choose-one__text {
  font-weight: 500;
  color: #ceffe5;
  letter-spacing: var(--govity-letter-spacing-two);
  margin-top: 29px;
  margin-bottom: 33px;
}

.why-choose-one__points {
  position: relative;
  display: block;
}

.why-choose-one__points li {
  position: relative;
  display: flex;
  align-items: center;
}

.why-choose-one__points li + li {
  margin-top: 6px;
}

.why-choose-one__points li .icon {
  position: relative;
  display: inline-block;
  top: 5px;
}

.why-choose-one__points li .icon span {
  position: relative;
  display: inline-block;
  font-size: 24px;
  color: var(--govity-white);
}

.why-choose-one__points li .text {
  position: relative;
  display: block;
  margin-left: 10px;
}

.why-choose-one__points li .text p {
  font-weight: 700;
  color: var(--govity-white);
}

/*--------------------------------------------------------------
# Team Page
--------------------------------------------------------------*/
.team-one__shape-4 {
  position: absolute;
  bottom: 56px;
  left: 50%;
  transform: translateX(-50%);
}

.team-one__shape-5 {
  position: absolute;
  bottom: 110px;
  left: 50%;
  transform: translateX(-50%);
}

.team-one__shape-6 {
  position: absolute;
  bottom: 102px;
  left: 50%;
  transform: translateX(-50%);
}

/*--------------------------------------------------------------
# Portfolio Page
--------------------------------------------------------------*/
.portfolio-page {
  position: relative;
  display: block;
  padding: 120px 0 90px;
}

.portfolio-page__single {
  position: relative;
  display: block;
  margin-bottom: 30px;
}

.portfolio-page__img-box {
  position: relative;
  display: block;
}

.portfolio-page__img {
  position: relative;
  display: block;
  overflow: hidden;
  z-index: 1;
}

.portfolio-page__img:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(var(--govity-black-rgb), 0.4);
  visibility: hidden;
  transform-origin: top center;
  transform: scaleY(0) translateZ(100px);
  transition:
    opacity 500ms ease,
    visibility 500ms ease,
    transform 700ms ease;
}

.portfolio-page__single:hover .portfolio-page__img:before {
  visibility: visible;
  transform: scaleY(1) translateZ(0px);
}

.portfolio-page__img img {
  width: 100%;
}

.portfolio-page__content {
  position: absolute;
  bottom: 30px;
  left: 0;
  transform: translateY(66%);
  transition:
    opacity 500ms ease,
    visibility 500ms ease,
    transform 500ms ease;
  visibility: hidden;
  opacity: 0;
  z-index: 2;
}

.portfolio-page__single:hover .portfolio-page__content {
  visibility: visible;
  transform: translateY(0%);
  transition-delay: 500ms;
  opacity: 1;
}

.portfolio-page__sub-title {
  position: relative;
  display: inline;
  font-size: 12px;
  color: var(--govity-white);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 12px;
  background-color: var(--govity-base);
  padding: 7px 30px 7px;
}

.portfolio-page__title {
  position: relative;
  display: block;
  background-color: var(--govity-white);
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
  padding: 18px 30px 18px;
  padding-right: 60px;
}

.portfolio-page__title a {
  color: var(--govity-black);
  transition: all 500ms ease;
}

.portfolio-page__title a:hover {
  color: var(--govity-base);
}

/*--------------------------------------------------------------
# Department Page
--------------------------------------------------------------*/
.department-page {
  position: relative;
  display: block;
  padding: 120px 0 60px;
}

/*--------------------------------------------------------------
# Shop Sidebar
--------------------------------------------------------------*/
.sidebar--shop {
  margin-top: 0;
  margin-bottom: 60px;
}

@media (min-width: 992px) {
  .sidebar--shop {
    margin-bottom: 0;
  }
}

.sidebar--shop .sidebar__single:not(.sidebar__search) {
  background-color: #fff;
  border: 1px solid var(--govity-bdr-color);
  padding: 30px;
}

.sidebar--shop .sidebar__category-list {
  margin-left: -20px;
  margin-right: -20px;
}

.sidebar--shop .sidebar__category-list li:hover a,
.sidebar--shop .sidebar__category-list li.active a {
  background-color: var(--govity-primary);
}

.sidebar--shop .sidebar__category .sidebar__title {
  padding-left: 0;
}

.sidebar__price .sidebar__title {
  margin-bottom: 25px;
}

.sidebar__price .price-ranger .ui-widget-content {
  background: var(--govity-primary);
  border: none;
  height: 5px;
  border-radius: 0px;
}

.sidebar__price .price-ranger .ui-slider-handle {
  position: absolute;
  top: -5px;
  background: var(--govity-base);
  border: 0;
  height: 14px;
  width: 14px !important;
  border-radius: 50%;
  margin-left: -2px;
  outline: medium none;
  cursor: pointer;
  z-index: 2;
}

.sidebar__price .price-ranger .ui-slider .ui-slider-range {
  background: var(--govity-base);
}

.sidebar__price .price-ranger #slider-range {
  margin-left: 3px;
  margin-right: 0;
  margin-top: 0;
}

.sidebar__price .price-ranger .ranger-min-max-block {
  position: relative;
  display: block;
  margin-top: 17px;
}

.sidebar__price .price-ranger .ranger-min-max-block input {
  display: inline-block;
}

.sidebar__price .price-ranger .ranger-min-max-block input[type='text'] {
  position: relative;
  display: inline-block;
  color: var(--govity-gray);
  font-size: 14px;
  font-weight: 400;
  width: 40px;
  line-height: 30px;
  border: none;
  padding: 0;
  text-align: center;
  background-color: transparent;
}

.sidebar__price .price-ranger .ranger-min-max-block span {
  position: relative;
  display: inline-block;
  color: var(--govity-gray);
  font-size: 14px;
  font-weight: 400;
  line-height: 40px;
  left: -2px;
}

.sidebar__price .price-ranger .thm-btn {
  font-size: 12px;
  padding: 4px 21px;
  float: right;
}

/*--------------------------------------------------------------
# Shop
--------------------------------------------------------------*/
.shop-one {
  padding-top: 120px;
  padding-bottom: 120px;
}

.product__all {
  position: relative;
  display: block;
}

.product__all .row {
  --bs-gutter-y: 30px;
}

.product__all-single {
  position: relative;
  display: block;
  text-align: center;
  transition: all 500ms ease;
  z-index: 1;
}

.product__all-single-inner {
  position: relative;
  display: block;
  border-radius: 0;
  overflow: hidden;
  z-index: 1;
}

.product__all-img {
  position: relative;
  display: block;
  overflow: hidden;
  background-color: var(--govity-white);
  z-index: 1;
}

.product__all-img:before {
  position: absolute;
  content: '';
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(var(--govity-black-rgb), 0.1);
  transition:
    opacity 500ms ease,
    visibility 500ms ease,
    transform 500ms ease;
  visibility: hidden;
  opacity: 0;
  transform: translateY(-70%);
  z-index: 1;
}

.product__all-single:hover .product__all-img:before {
  visibility: visible;
  transform: translateY(0%);
  opacity: 1;
}

.product__all-img img {
  width: 100%;
  mix-blend-mode: darken;
  transition: all 500ms ease;
}

.product__all-single:hover .product__all-img img {
  transform: scale(1.05);
}

.product__all-content {
  position: relative;
  display: block;
  text-align: center;
  border: 1px solid var(--govity-bdr-color);
  border-top: 0;
  background-color: var(--govity-white);
  padding-top: 30px;
  padding-bottom: 30px;
  transition: all 500ms ease;
}

.product__all-single:hover .product__all-content {
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
}

.product__all-review {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product__all-review i {
  color: var(--govity-base);
  font-size: 14px;
}

.product__all-review i + i {
  margin-left: 6px;
}

.product__all-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.04em;
  margin-bottom: 2px;
  margin-top: 4px;
}

.product__all-title a {
  color: var(--govity-black);
  transition: all 500ms ease;
}

.product__all-title a:hover {
  color: var(--govity-base);
}

.product__all-price {
  font-weight: 500;
  line-height: 25px;
  font-size: 16px;
}

.product__all-btn-box {
  position: relative;
  display: block;
  margin-top: 14px;
}

.product__all-btn {
  padding: 4px 21px;
  font-size: 12px;
  background-color: var(--govity-primary);
  border-radius: 0;
  color: var(--govity-black);
}

.product__all-btn:hover {
  color: var(--govity-white);
}

.product__all-btn::before {
  background-color: var(--govity-base);
}

.products__all-icon-boxes {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 2;
  display: flex;
  align-items: center;
  flex-direction: column;
  opacity: 0;
  transform: perspective(400px) rotateX(20deg) translateX(100%);
  transform-origin: bottom;
  transition-delay: 0.1s;
  transition-timing-function: ease-in-out;
  transition-duration: 0.5s;
  transition-property: all;
  z-index: 1;
}

.product__all-single:hover .products__all-icon-boxes {
  opacity: 1;
  transform: perspective(400px) rotateX(0deg) translateX(0%);
  transition-delay: 0.3s;
  transition-timing-function: ease-in-out;
  transition-duration: 0.5s;
  transition-property: all;
}

.products__all-icon-boxes a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background-color: var(--govity-white);
  border-radius: 50%;
  font-size: 14px;
  color: var(--govity-black);
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.products__all-icon-boxes a:hover {
  background-color: var(--govity-base);
  color: var(--govity-white);
}

.products__all-icon-boxes a + a {
  margin-top: 10px;
}

.product__showing-result {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  flex-wrap: wrap;
}

.product__showing-text-box {
  position: relative;
  display: block;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .product__showing-text-box {
    margin-bottom: 0;
  }
}

.product__showing-text {
  font-size: 18px;
  font-weight: 400;
}

.product__showing-sort {
  position: relative;
  display: block;
  max-width: 340px;
  width: 100%;
}

.product__showing-sort .select-box .nice-select {
  background-color: var(--govity-primary);
  border-radius: 0;
  color: var(--govity-gray);
  font-size: 14px;
  font-weight: 400;
  height: 70px;
  line-height: 70px;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  margin-bottom: 0px;
}

.product__showing-sort .select-box .nice-select:after {
  position: absolute;
  right: 30px;
}

/*--------------------------------------------------------------
# Shop Details
--------------------------------------------------------------*/
.product-details {
  padding-top: 120px;
}

.review-form-one {
  padding-bottom: 120px;
}

.review-form-one__title {
  margin: 0;
  color: var(--govity-black);
  font-weight: bold;
  font-size: 30px;
  letter-spacing: -0.04em;
  margin-bottom: 10px;
}

.review-form-one__rate-box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 25px;
}

.review-form-one__rate-text {
  margin: 0;
  font-size: 18px;
  letter-spacing: -0.02em;
  margin-right: 25px;
}

.review-form-one__rate {
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  color: var(--govity-base);
}

.review-form-one__rate i + i {
  margin-left: 6px;
}

.review-form-one .contact-one__btn-box {
  margin: 0;
  text-align: left;
}

.review-one {
  margin-bottom: 53px;
}

.review-one__title {
  margin: 0;
  color: var(--govity-black);
  font-weight: bold;
  font-size: 30px;
  letter-spacing: -0.04em;
  margin-bottom: 30px;
}

.review-one .comment-box .comment {
  border-bottom: 1px solid var(--govity-bdr-color);
  padding-bottom: 35px;
  margin-bottom: 40px;
  position: relative;
}

@media (min-width: 768px) {
  .review-one .comment-box .comment {
    display: flex;
    align-items: center;
  }
}

.review-one .comment-box .comment .thumb {
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .review-one .comment-box .comment .thumb {
    margin-bottom: 0;
    margin-right: 45px;
  }
}

.review-one .comment-box .comment .thumb img {
  border-radius: 50%;
}

.review-one .comment-box .comment .reply-btn {
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  color: var(--govity-base);
}

@media (min-width: 768px) {
  .review-one .comment-box .comment .reply-btn {
    position: absolute;
    top: 14px;
    right: 0;
  }
}

.review-one .comment-box .comment .reply-btn i + i {
  margin-left: 6px;
}

.review-one__content-top .info {
  display: flex;
  align-items: center;
}

.review-one__content-top .info h2 {
  margin: 0;
  color: var(--govity-black);
  font-weight: bold;
  font-size: 20px;
  letter-spacing: -0.04em;
}

.review-one__content-top .info span {
  font-size: 16px;
  color: var(--govity-base);
  letter-spacing: -0.02em;
  display: inline-block;
  position: relative;
  top: 2px;
  margin-left: 10px;
}

.review-one__content-bottom {
  margin-top: 20px;
}

@media (min-width: 768px) {
  .review-one__content-bottom {
    margin-top: 32px;
  }
}

.review-one__content-bottom p {
  font-size: 16px;
  margin: 0;
  line-height: 30px;
  letter-spacing: -0.02em;
}

.product-description {
  padding-top: 50px;
}

.product-description__inner {
  border-bottom: 1px solid var(--govity-bdr-color);
  margin-bottom: 50px;
  padding-bottom: 50px;
}

.product-description__title {
  margin: 0;
  color: var(--govity-black);
  font-weight: bold;
  font-size: 30px;
  letter-spacing: -0.04em;
  margin-bottom: 21px;
}

.product-description p {
  margin: 0;
}

.product-description__list ul {
  margin-top: 20px;
  margin-bottom: 20px;
}

.product-description__list ul li {
  position: relative;
  padding-left: 40px;
  color: var(--govity-black);
  font-size: 16px;
  font-weight: bold;
}

.product-description__list ul li [class*='icon-'] {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 20px;
  color: var(--govity-base);
}

.quantity-box {
  display: inline-flex;
  align-items: center;
  position: relative;
}

.quantity-box input[type='number'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 50px;
  width: 67px;
  outline: none;
  border: 1px solid var(--govity-bdr-color);
  border-left: 0;
  border-right: 0;
  text-align: center;
  font-size: 16px;
  color: var(--govity-gray);
  font-size: 18px;
}

.quantity-box input::-webkit-outer-spin-button,
.quantity-box input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

.quantity-box button {
  width: 30px;
  height: 50px;
  border: 1px solid var(--govity-bdr-color);
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  color: var(--govity-gray);
  font-size: 18px;
  background-color: var(--govity-bdr-color);
  transition: all 500ms ease;
}

.quantity-box button:first-child {
  border-right: 0;
}

.quantity-box button:last-child {
  border-left: 0;
}

.quantity-box button:hover {
  color: #fff;
  background-color: var(--govity-black);
}

.product-details__img {
  position: relative;
  margin-bottom: 50px;
}

@media (min-width: 992px) {
  .product-details__img {
    margin-bottom: 0;
  }
}

.product-details__img .img-popup {
  position: absolute;
  font-size: 21px;
  color: var(--govity-black);
  top: 30px;
  right: 30px;
}

.product-details__img img {
  max-width: 100%;
}

.product-details__title {
  margin: 0;
  color: var(--govity-black);
  font-weight: bold;
  font-size: 34px;
  letter-spacing: -0.04em;
}

.product-details__top {
  display: flex;
  align-items: baseline;
  margin-bottom: 12px;
  margin-top: -5px;
}

.product-details__top span {
  font-size: 20px;
  font-weight: bold;
  color: var(--govity-base);
  letter-spacing: var(--govity-letter-spacing);
  margin-left: 37px;
}

.product-details__review {
  display: inline-flex;
  align-items: center;
  font-size: 16px;
}

.product-details__review i {
  color: var(--govity-base);
}

.product-details__review i + i {
  margin-left: 6px;
}

.product-details__review span {
  margin-left: 20px;
}

.product-details__content {
  border-top: 1px solid var(--govity-bdr-color);
  margin-top: 20px;
  padding-top: 20px;
}

.product-details__quantity {
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.product-details__quantity-title {
  margin: 0;
  color: var(--govity-black);
  font-weight: bold;
  font-size: 18px;
  letter-spacing: -0.04em;
  margin-right: 30px;
}

.product-details__buttons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.product-details__buttons-1 {
  margin-right: 10px;
  margin-bottom: 10px;
}

@media (min-width: 445px) {
  .product-details__buttons-1 {
    margin-bottom: 0;
  }
}

.product-details__buttons-1 .thm-btn {
  background-color: var(--govity-black);
}

.product-details__buttons-1 .thm-btn::before {
  background-color: var(--govity-base);
}

.product-details__social {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.product-details__social .title {
  margin: 0;
  color: var(--govity-black);
  font-weight: bold;
  font-size: 18px;
  letter-spacing: -0.04em;
}

.product-details__social-link {
  display: inline-flex;
  margin-left: 30px;
}

.product-details__social-link a {
  background-color: var(--govity-primary);
  color: var(--govity-black);
  font-size: 14px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 43px;
  height: 43px;
  transition: all 500ms ease;
  border-radius: 50%;
}

.product-details__social-link a + a {
  margin-left: 10px;
}

.product-details__social-link a:hover {
  color: var(--govity-white);
  background-color: var(--govity-black);
}

.cart-page {
  padding-top: 110px;
  padding-bottom: 120px;
}

.cart-page .table-responsive {
  position: relative;
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 60px;
}

.cart-table {
  --bs-table-bg: transparent;
  --bs-table-striped-color: var(--govity-black);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--govity-black);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--govity-black);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--govity-black);
  vertical-align: top;
  border-color: var(--govity-bdr-color);
  margin-bottom: 0;
}

@media (max-width: 1199px) {
  .cart-table {
    min-width: 1170px;
  }
}

.cart-table > :not(:last-child) > :last-child > * {
  border-bottom-color: var(--govity-bdr-color);
}

.cart-table th,
.cart-table td {
  padding-top: 0;
  padding-bottom: 0;
}

.cart-table th:first-child,
.cart-table td:first-child {
  padding-left: 0;
}

.cart-table th:last-child,
.cart-table td:last-child {
  padding-right: 0;
  text-align: right;
}

.cart-table th {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.02em;
  padding-bottom: 20px;
  line-height: 1.2em;
}

.cart-table td {
  padding-top: 30px;
  padding-bottom: 30px;
  vertical-align: middle;
  font-size: 18px;
  color: var(--govity-gray);
}

.cart-table .product-box {
  display: flex;
  align-items: center;
}

.cart-table .product-box .title {
  font-size: 20px;
  color: var(--govity-black);
  font-weight: 700;
  margin: 0;
  letter-spacing: -0.04em;
  margin-left: 35px;
}

.cart-table .product-box .title a {
  color: inherit;
  transition: all 500ms ease;
}

.cart-table .product-box .title a:hover {
  color: var(--govity-base);
}

.cart-cupon__form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 40px;
}

@media (min-width: 992px) {
  .cart-cupon__form {
    margin-bottom: 0;
  }
}

.cart-cupon__form input[type='text'],
.cart-cupon__form input[type='email'] {
  height: 58px;
  border: 0;
  width: 100%;
  font-size: 14px;
  color: var(--govity-gray);
  background-color: var(--govity-primary);
  border-radius: var(--govity-bdr-radius);
  padding: 0 20px;
  outline: none;
  max-width: 375px;
  margin-right: 20px;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .cart-cupon__form input[type='text'],
  .cart-cupon__form input[type='email'] {
    margin-bottom: 0;
  }
}

.cart-total {
  float: right;
  width: 100%;
}

@media (min-width: 992px) {
  .cart-total {
    max-width: 290px;
  }
}

.cart-total li {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart-total li + li {
  margin-top: 15px;
}

.cart-total li span {
  font-size: 20px;
  font-weight: bold;
  color: var(--govity-black);
}

.cart-total li span:last-child {
  font-size: 18px;
  font-weight: 400;
  color: var(--govity-gray);
}

.cart-total li span.cart-total-amount {
  color: var(--govity-base);
}

.cart-page__buttons {
  float: right;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 20px;
}

.cart-page__buttons-1 {
  margin-right: 10px;
  margin-bottom: 10px;
}

@media (min-width: 445px) {
  .cart-page__buttons-1 {
    margin-bottom: 0;
  }
}

.cart-page__buttons-1 .thm-btn {
  background-color: var(--govity-black);
}

.cart-page__buttons-1 .thm-btn::before {
  background-color: var(--govity-base);
}

/*--------------------------------------------------------------
# Checkout
--------------------------------------------------------------*/
.checkout-page {
  position: relative;
  display: block;
  padding: 110px 0 120px;
}

.checkout-page .row {
  --bs-gutter-x: 20px;
}

.checkout-page .billing_details {
  position: relative;
  display: block;
}

.billing_title {
  position: relative;
  display: block;
  margin-bottom: 33px;
}

.billing_title p {
  font-size: 16px;
  margin: 0;
}

.billing_title a,
.billing_title span {
  color: var(--govity-base);
  text-shadow: 0 0 1px currentColor;
  transition: all 500ms ease;
}

.billing_title a:hover {
  color: var(--govity-black);
}

.billing_title h2 {
  font-size: 30px;
  line-height: 1.5em;
  margin: 0;
  font-weight: 700;
  margin-top: 0px;
  letter-spacing: -0.04em;
}

.billing_details_form {
  position: relative;
  display: block;
}

.billing_details_form .bs-gutter-x-20 {
  --bs-gutter-x: 20px;
}

.billing_details_form .btn-light {
  border: none;
}

.billing_details_form .btn-light:hover {
  border: none;
}

.billing_input_box {
  position: relative;
  display: block;
  margin-bottom: 20px;
}

.billing_input_box .bootstrap-select:not([class*='col-']):not([class*='form-control']):not(.input-group-btn) {
  width: 100%;
}

.billing_input_box .bootstrap-select .dropdown-toggle .filter-option {
  display: flex;
  align-items: center;
}

.billing_input_box .bootstrap-select > .dropdown-toggle,
.billing_input_box input[type='text'],
.billing_input_box input[type='email'],
.billing_input_box input[type='tel'] {
  height: 58px;
  border: 0;
  width: 100%;
  font-size: 14px;
  color: var(--govity-gray);
  background-color: var(--govity-primary);
  border-radius: var(--govity-bdr-radius);
  padding: 0 30px;
  outline: none;
}

.billing_details .checked-box {
  position: relative;
  display: block;
  margin-top: -6px;
}

.billing_details .checked-box label {
  position: relative;
  display: inline-block;
  padding-left: 30px;
  margin-right: 0px;
  margin-bottom: 0;
  color: var(--govity-gray);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  text-transform: none;
  cursor: pointer;
}

.billing_details .checked-box input[type='checkbox'] {
  display: none;
}

.billing_details .checked-box input[type='checkbox'] + label span {
  position: absolute;
  top: 2px;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  vertical-align: middle;
  background-color: transparent;
  background: var(--govity-base);
  cursor: pointer;
  transition: all 300ms ease;
  border: 0;
}

.billing_details .checked-box label span:before {
  position: absolute;
  top: 4px;
  left: 6px;
  display: block;
  border-bottom: 2px solid var(--govity-white);
  border-right: 2px solid var(--govity-white);
  content: '';
  width: 6px;
  height: 9px;
  pointer-events: none;
  transform-origin: 66% 66%;
  transform: rotate(45deg);
  transition: all 0.15s ease-in-out;
  opacity: 0;
}

.billing_details.checked-box input[type='checkbox']:checked + label span {
  border-color: #ffffff;
}

.billing_details .checked-box input[type='checkbox']:checked + label span:before {
  opacity: 1;
}

.ship_different_address_title {
  margin-top: 30px;
}

.ship_different_address_title h2 span::before {
  position: relative;
  display: inline-block;
  color: var(--govity-white);
  font-size: 11px;
  top: -5px;
  left: 2px;
  height: 20px;
  width: 20px;
  background-color: var(--govity-base);
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
}

.ship_different_address_form .ship_different_input {
  position: relative;
  display: block;
  height: 130px;
}

.ship_different_address_form .ship_different_input textarea {
  height: 138px;
  width: 100%;
  border: none;
  color: var(--govity-gray);
  background-color: var(--govity-primary);
  border-radius: var(--govity-bdr-radius);
  padding: 20px 30px 20px;
  outline: none;
  font-size: 14px;
}

.billing_details_form .select-box .nice-select {
  background-color: var(--govity-extra);
  border-radius: var(--govity-bdr-radius);
  color: var(--govity-gray);
  font-size: 14px;
  font-weight: 500;
  height: 60px;
  line-height: 60px;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  margin-bottom: 20px;
}

.billing_details_form .select-box .nice-select:after {
  position: absolute;
  right: 30px;
}

.your_order {
  position: relative;
  display: block;
  padding-top: 56px;
}

.your_order h2 {
  font-size: 30px;
  line-height: 1.5em;
  font-weight: 700;
  margin: 0;
  margin-bottom: 53px;
  letter-spacing: -0.04em;
}

.order_table_box {
  position: relative;
  width: 100%;
  border-bottom: 1px solid var(--govity-bdr-color);
  padding-bottom: 24px;
}

.order_table_detail {
  position: relative;
  width: 100%;
}

.order_table_head {
  position: relative;
  width: 100%;
  border-bottom: 1px solid var(--govity-bdr-color);
  border-top: 1px solid var(--govity-bdr-color);
}

.order_table_head th {
  font-size: 20px;
  color: var(--govity-black);
  font-weight: 700;
  letter-spacing: -0.02em;
  margin: 0;
  padding: 22px 0 22px;
}

.order_table_head th.right {
  float: right;
}

.order_table_detail tbody td {
  padding: 25px 0 2px;
}

.order_table_detail tbody td.pro__title {
  color: var(--govity-gray);
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
}

.order_table_detail tbody td.pro__price {
  color: var(--govity-gray);
  font-size: 16px;
  line-height: 18px;
  text-align: right;
  font-weight: 500;
  letter-spacing: 0;
  transition: all 500ms ease;
}

.checkout__payment {
  background-color: var(--govity-primary);
  border-radius: var(--govity-bdr-radius);
  padding: 49px 50px 18px;
  margin-bottom: 20px;
}

.checkout__payment__item + .checkout__payment__item {
  margin-top: 14px;
}

.checkout__payment__title {
  display: flex;
  color: var(--govity-black);
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  align-items: center;
  margin-bottom: 23px;
  cursor: pointer;
  letter-spacing: -0.02em;
}

.checkout__payment__title::before {
  content: '';
  width: 23px;
  height: 23px;
  background-color: #ffffff;
  border: 2px solid #eee9db;
  border-radius: 50%;
  margin-right: 10px;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 10px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  position: relative;
  top: -2px;
  transition: all 500ms ease;
}

.checkout__payment__title img {
  margin-left: 15px;
}

.checkout__payment__item--active .checkout__payment__title::before {
  background-color: var(--govity-base);
  border-color: var(--govity-base);
  content: '\f00c';
  color: var(--govity-white);
}

.checkout__payment__content {
  font-size: 16px;
  line-height: 26px;
  color: var(--govity-gray);
  font-weight: 400;
  margin-left: 39px;
  letter-spacing: -0.02em;
  padding-bottom: 30px;
}

/*--------------------------------------------------------------
# Boxed Version
--------------------------------------------------------------*/
body.boxed-wrapper {
  background-color: var(--govity-primary);
}

body.boxed-wrapper .main-menu .main-menu__list > li + li,
body.boxed-wrapper .stricky-header .main-menu__list > li + li {
  margin-left: 40px;
}

body.boxed-wrapper .main-menu__call {
  display: none;
}

.boxed-wrapper .page-wrapper {
  max-width: 1530px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.08);
}
